import React, { useRef, 
	useEffect 
} from "react";

import { CalcitePanel } from "@esri/calcite-components-react";
import Legend from "@arcgis/core/widgets/Legend";

const LegendPanel = ({isMapLoading, arcgisMap}) => {

	const legendRef = useRef(null);
	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (legendRef.current && !legendRef.current.children.length &&  arcgisMap.map) {

			arcgisMap.when( () => {
				new Legend({
					view: arcgisMap,
					container: legendRef.current
				});
			});

		}
	}, [arcgisMap]);
	//onLoadMap();
	return (
		<CalcitePanel loading={isMapLoading}>
			<div ref={legendRef}></div>
		</CalcitePanel>
	);
}


export default LegendPanel;