import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const thisId = 'aa-hl-planning';
export const hlPlanning = {
	id: thisId,
	title:"Planning",
	type:'group',
	description:``,
	items: [
		{
			layerKey: 'bound-openSpaceLabel',
			server: 'terrene',
			title: 'Open Space Use Label',
			folder: 'SAFEA',
			mapServer: 'Boundary_Layers',
			layerId: 4,
			parentDiv: 'openSpaceMgt',
			defExp: '1=1',
			isFeatureLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'bound-openSpaceMgt',
			server: 'terrene',
			title: 'Open Space Management Type',
			folder: 'SAFEA',
			mapServer: 'Boundary_Layers',
			layerId: 5,
			parentDiv: 'openSpaceMgt',
			defExp: '1=1',
			isFeatureLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			const thisLayer = new FeatureLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
				, title: floodmapperObj.title
				, outFields: ['*']
				, popupEnabled: true
			});
		
			thisLayer.when(() => {
				//Create a template of literally all the fields
				thisLayer.popupTemplate = thisLayer.createPopupTemplate();
			});
		
			return {
				layerId: `aa-hl-os-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: thisLayer,
				meta:null}
		})
}