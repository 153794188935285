import assessAreaHighlands from './assessment-area/highlands-region';
import assessAreaPinelands from './assessment-area/pinelands-region';

import scenarioAssessHighlands from './scenario-assess-lu-change/highlands-region';
import scenarioAssessPinelands from './scenario-assess-lu-change/pinelands-region';
//import {scenarioAssessPinelandsPriority, scenarioAssessPinelandsDrivingFactors} from './scenario-assess-lu-change/pinelands-region';

import { wsHighlandEcoHealth } from './watershed-eco-health/highlands-region/watershedEcoHealth';
import { wsPinelandEcoHealth } from './watershed-eco-health/pinelands-region/watershedEcoHealth';

import boundHighlands from './boundary-layers/highlands-region';
import boundPinelands from './boundary-layers/pinelands-region';

export const assessmentArea = 
{
	id:'assessment-area',
	title:"Assessment Areas",
	type:'group',
	ignoreInMapLayersPanel: true,
	items:[
		{
			id:'aa-hl',
			title:"Highlands Region",
			type:'group',
			items: assessAreaHighlands
		},
		{
			id:'aa-pl',
			title:"Pinelands Region",
			type:'group',
			items: assessAreaPinelands
		}
	]
};


export const scenarioAssessmentLuChange = 
{
	id:'scenario-assessment',
	title:"Scenario Assessment of LU Change Potential",
	type:'group',
	ignoreInMapLayersPanel: true,
	showScenario: 'main',
	items:[
		{
			id:'sa-hl',
			title:"Highlands Region",
			type:'group',
			items: scenarioAssessHighlands
		},
		{
			id:'sa-pl',
			title:"Pinelands Region",
			type:'group',
			items: scenarioAssessPinelands
		}
		/*
		{
			id:'sa-pl',
			title:"Pinelands Region",
			type:'group',
			items: [
				{
					id: 'sa-pl-scen',
					type: 'group-set',
					//type: 'group',
					title: 'Priority based scenario assessment of land use change potential ',
					items: scenarioAssessPinelandsPriority
				},
				
				{
					id: 'sa-pl-df',
					type: 'group-set',
					//type: 'group',
					title: 'Driving Factors',
					items: scenarioAssessPinelandsDrivingFactors
				}
			]
		}
		*/
	]
};

export const wsLayers = 
{
	id:'watershed-eco-health',
	title:"Watershed Ecological Health Layers",
	type:'group',
	ignoreInMapLayersPanel: true,
	items:[
		wsHighlandEcoHealth,
		wsPinelandEcoHealth
	]
};



export const boundaryLayers = 
{
	id:'boundary-layers',
	title:"Boundary Layers",
	type:'group',
	ignoreInMapLayersPanel: true,
	items:[
		{
			id:'bl-hl',
			title:"Highlands Region",
			type:'group',
			items:boundHighlands
		},
		{
			id:'bl-pl',
			title:"Pinelands Region",
			type:'group',
			items:boundPinelands
		}
	]
};
