
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const factorLayers = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Scenario_Assess_LUChg/MapServer/`
	, sublayers: [{id:1}]
	, title: 'Equal Priority of Each Driving Factor'
});
export const factors = 
{
	id:'sa-hl-factors',
	title:"Equal Priority of Factors",
	type:'group',
	showScenario:'highlandsEqualPrio',
	items:[
	
	{layerId:'sa-hl-factors-main', type: 'layer', item: factorLayers, meta:null},
	
	{
		id: 'sa-hl-factors-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [{
			layerKey: 'saluc-epof-slope',
			layerItem: null,
			server: 'terrene',
			title: 'Slope (% Rise per meter)',
			folder: 'SAFEA_Highlands',
			mapServer: 'Equal_Priority_of_Each_Driving_Factor',
			layerId: 3,
			opacityBar: null,
			parentDiv: 'epofDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-epof-lulc15',
			layerItem: null,
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SAFEA_Highlands',
			mapServer: 'Equal_Priority_of_Each_Driving_Factor',
			layerId: 4,
			opacityBar: null,
			parentDiv: 'epofDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-epof-mr',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SAFEA_Highlands',
			mapServer: 'Equal_Priority_of_Each_Driving_Factor',
			layerId: 5,
			opacityBar: null,
			parentDiv: 'epofDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-epof-ts',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SAFEA_Highlands',
			mapServer: 'Equal_Priority_of_Each_Driving_Factor',
			layerId: 6,
			opacityBar: null,
			parentDiv: 'epofDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-epof-hdra',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to High Density Residential Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Equal_Priority_of_Each_Driving_Factor',
			layerId: 7,
			opacityBar: null,
			parentDiv: 'epofDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-hl-factors-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};