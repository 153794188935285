import React from "react";
import { connect } from "react-redux";

/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const HighlandsAgriPriority = () => {


	return <>
		<div slot="header" id="modal-title">Agricultural Priority Areas Restricted</div>
		<div slot="content">
			<h3 className="text-left">Spatial data rankings and weighted overlay analysis for agricultural priority.</h3>
			<p>Agriculture priority represents a scenario where high agricultural priority areas in non-preserved farmland are restricted from future residential land use selection.</p>
			<p>
				<strong>Land Use/Land Cover <span style={{float:'right'}}>Influcence 60%</span></strong>
			</p>
			<table className="meta-table">
				<thead>
					<tr>
						<th>Parameters</th>
						<th>Ranks</th>
						<th>Suitability</th>		
					</tr>
				</thead>
				<tbody>
					<tr><td>Agriculture</td>            <td>9</td>  <td>High</td></tr>
					<tr><td>Barren</td>                 <td>7</td>  <td>High</td></tr>
					<tr><td>Forest</td>                 <td>3</td>  <td>Moderate</td></tr>
					<tr><td>Urban/Built-up Area</td>    <td>0</td>  <td>Low</td></tr>
					<tr><td>Water</td>                  <td>0</td>  <td>Low</td></tr>
					<tr><td>Wetlands</td>               <td>0</td>  <td>Low</td></tr>
					<tr><td>Preserved</td>              <td>0</td>  <td>Low</td></tr>
				</tbody>
			</table>
			<p>
				<strong>Distance to high density residential areas <span style={{float:'right'}}>Influcence 10%</span></strong>
			</p>
			<table class="meta-table">
				<thead>
					<tr>
						<th>Parameters</th>
						<th>Ranks</th>
						<th>Suitability</th>		
					</tr>
				</thead>
				<tbody>
					<tr><td>Up to 1 mile</td>     <td>9</td>  <td>High</td></tr>
					<tr><td>Up to 4 miles</td>    <td>7</td>  <td>High</td></tr>
					<tr><td>Up to 10 miles</td>   <td>1</td>  <td>Low</td></tr>
				</tbody>
			</table>
			<p>
				<strong>Distance to train stations <span style={{float:'right'}}>Influcence 15%</span></strong>
			</p>
			<table class="meta-table">
				<thead>
					<tr>
						<th>Parameters</th>
						<th>Ranks</th>
						<th>Suitability</th>		
					</tr>
				</thead>
				<tbody>
					<tr><td>Up to 1 mile</td>     <td>9</td>  <td>High</td></tr>
					<tr><td>Up to 4 miles</td>    <td>7</td>  <td>High</td></tr>
					<tr><td>Up to 10 miles</td>   <td>2</td>  <td>Low</td></tr>
					<tr><td>Up to 50 miles</td>   <td>1</td>  <td>Low</td></tr>
				</tbody>
			</table>
			<p>
				<strong>Distance to Major Roads <span style={{float:'right'}}>Influcence 15%</span></strong>
			</p>
			<table class="meta-table">
				<thead>
					<tr>
						<th>Parameters</th>
						<th>Ranks</th>
						<th>Suitability</th>		
					</tr>
				</thead>
				<tbody>
					<tr><td>Up to 1 mile</td>     <td>9</td>  <td>High</td></tr>
					<tr><td>Up to 4 miles</td>    <td>7</td>  <td>High</td></tr>
					<tr><td>Up to 10 miles</td>   <td>1</td>  <td>Low</td></tr>
				</tbody>
			</table>
		</div> 
	</>
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlandsAgriPriority);
