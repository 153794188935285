import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";


const hydroFMLayer = [{
	layerKey: 'pineHydro-streams',
	server: 'terrene',
	title: 'Streams',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Hydrology',
	layerId: 0,
	parentDiv: 'pineHydro',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'pineHydro-detailed',
	server: 'terrene',
	title: 'Detailed Water Bodies',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Hydrology',
	layerId: 1,
	parentDiv: 'pineHydro',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}];

const thisId = 'aa-pl-hydro';
export const plHydrologyWater = {
	id: thisId,
	title:"Hydrology / Water",
	type:'group',
	description:`Hydrology/water tab include data on streams and detailed waterbodies. This data set is intended to serve as a resource for analysis rather than regulatory delineations.`,
	items: hydroFMLayer.map( (floodmapperObj) => {
		const thisLayer = new FeatureLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
			, title: floodmapperObj.title
			, outFields: ['*']
			, popupEnabled: true
		});
	
		thisLayer.when(() => {
			//Create a template of literally all the fields
			thisLayer.popupTemplate = thisLayer.createPopupTemplate();
		});
	
		return {
			layerId: `aa-pl-ch-${floodmapperObj.layerKey}`,
			type: 'layer',
			item: thisLayer,
			meta:null}
	})
}