//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
const projectedLossPestsEast = [{
	layerKey: 'prj-loss-pest-pct-host-maple',
	layerItem: null,
	server: 'terrene',
	title: '% Host BA Loss - Maple decline',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 0,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-pct-total-maple',
	layerItem: null,
	server: 'terrene',
	title: '% Total BA Loss - Maple decline',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 3,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-total-maple',
	layerItem: null,
	server: 'terrene',
	title: 'Total BA Loss - Maple decline',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 6,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
//Oak
, {
	layerKey: 'prj-loss-pest-pct-host-oak',
	layerItem: null,
	server: 'terrene',
	title: '% Host BA Loss - Oak decline and gypsy moth',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 1,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-pct-total-oak',
	layerItem: null,
	server: 'terrene',
	title: '% Total BA Loss - Oak decline and gypsy moth',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 4,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-total-oak',
	layerItem: null,
	server: 'terrene',
	title: 'Total BA Loss - Oak decline and gypsy moth',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 7,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
//Dutch Elm
, {
	layerKey: 'prj-loss-pest-pct-host-dutch-elm',
	layerItem: null,
	server: 'terrene',
	title: '% Host BA Loss - Dutch elm disease',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 2,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-pct-total-dutch-elm',
	layerItem: null,
	server: 'terrene',
	title: '% Total BA Loss - Dutch elm disease',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 5,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-total-dutch-elm',
	layerItem: null,
	server: 'terrene',
	title: 'Total BA Loss - Dutch elm disease',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_pests_east',
	layerId: 8,
	opacityBar: null,
	parentDiv: 'hazPlpeMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}]
;
const projectedLossEmeraldAshBorer = [{
	layerKey: 'prj-loss-pest-pct-host-eab',
	layerItem: null,
	server: 'terrene',
	title: '% Host BA Loss - Emerald ash borer',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_by_emerald_ash_borer',
	layerId: 0,
	opacityBar: null,
	parentDiv: 'hazPleabMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-pct-total-eab',
	layerItem: null,
	server: 'terrene',
	title: '% Total BA Loss - Emerald ash borer',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_by_emerald_ash_borer',
	layerId: 1,
	opacityBar: null,
	parentDiv: 'hazPleabMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-pest-total-eab',
	layerItem: null,
	server: 'terrene',
	title: 'Total BA Loss - Emerald ash borer',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_by_emerald_ash_borer',
	layerId: 2,
	opacityBar: null,
	parentDiv: 'hazPleabMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}];
const projectedLossRateSpecies = [{
	layerKey: 'prj-loss-sp-hemlock',
	layerItem: null,
	server: 'terrene',
	title: 'Hemlock Spp.',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 0,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-redmaple',
	layerItem: null,
	server: 'terrene',
	title: 'Red Maple',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 1,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-sugarmaple',
	layerItem: null,
	server: 'terrene',
	title: 'Sugar Maple',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 2,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-ambeech',
	layerItem: null,
	server: 'terrene',
	title: 'American Beech',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 3,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-ashspp',
	layerItem: null,
	server: 'terrene',
	title: 'Ash Spp.',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 4,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-whiteoak',
	layerItem: null,
	server: 'terrene',
	title: 'White Oak spp.',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 5,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-redoak',
	layerItem: null,
	server: 'terrene',
	title: 'Red Oak spp.',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 6,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'prj-loss-sp-amelm',
	layerItem: null,
	server: 'terrene',
	title: 'American Elm',
	folder: 'SAFEA',
	mapServer: 'Projected_loss_rate',
	layerId: 7,
	opacityBar: null,
	parentDiv: 'hazPlrMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}];


export const projectedLossPestsEastGroup = {id: `aa-hl-hazard-pl-pe`, title:'Projected Loss: Pests East', type:'group', items:[]}
export const projectedLossEmeraldAshBorerGroup = {id: `aa-hl-hazard-pl-eab`, title:'Projected Loss: Emerald Ash Borer', type:'group', items:[]}
export const projectedLossRateSpeciesGroup = {id: `aa-hl-hazard-plr-s`, title:'Projected Loss Rate: Species', type:'group', items:[]}

projectedLossPestsEastGroup.items = projectedLossPestsEast.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-hazard-pl-pe-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});

projectedLossEmeraldAshBorerGroup.items = projectedLossEmeraldAshBorer.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-hazard-pl-eab-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});

projectedLossRateSpeciesGroup.items = projectedLossRateSpecies.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-hazard-plr-s-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});