import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const connHabitPinelands = [{
	layerKey: 'pineConHab-wildlife',
	server: 'terrene',
	title: 'NJDEP Terrestrial Wildlife Habitat Stepping Stones',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Connecting_Habitat',
	layerId: 0,
	parentDiv: 'pineConHab',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'pineConHab-chanj',
	server: 'terrene',
	title: 'The CHANJ feature',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Connecting_Habitat',
	layerId: 1,
	parentDiv: 'pineConHab',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'pineConHab-roadThreat',
	server: 'terrene',
	title: 'Road Threat',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Connecting_Habitat',
	layerId: 2,
	parentDiv: 'pineConHab',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'pineConHab-movement',
	server: 'terrene',
	title: 'Cumulative Movement Cost',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Connecting_Habitat',
	layerId: 3,
	parentDiv: 'pineConHab',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}];

export const plConnectingHabitats = {id: `aa-pl-ch`, title:'Connecting Habitats', type:'group', items:[],
description: `Connecting Habitat Across New Jersey (CHANJ) administered by the Endangered and Nongame Species Program within the DEP’s Division of Fish and Wildlife. It identifies intact habitats, called “Cores” and the opportunities to link them, called “Corridors.” The tab also includes road segments that cut through habitats and are likely to pose problems for animal movement. The data was collected form NJDEP GIS Environmental Open Data via an online CHANJ Web Viewer.`
}

plConnectingHabitats.items = connHabitPinelands.map( (floodmapperObj) => {
	const thisLayer = new FeatureLayer({
		url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
		, title: floodmapperObj.title
		, outFields: ['*']
		, popupEnabled: true
	});

	thisLayer.when(() => {
		//Create a template of literally all the fields
		thisLayer.popupTemplate = thisLayer.createPopupTemplate();
	});

	return {
		layerId: `aa-pl-ch-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: thisLayer,
		meta:null}
});
