import React from "react";
import { connect } from "react-redux";

/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const GeneralInfraPriority = () => {


	return <>
		<div slot="header" id="modal-title">Infrastructure Focused Priority</div>
		<div slot="content">
			<p>Infrastructure focused priority has several infrastructures focused sub-scenarios.</p>
		</div> 
	</>
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfraPriority);
