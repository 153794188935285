import React, {useState, useEffect} from "react";
import { connect } from "react-redux";

import ArcGISOnlinePortalLoggedOutPanel from "./ArcGISOnlinePortalLoggedOutPanel";
import ArcGISOnlinePortalSignedInPanel from "./ArcGISOnlinePortalSignedInPanel";

//import Portal from "@arcgis/core/portal/Portal";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
import { CalcitePanel } from "@esri/calcite-components-react";

const OAUTH_INFO = new OAuthInfo({
	appId:'AuzQR9e1CxxAvsYX',
	//portalUrl: 'https://rutgers.maps.arcgis.com',
	popup: true,
});

esriId.registerOAuthInfos([OAUTH_INFO]);

const ArcGISOnlinePortalNavigatorPanel = ( {manualTabIndex} ) => {

	const [usePanel, SetPanel] = useState('loading');

	useEffect(()=>{

		console.log(esriId, OAUTH_INFO.portalUrl)
		
		esriId.checkSignInStatus(OAUTH_INFO.portalUrl)
		.then( (response) => {
			console.log('User is probably actually signed in: ', response);
			SetPanel('signed-in');
		})
		.catch( (response) => {
			console.log('User is probably not signed in: ', response);
			SetPanel('signed-out');
		});
		
		esriId.on('credential-create', ({credential}) => {
			SetPanel('signed-in');
			credential.on('destroy', () => {
				SetPanel('signed-out');
			})

		});

	}, [ ]);


	//console.log('esriId', esriId);


	const OutputPortalPanel = () => {
		if( 
			usePanel === 'loading'
			|| usePanel === 'signed-out'
		) {
			return (
				<ArcGISOnlinePortalLoggedOutPanel />
			);
		}
		return <ArcGISOnlinePortalSignedInPanel />;
	}

	return (
		<CalcitePanel
			style={{display: ( manualTabIndex === 'asb-arcgis-online' ? 'flex' : 'none')}}
			loading={usePanel==='loading'}
			collapsed={false}
		>
		<div className="content-area">
			{OutputPortalPanel()}
		</div>		
		</CalcitePanel>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(ArcGISOnlinePortalNavigatorPanel);
