
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const equalLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/`
	, sublayers: [{id:1}]
	, title: 'Equal priority of each infrastructure focused factors'
});
export const equal = 
{
	id:'sa-hl-agri-prio',
	title:"Equal Priority of Factors",
	type:'group',
	showScenario:'pinelandsEqualPrio',
	items:[
	
	{layerId:'sa-hl-alcp-main', type: 'layer', item: equalLayer, meta:null},
	
	{
		id: 'sa-hl-alcp-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [{
			layerKey: 'pineEqual-lulc',
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 8,
			parentDiv: 'pineEqualDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineEqual-train',
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 9,
			parentDiv: 'pineEqualDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineEqual-roads',
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 10,
			parentDiv: 'pineEqualDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineEqual-res',
			server: 'terrene',
			title: 'Distance to High Density Residential Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 11,
			parentDiv: 'pineEqualDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineEqual-sewer',
			server: 'terrene',
			title: 'Sewer Service Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 12,
			parentDiv: 'pineEqualDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-hl-alcp-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};