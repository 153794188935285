import { plConnectingHabitats } from "./connectingHabitats";

import { plEconomicInfra } from "./economicInfrastructure";
import { plEnvironHabitats } from "./environmentalHabitats";
import { plForest } from "./forest";
import { plHazard } from "./hazards";
import { plHydrologyWater } from "./hydrologyWater";
import { plLulc } from "./landUseLandCover";
import { plTreeBasalArea } from "./treedBasalArea";
import { plPlanning } from "./planning";
//import { hlConnectingHabitats } from "./connectingHabitats";
//import { hlEconomicInfra } from "./economicInfrastructure";

const assessAreaPinelands = [
	
	plConnectingHabitats
	, plEconomicInfra
	, plEnvironHabitats
	, plForest
	, plHazard
	, plHydrologyWater
	, plLulc 
	, plTreeBasalArea
	, plPlanning
];
export default assessAreaPinelands;