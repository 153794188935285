import React
,
{ 
	//useEffect,
useState } 
from "react";
import { connect } from "react-redux";

import {

	CalciteButton,
	CalcitePanel,
	CalciteRadioGroup,
	CalciteRadioGroupItem
	//CalcitePickList,
	//CalcitePickListItem,

} from "@esri/calcite-components-react";





/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPAnalysisClass = ({geographicArea, SetAnalysisClass, RemoveServicePanel}) => {
	const InstructionalText = `Select an analysis class.`;

	const [analysisClass, UpdateAnalysisClass] = useState('');



	const UpdateSelectedAnalysisClass = (calciteRadioEvent) => {
		console.log('Updating analysis class selection: ', calciteRadioEvent, calciteRadioEvent.detail);
		UpdateAnalysisClass(calciteRadioEvent.detail || null);
	}

	/*
	const [pickListVars, SetPickListVars] = useState(variableList);


	const UpdateSelectedVariableList = (pickListEvent) => { 
		//console.log(pickListVars, pickListEvent.detail);
		const myUpdatedPickList = pickListVars.map( (varInfo, idx) => {
			return {
				...varInfo,
				selected: pickListEvent.detail.has(varInfo.key)
			}
		});
		
		//console.log('Updating list : ', myUpdatedPickList, pickListVars);
		SetPickListVars( myUpdatedPickList );

	}
	*/
	const ConfirmSelection = () => {
		SetAnalysisClass(analysisClass);
	}



	return (<>
		
			<CalcitePanel
				key={'select-class-of-analysis'}
				heading={'Select Class of Analysis'}
				summary={InstructionalText}
				onCalcitePanelBackClick={RemoveServicePanel}
			>
				<div className="content-area">
					<div style={{marginTop:'1em',marginBottom:'1em'}}>
						<CalciteButton width="full" disabled={analysisClass === '' || undefined} onClick={ConfirmSelection}>Select Class of Analysis</CalciteButton>
					</div>
					<h3 style={{marginBottom:'.5rem'}}>Class of Analysis</h3>
					<div>
						<CalciteRadioGroup layout="vertical" width="full" scale="l" onCalciteRadioGroupChange={UpdateSelectedAnalysisClass} >
							
							<CalciteRadioGroupItem icon="" label={`Priority Based`} value={`priority-based`} >Priority Based</CalciteRadioGroupItem>
							<CalciteRadioGroupItem icon="" label={`Distance Based`} value={`distance-based`} >Distance Based</CalciteRadioGroupItem>
							<CalciteRadioGroupItem icon="" label={`Attribute Based`} value={`attribute-based`} >Attribute Based</CalciteRadioGroupItem>
							<CalciteRadioGroupItem icon="" label={`Cluster Based`} value={`cluster-based`} >Cluster Based</CalciteRadioGroupItem>
						</CalciteRadioGroup>
					</div>
					<div style={{marginTop:'1em',marginBottom:'1em'}}>
						<CalciteButton width="full" disabled={analysisClass === '' || undefined} onClick={ConfirmSelection}>Select Class of Analysis</CalciteButton>
					</div>
				</div>
			</CalcitePanel>

	</>);
};



const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPAnalysisClass);

/*

						<CalcitePickList>
							<CalcitePickListItem icon="square" label={`Priority Based`} value={`priority-based`} />
							<CalcitePickListItem icon="square" label={`Distance Based`} value={`distance-based`} />
							<CalcitePickListItem icon="square" disabled={true} label={`Attribute Based`} value={`attribute-based`} />
							<CalcitePickListItem icon="square" disabled={true} label={`Cluster Based`} value={`cluster-based`} />
						</CalcitePickList>
						
*/
/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/