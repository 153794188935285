import React, { useRef, 
	useEffect,
	useState 
} from "react";
import { connect } from "react-redux";


//import LayerList from "@arcgis/core/widgets/LayerList";
//import { layerCompilation } from "./Layers/allLayersJson";
import { CalciteFlow, CalcitePanel, 
	CalciteButton,
	CalciteAction,
	CalcitePickList,
	CalcitePickListGroup,
	CalcitePickListItem,
	//CalcitePopoverManager,
	//CalcitePopover
} from "@esri/calcite-components-react";
import {loadLayer, removeLayer} from '../../../../Flux/thunks';
import { updateTip } from "../../../../Flux/actions";
/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const SefeaLayersPanel = ({availableLayers, onUpdateTip, loadLayerOnMap, 
	removeLayerFromMap, isMapLoading, arcgisMap, useLayerSet, OpenScenarioModal}) => {
//console.log('MAP LAYERS: ', layerCompilation, availableLayers);
	const mapLayersDiv = useRef(null);
	
	const [panels, UpdatePanels] = useState([]);
	const useAvailLayers = availableLayers.find( (availableLayerItem) => { return availableLayerItem.id === useLayerSet; } );

	//console.log('[SefeaLayersPanel] useAvailLayers', useAvailLayers);
	useEffect(() => {
		
	}, [panels]);
	

	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
		
	  	if (mapLayersDiv.current && arcgisMap.map) {
			  
		}
	}, [arcgisMap]);
	
	
	const ClearAllPanels = () => {
		console.log('[SefeaLayersPanel] Clearing all panels');
		UpdatePanels([]);
	}


	const AddLayerToMap = (layerItem, _event) => {
		//console.log('[MapLayersFlowPanel] DUMB: Adding layer to map.', layerItem);
		loadLayerOnMap(layerItem);
		//loadLayerOnMap
	};

	const RemoveServicePanel = (event) => {
		//console.log('Removing Panel: ', event);
		event.stopPropagation();
		UpdatePanels( panels.slice(0,-1) );
	}
	const GoToMenu = (_e) => {
		//console.log('EVENT TEST BUTTON', _e.target);
		UpdatePanels( [...panels, 
			{key: _e.target.value, title: _e.target.title, items: _e.target.items, description: _e.target.description, 
				showScenario: _e.target.showScenario || null
			}] 
			
			);
	}

	const CreateElementFromItem = (selectionItem, key) => {
		if( selectionItem.type === 'group' ) {
			return (
				<CalcitePickListItem key={key} 
					heading={selectionItem.title} 
					description={selectionItem.title} 
					onCalciteListItemChange={ (_e) => { 
						if( _e.detail && _e.detail.selected) {
							//SearchServer(_e.detail.value);
							GoToMenu(_e);
							_e.target.selected=false;
						}
					}}
					title={selectionItem.title}
					items={selectionItem.items}
					showScenario={selectionItem.showScenario || undefined }
					>
					<CalciteAction slot="actions-end" icon="chevrons-right" 
						title={selectionItem.title}
						description={selectionItem.description}
						items={selectionItem.items}
						showScenario={selectionItem.showScenario || undefined }
						onClick={ (_e) => { GoToMenu(_e) }}></CalciteAction>
				</CalcitePickListItem>

				);
			}else if( selectionItem.type === 'layer') {
				const appendHelper = {};
				if( !selectionItem.meta ) {
					appendHelper.disabled=true;
				}
				return (
					<CalcitePickListItem key={key} 
					heading={selectionItem.item.title} 
					description={selectionItem.item.title} 
					title={selectionItem.item.title} 

					value={selectionItem.item} 
					onCalciteListItemChange={ (_e) => { 
						if( _e.detail && _e.detail.selected) {
							//SearchServer(_e.detail.value);
							//AddLayerToMap()
						}
					}}
					>
					<CalciteAction 
						slot="actions-end"
						icon="information" 
						title="Metadata"
						{...appendHelper}
						onClick={ (_e) => { console.log('Activating Metadata...', _e); 
							if(selectionItem.meta ) {
								const {layerTitle, layerDescription, mapService, authoritiveSource} = selectionItem.meta;
								onUpdateTip(layerTitle, layerDescription, mapService, authoritiveSource);
							}
						}}></CalciteAction>
					<CalciteAction slot="actions-end" icon={
						selectionItem.type === 'tiles' ? 'tile-layer' : 'add-layer'
					} onClick={ (_e) => { 
						//SearchServer(layers.url); 
						AddLayerToMap(selectionItem.item);
					}}></CalciteAction></CalcitePickListItem>
				);
			}
			return null;
	}

	const CreateMenuSystem = (currentSelection, title="", mainKey=null, description=null, options={}) => {

		const groupSets = currentSelection
		.filter( (selectionItem) => { return selectionItem.type === 'group-set'});
		//console.log('Creating Menu System with the following items: ', description);
//console.log('[CreateMenuSystem]', currentSelection, options);

		return (
			<CalcitePanel
			key={mainKey || 'head'}
				heading={title === '' ? 'Main Menu' : title}
				onCalcitePanelBackClick={(e)=>{RemoveServicePanel(e);}}
			>
				{
					description ?
					<p><small>{description}</small></p> : null
				}
				
				{
					options && options.showScenario ? 
					<CalciteAction 
						slot="header-actions-end"
						icon="information" 
						title="Show Information"
						textEnabled={true}
						text={undefined}
						onClick={ (_e) => { 
							OpenScenarioModal( options.showScenario );
						}}></CalciteAction>
					:null

				}
				<CalcitePickList>

				

				{currentSelection
				.filter( (selectionItem) => { return selectionItem.type !== 'group-set'})
				.map( (selectionItem, key) => {
					return CreateElementFromItem(selectionItem, key);
				})}
				{
					groupSets.length > 0 ?
					(
						
						groupSets.map( (selectionItem, key) => {
							return (
							<CalcitePickListGroup groupTitle={selectionItem.title} key={key}>
								{selectionItem.items.map( (specificItem, key2)=> {
									return CreateElementFromItem(specificItem, key2);
								})
								}
							</CalcitePickListGroup>);
						}) 
						
					)					
					: null
				}
				</CalcitePickList>
			</CalcitePanel>
		);

	}
	return (
		<>
			<div className="content-area">
			<CalciteButton
				width={"full"}
				onClick={(_e) => { 
					ClearAllPanels();
				}}
				iconStart="reset"
			>
				Go to Main Menu
			</CalciteButton>
			</div>
			<CalciteFlow>
				{CreateMenuSystem( useAvailLayers && useAvailLayers.items ? useAvailLayers.items : [], 'Region', 
				null, null, {
					showScenario: useAvailLayers && useAvailLayers.showScenario ? useAvailLayers.showScenario : null
				} )}
				{panels.map( (panelItem, key)=> {
					console.log('Panel Item???? ', panelItem);
					return CreateMenuSystem(panelItem.items, panelItem.title, key, panelItem.description, {
						showScenario: panelItem.showScenario ? panelItem.showScenario : null
					});
				})}
			</CalciteFlow>
		</>
	);
}

const mapStateToProps = (state) => ({
    availableLayers: state.availableLayers,
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onUpdateTip: (layerTitle, layerDescription, mapService, authoritiveSource) => {dispatch(updateTip(layerTitle, layerDescription, mapService, authoritiveSource))},
	loadLayerOnMap: (layer) => {dispatch(loadLayer(layer))},
	removeLayerFromMap: (layer) => {dispatch(removeLayer(layer))}
});

export default connect(mapStateToProps, mapDispatchToProps)(SefeaLayersPanel);
