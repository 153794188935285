import React
, { 
	//useRef, 
	useEffect 
} 
from "react";
import { connect } from 'react-redux';
import { closeAlert, clearAlerts } from '../../../../Flux/actions';
import { CalciteAlert } from "@esri/calcite-components-react";

const AlertList = ({alertList, onAlertClose, onAlertClearList}) => {
//This item is a bit messed up, so I'm going to leave it as is and figure out a way to make it more react-y
	//console.log('The current alertList: ', alertList);

	useEffect( () => {
		//console.log('Alert list is updated: ', alertList);
	}, [alertList]);


	return (
		<>
		{alertList
		//.filter((alertItem) => { return alertItem.isRead === false; })
		.map( (alertListItem, key) => {
			return (
				<CalciteAlert key={key} color={alertListItem.color} active={alertListItem.isRead === false} 
				
				onCalciteAlertClose={(e)=>{
					e.preventDefault();
					if( e.detail.queue.length === 0 ) {
						onAlertClearList();
					}
					//e.detail.preventDefault();
					console.log('alert closed.',e,  key);
					//onAlertClose(key);
				}}>
						<div slot="title">{alertListItem.title}</div>
						<div slot="message">{alertListItem.message}</div>
				</CalciteAlert>
			)
		})}
		</>
	);
}



const mapStateToProps = (state) => ({
    alertList: state.alertList
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onAlertClose: (key) => {dispatch(closeAlert(key))},
	onAlertClearList: (key) => {dispatch(clearAlerts())}
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);




//export default AlertList;



/*

		<CalciteAlert active={true}>
				<div slot="title">Title</div>
				<div slot="message">Message</div>
		</CalciteAlert>
*/