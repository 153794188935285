import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const boundHighlands = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Boundary_Layers/MapServer/0"
	, title: 'Highlands Boundary'
	, outFields: ['*']
	, popupEnabled: true
});
const boundCounties = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Boundary_Layers/MapServer/1"
	, title: 'Counties'
	, outFields: ['*']
	, popupEnabled: true
});
const boundMun = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Boundary_Layers/MapServer/2"
	, title: 'Muncipalities'
	, outFields: ['*']
	, popupEnabled: true
});
const boundParc = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Boundary_Layers/MapServer/3"
	, title: 'Property Parcels'
	, outFields: ['*']
	, popupEnabled: true
});


boundHighlands.when(() => {
	//Create a template of literally all the fields
	boundHighlands.popupTemplate = boundHighlands.createPopupTemplate();
});

boundCounties.when(() => {
	//Create a template of literally all the fields
	boundCounties.popupTemplate = boundCounties.createPopupTemplate();
});

boundMun.when(() => {
	//Create a template of literally all the fields
	boundMun.popupTemplate = boundMun.createPopupTemplate();
});

boundParc.when(() => {
	//Create a template of literally all the fields
	boundParc.popupTemplate = boundParc.createPopupTemplate();
});



export const boundaryHighlands = {
	id: 'bl-hl-hbl',
	title:"Highlands Boundary Layers",
	type:'group',
	
	items: [
		{layerId:'bl-hl-hbl-highlands', type: 'layer', item: boundHighlands, meta:null},
		{layerId:'bl-hl-hbl-counties', type: 'layer', item: boundCounties, meta:null},
		{layerId:'bl-hl-hbl-mun', type: 'layer', item: boundMun, meta:null},
		{layerId:'bl-hl-hbl-parc', type: 'layer', item: boundParc, meta:null},
	]
}

