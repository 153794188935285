import { equal } from "./equal";
import {infra} from './infra'
import { medium } from "./medium";
import { high } from "./high";

//import { trans } from "./trans";
import { hab } from "./hab";

const scenarioAssessPinelands = [
	equal
	,infra
	,medium
	,high
	//,trans
	,hab
];
export default scenarioAssessPinelands;