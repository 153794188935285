import React, { useState, 
	//useHistory 
} from 'react';
//import { Link } from 'react-router-dom';
import { 
  CalciteShell,
  CalciteShellPanel,
  CalcitePanel,  
  CalciteAction,
  CalciteButton,
  //CalciteTab, CalciteTabNav 
} from "@esri/calcite-components-react";
//import LinkToCalciteButton from '../Extenders/LinkToCalciteButton';

import UserGuidePdf from './pdfs/User Guide.pdf';
//import {ReactComponent as  MySvgImage } from './images/placeholder.svg';
import RUShield from './images/rushield.png';

const GenericHeader = () => {
	//<CalciteButton target="_blank" color="neutral" href="https://google.com" icon-start="link" label="Google Link">Button</CalciteButton>
	//In CalcitePanel; <MySvgImage slot="header-actions-start" />
	const [isCollapsed, setIsCollapsed] = useState(true);
	return (
		<>
			<CalcitePanel slot="header" heading={process.env.REACT_APP_HEADER_FULL_TITLE || process.env.REACT_APP_HEADER_SHORT_TITLE}  >
				<CalciteAction title={`${process.env.REACT_APP_HEADER_SHORT_TITLE} Site Menu`} icon="hamburger" slot="header-actions-start" onClick={(e)=>{setIsCollapsed(!isCollapsed)}}></CalciteAction>
				<img slot="header-actions-start" src={RUShield} style={{ maxHeight:'45px'}} alt={"Rutgers Shield Logo"}/>
			</CalcitePanel>
			<CalciteShellPanel style={{position:'fixed', top: '50px', left:0, bottom:0, maxWidth:'100%', zIndex: 999999}} 
				slot="primary-panel"  position="start" collapsed={isCollapsed} heading={process.env.REACT_APP_HEADER_SHORT_TITLE }
				widthScale="m"
				detached="true">
				<CalciteShell heading={`${process.env.REACT_APP_HEADER_SHORT_TITLE} Hamburger Menu`}>
					<CalciteButton color="neutral" to="/" href="/" icon-start="home" label="Home">Home</CalciteButton>
					<CalciteButton color="neutral" to="/data-sources" href="/data-sources" icon-start="data" label="/data-sources">Data Sources</CalciteButton>
					<CalciteButton color="neutral" to="/presentation" href="/presentation" icon-start="video" label="/presentation">Presentation</CalciteButton>
					<CalciteButton color="neutral" target="_blank" to={UserGuidePdf} href={UserGuidePdf} icon-start="file-pdf" label="/data-sources">User Guide</CalciteButton>
				</CalciteShell>
			</CalciteShellPanel>
		</>
	);
}

export default GenericHeader;