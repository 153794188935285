import React from 'react';
import { CalcitePanel } from "@esri/calcite-components-react";

import PresentationAudio from '../../Common/presentation/GMT20220927-145329_Recording.cutfile.20221010190152319.m4a';
import PresentationVideo from '../../Common/presentation/GMT20220927-145329_Recording.cutfile.20221010190152319_1920x1080.mp4';
import PresentationCaptions from '../../Common/presentation/GMT20220927-145329_Recording.cutfile.20221010190152319.cc.vtt';


import { CalciteButton } from '@esri/calcite-components-react';
const PresentationPage = () => {

	return ( <CalcitePanel slot="center-row">
		
	<div className="presentation">

		<div className="presentation-content">
			<h2 className="text-center ">Presentation Video / Audio</h2>
			<p>You can watch the video directly from the website or download. </p>
			<div>
				<h3> Watch Online</h3>
			<p><em>Captions start at [22 min 13 sec].</em></p>
				<video id="video" style={{maxWidth:'100%'}} controls preload="metadata">
					<source src={PresentationVideo} type="video/mp4" />
					<track
						label="English"
						kind="subtitles"
						srclang="en"
						src={PresentationCaptions}
						default />
				</video>
			</div>
			<div>
				<h3>Download</h3>
				<ul>
					<li><strong>Video (250 MB)</strong>: 
						<CalciteButton color="neutral" target="_blank" to={PresentationVideo} href={PresentationVideo} icon-start="file-video" 
							>Video Recording (.m4a)</CalciteButton></li>
					<li><strong>Caption File (47 KB)</strong>: 
						<CalciteButton color="neutral" target="_blank" to={PresentationCaptions} 
							href={PresentationCaptions} icon-start="file-text" >Caption (.cc.vtt)</CalciteButton></li>
					<li><strong>Audio Only (67 MB)</strong>: 
						<CalciteButton color="neutral" target="_blank" to={PresentationAudio} href={PresentationAudio} icon-start="file-sound" >Audio Recording (.m4a)</CalciteButton>
					</li>
				</ul>
			</div>
		</div>
		
		


	</div>
	</CalcitePanel> );
}


export default PresentationPage;