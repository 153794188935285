import axios from "axios";
import {Buffer} from 'buffer';

import { 
	loadMapInProgress, loadMapFailure, loadMapSuccess ,
	urlParamsSet,
	loadLayerInProgress,
	loadLayerFailure,
	loadLayerSuccess,
	
	layerAddedToList,
	//layerAddedToListByIds,
	layerListAddCustomLayer,
	layerRemovedFromList,
	readLayerList,

	customLayerStorageIsLoading,
	customLayerStorageLoadSuccessful,
	customLayerStorageLoadFailed,
	customLayerStorageAdd,


	serverBrowserAddCache,
	serverBrowserIsLoading,
	serverBrowserLoadSuccessful,
	serverBrowserLoadFailed,
	serverBrowserAddPanel,
	layerListAddAHPLayer,

	ahpRasterStartLoading,
	ahpRasterIsDoneLoading,
	//ahpRastersLoading

	//Graphing Options
	/*
	graphIsInitializing,
	graphIsReady,
	graphIsNotReady,
	graphIsRetrievingData,
	graphLoadSuccessful,
	graphLoadFailed,
	graphOpened
	*/

} from "./actions";

import * as watchUtils from "@arcgis/core/core/watchUtils";
//When updating to 4.24 ( https://developers.arcgis.com/javascript/latest/api-reference/esri-core-reactiveUtils.html )
//import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

import { GetCurrentUrlOptions } from "../Logic/UrlOptionsLogic";

import * as esriNS from "@arcgis/core/kernel";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import { layerCompilation } from "../Pages/Map/Layers";

//import PortalItem from "@arcgis/core/portal/PortalItem";
import Layer from "@arcgis/core/layers/Layer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import TileLayer from '@arcgis/core/layers/TileLayer';
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
//import SubtypeGroupLayer from "@arcgis/core/layers/SubtypeGroupLayer";



/*
export const loadGraphingSelectOptions = ( ) => {
	return async (dispatch, getState) => {

		const {graphingOptions} = getState();

		console.log('Graphing state: ', graphingOptions.isInitializing);
		if( graphingOptions.isInitializing || graphingOptions.isReady ) {
			console.log('Already initializing graphing options.');
			return;
		}

		dispatch(graphIsInitializing());
		GetGraphingOptions()
		.then( (response) => {
			console.log('[Graphing Options] Loaded information from the API');
			if( response.data.code === 'OK' ) {
				//should only appear on an error
				dispatch(graphIsReady(response.data.payload));
			}else{
				dispatch(graphIsNotReady());
			}
			return response;
		})
		.catch( (response) => {
			console.log('[Graphing Options] Failed to load basic information from the API.');
			dispatch(graphIsNotReady());
			return response;
		})

	}
	
}


export const loadGraphingData = ( dataOptions ) => {
	return async (dispatch, getState) => {
		dispatch(graphIsRetrievingData());
		GetGraphingData(dataOptions)
		.then( (response) => {
			console.log('[Graphing Data] Loaded data from the API');
			if( response.data.code === 'OK' ) {
				//should only appear on an error
				dispatch(graphLoadSuccessful(CompileDataIntoHighcharts(response.data.payload)));
				dispatch(graphOpened());
			}else{
				dispatch(graphLoadFailed());
			}
			return response;
		})
		.catch( (response) => {
			console.log('[Graphing Options] Failed to load raw data from the API.', response);
			dispatch(graphLoadFailed());
			return response;
		})

	}
	
}
*/

//thunks must return a function
export const loadMap = (shareMapKey) => {
	return async (dispatch, getState) => {
		dispatch(loadMapInProgress());
		dispatch(readLayerList(layerCompilation));


		try { 
			//https://developers.arcgis.com/javascript/latest/api-reference/esri-config.html if we need api key
			const myCurrentUrlOptions = await GetCurrentUrlOptions(shareMapKey);
			console.log('Here are the detected URL options to reload: ', myCurrentUrlOptions);
			dispatch(urlParamsSet(myCurrentUrlOptions) );
			console.log('Version of ArcGIS Map: ', esriNS.version, myCurrentUrlOptions);


			//default map config
			let basemap = 'topo-vector';
			let center = {
				latitude: 40.25638051088129,
				longitude: -74.59518427587497,
			};
			
			let zoom = 8;
			if( myCurrentUrlOptions.params ) {
				if( myCurrentUrlOptions.params.basemap ) {
					basemap = myCurrentUrlOptions.params.basemap;
				}
				if( myCurrentUrlOptions.params.center ) {
					center = myCurrentUrlOptions.params.center;
				}
				if( myCurrentUrlOptions.params.zoom ) {
					zoom = myCurrentUrlOptions.params.zoom;
				}
			}
			
			/*
			const feature = new FeatureLayer({
				portalItem: {
					id: "4918cb21cebf4fc7984d78c8497acc1f"
				}
			});
			webmap.add(feature);
			*/
			const webmap = new WebMap({
				basemap
			});
			const view = new MapView({
				//container: mapDiv.current,
				map: webmap,
				center,
				zoom
			});
			const scaleBar = new ScaleBar({
				view: view
			});
			// Add widget to the bottom left corner of the view
			view.ui.add(scaleBar, {
				position: "bottom-left"
			});
			console.log(webmap, view);
			//dispatch(loadMapSuccess({view}));
			
			//Not really necessary, just wanted to test the loader by creating a load time.
			setTimeout( () => {
				dispatch(loadMapSuccess(view));
				//console.log('Double checking params...', myCurrentUrlOptions);
				if(myCurrentUrlOptions.params && myCurrentUrlOptions.params.layers && myCurrentUrlOptions.params.layers.length>0 ) {
					dispatch(loadLayersById(myCurrentUrlOptions.params.layers));
				}
			}, 1500);
			
		}catch( e ) {
			dispatch(loadMapFailure());
		}

	};
}

const _findLayerInList = (availableLayers, layerItem, dispatch, _callback= () => {}) => {
	
	return availableLayers.forEach( async (checkItem) => {
		if( checkItem.type === 'group' ) {
			return await _findLayerInList(checkItem.items, layerItem, dispatch, _callback);
		}

		//Check sublayers
		if( checkItem.containsSublayers && checkItem.containsSublayers.sublayerItems) {
			const findOne = checkItem.containsSublayers.sublayerItems.find( (sublayerItem) =>{ 
				return sublayerItem.layerId === layerItem.layerId;
			});

			if( findOne ) {
				dispatch(_callback(findOne.item));
			}
			return true;
		}

		if(checkItem.layerId === layerItem.layerId ){
			
			checkItem.item.opacity = layerItem.opacity;
			checkItem.item.visible = layerItem.visible;
			dispatch(_callback(checkItem.item));
		}
		return true;
	});
}

export const loadLayersById = (layerInfos) => {
	return (dispatch, getState) => {
		dispatch(loadLayerInProgress());
		const {availableLayers} = getState();

		layerInfos.forEach( async (layerItem) => {
			console.log('[loadLayersById]', layerInfos);

			if( layerItem.custom ) {
				await _createCustomLayerService(layerItem, dispatch, loadLayer);
			}else{
				await _findLayerInList(availableLayers, layerItem, dispatch, loadLayer);
			}
		});
		dispatch(loadLayerSuccess());
	};
}

const _createCustomLayerService = async (layerItem, dispatch, loadLayer) => {
	const arcserviceUrl = Buffer.from(layerItem.layerId, 'base64').toString();

		console.log('[_createCustomLayerService] Reloading CUSTOM layer Item: ', layerItem, arcserviceUrl);
		if( layerItem.isPortal ){
			const portalLayer = Layer.fromPortalItem({
				portalItem:{id:layerItem.trimLayerId}
			});
			//Currently if we're not conscious about loading stuff, the title may not load. 
			 await portalLayer.then((returnLayer) => {
				//Loads the resources referenced by this class. (https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html#load)
				returnLayer.load().then( (newLayer)=> {
					newLayer.when(() => {
						if(newLayer.createPopupTemplate ) {
							newLayer.popupTemplate = newLayer.createPopupTemplate();
						}

						newLayer.visible= layerItem.visible;

						dispatch(layerListAddCustomLayer(arcserviceUrl, newLayer));
						dispatch(loadLayer(newLayer));
					});
				});
			});

		}else if( layerItem.rasterRenderer ){
			const ahpServiceUrl = layerItem.ahpServiceUrl;
			const myRenderingRule = Buffer.from(layerItem.rasterRenderer, 'base64').toString();

			console.log("UrlOptionsLogic - rendering: ", myRenderingRule);
			//recreate mask
			const myRenderingRuleJson = JSON.parse(myRenderingRule);

			function findCalculator (renderRule) {
				//console.log('myRenderingRuleJson checking: ', renderRule);
				if(renderRule.rasterFunction 
					&& renderRule.rasterFunction === 'RasterCalculator') {
					return renderRule;
				}
				if( renderRule.rasterFunctionArguments.Raster ) {
					return findCalculator(renderRule.rasterFunctionArguments.Raster);
				}else if( renderRule.rasterFunctionArguments.raster ) {
					return findCalculator(renderRule.rasterFunctionArguments.raster);
				}

				return null;
			}

			//console.log('myRenderingRuleJson: ', myRenderingRuleJson)
			const myBaseCalc = findCalculator(myRenderingRuleJson);

			const myMaskRule = {
				"rasterFunction": "Mask",
				"rasterFunctionArguments":{
					"IncludedRanges":[0.00000000001,10],
					"NoDataInterpretation": 'all',
					"Raster": myBaseCalc || null
				}
			};

			const AHP_TOOL = new ImageryLayer({
				title: layerItem.ahpTitle,
				url: ahpServiceUrl,
				// apply the most recent raster function to the chain
				renderingRule:myRenderingRuleJson //JSON.parse(myRenderingRule)
				
			, renderer: {
				type: 'unique-value',
				field:'Raster.ServicePixelValue',
				legendOptions: {
					title: "Importance"
				},
				defaultLabel: 'Unknown Value',
				defaultSymbol: {
					type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
					size: 1,
					color: "black",
					outline: {  // autocasts as new SimpleLineSymbol()
					  width: 1,
					  color: "white"
					}
				},
				uniqueValueInfos: [{
					value:1,
					label: 'Low',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [249, 210, 197, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [249, 210, 197, 1]
						}
					}
				},{
					value:2,
					label: 'Medium',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [230, 130, 97, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [230, 130, 97, 1]
						}
					}
				},{
					value:3,
					label: 'High',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [173, 60, 22, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [173, 60, 22, 1]
						}
					}
				}]
			}
				, format: 'lerc'
				//, interpolation: 'nearest'
				, noDataInterpretation: 'any'
				//, noDataInterpretation: 'all'
				//, pixelType: 'unknown'
				, noData:0
				, opacity: layerItem.opacity || 1
			});

			AHP_TOOL.visible= layerItem.visible;
			
			AHP_TOOL.on('layerview-create', ({view, layerView}) => {
				dispatch(attachRasterEvents(AHP_TOOL, layerView));
			});

			dispatch(layerListAddAHPLayer(layerItem.layerId, AHP_TOOL, myRenderingRule, myMaskRule));
			
			dispatch(loadLayer(AHP_TOOL));

		}else if( layerItem.type === 'feature') {
			const newLayer = new FeatureLayer({
				url: arcserviceUrl,
				layerId: layerItem.useLayerId || null
			});
			newLayer.when(() => {
				//Create a template of literally all the fields because screw it. :3
				newLayer.popupTemplate = newLayer.createPopupTemplate();
			});
			newLayer.visible= layerItem.visible;
			dispatch(layerListAddCustomLayer(arcserviceUrl, newLayer));
			dispatch(loadLayer(newLayer));
		}else if( layerItem.type === 'tile') {
			const newLayer = new TileLayer({
				url: arcserviceUrl
			});
			
			newLayer.visible= layerItem.visible;
			dispatch(layerListAddCustomLayer(arcserviceUrl, newLayer));
			dispatch(loadLayer(newLayer));
		}else if( layerItem.type === 'map-image') {
			console.log('THUNK', layerItem);
			const rasterUrl = arcserviceUrl.replace( layerItem.trimLayerId, '');
			const newLayer = new MapImageLayer({
				title: layerItem.title || undefined,
				url: rasterUrl,
				sublayers: layerItem.sublayers || null
			});
			
			newLayer.visible= layerItem.visible;
			dispatch(layerListAddCustomLayer(arcserviceUrl, newLayer));
			dispatch(loadLayer(newLayer));
			/*
			console.log('THUNK ', Buffer.from(layerItem.useLayerId, 'base64').toString(), layerItem.sublayers);
			//console.log(layerId, arcserviceUrl.lastIndexOf(`/${layerId}`));
			//console.log(arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/${layerId}`) ));

			const newLayer = new MapImageLayer({
				url: Buffer.from(layerItem.useLayerId, 'base64').toString(), //arcserviceUrl.substring(0,  arcserviceUrl.lastIndexOf(`/${layerId}`) ),
				//url: arcserviceUrl, //.substring(0,  arcserviceUrl.lastIndexOf(`/${layerItem.userLayerId}`) ),
				sublayers: layerItem.useSublayerIds || null
			});
			
			dispatch(layerListAddCustomLayer(Buffer.from(layerItem.useLayerId, 'base64').toString(), newLayer));
			dispatch(loadLayer(newLayer));
			*/
		}/*
		else if( layerItem.type==='group-layer') {
			console.warn('Group layer not yet implemented.');
		}*/else{
			console.warn('Layer type not implemented: ', layerItem.type);
		}

	return true;

	
}


export const loadLayer = (layer) => {
	return async (dispatch, getState) => {
		dispatch(loadLayerInProgress());
		const {arcgisMap} = getState();

		
		if( layer.loadStatus === 'loaded' ) {
			//console.warn('This layer may have already been loaded on the map.', layer);
			dispatch(loadLayerSuccess());
		}
		
		layer.on("layerview-create",  (event) => {
			dispatch(loadLayerSuccess());
		});
		layer.on("layerview-create-error",  (event) => {
			dispatch(loadLayerFailure());
		});
		layer.on("layerview-destroy", (event) => {
			dispatch(loadLayerSuccess());
			dispatch(layerRemovedFromList(layer));
		});
		if( layer ) {
			arcgisMap.map.add(layer);	
			dispatch(layerAddedToList(layer));
		}

	}
}
export const attachRasterEvents = (layer, layerView) => {
	return async (dispatch, getState) => {
		//console.log(watchUtils);
		
		watchUtils.watch(layerView, "updating", (newValue) => {
			if(newValue) { 
				console.log('Raster is updating...');
				dispatch(ahpRasterStartLoading(layerView));
			}else{
				console.log('Raster is done updating...');
				dispatch(ahpRasterIsDoneLoading(layerView));
			}
		});

		layer.on( 'layerview-destroy', () => {
			dispatch(ahpRasterIsDoneLoading(layerView));
		})
		/*
		reactiveUtils.watch( 
			() => layerView.updating,
			(isUpdating) => {
				console.log('Is layer updating?', isUpdating)
			}
		);
		*/
	}
}
export const removeLayer = (layer) => {
	return async (dispatch, getState) => {
		dispatch(loadLayerInProgress());
		const {arcgisMap} = getState();

		try{
		//console.log(layer);
		//console.log(arcgisMap.map.remove(layer));
		if(layer){
			arcgisMap.map.remove(layer);
		}
		dispatch(loadLayerSuccess());
		
		}catch(e){
			console.log(e);
			dispatch(loadLayerFailure());
		}
	}
}

export const removeAllLayers = () => {
	return async (dispatch, getState) => {
		dispatch(loadLayerInProgress());
		const {arcgisMap} = getState();

		try{
		//console.log(layer);
		//console.log(arcgisMap.map.remove(layer));
		console.log(arcgisMap.map.layers);
		arcgisMap.map.removeMany(arcgisMap.map.layers);
		dispatch(loadLayerSuccess());
		
		}catch(e){
			console.log(e);
			dispatch(loadLayerFailure());
		}
	}
}





export const loadCustomService = (parent, arcserviceUrl) => {
	console.log('[Custom Serivce] Loading from', arcserviceUrl);
	return async (dispatch) => {
		dispatch(customLayerStorageIsLoading());
		
		axios
		.get(arcserviceUrl, {params:{f: 'json'}})
		.then( (response) => {
			console.log('[Custom Serivce] Loaded information from the Arcgis Service');
			if( response.data.code ) {
				//should only appear on an error
				dispatch(customLayerStorageLoadFailed());
			}else{
				dispatch(customLayerStorageLoadSuccessful());
				console.log(response);
				dispatch(customLayerStorageAdd(
					parent || null,
					arcserviceUrl,
					response.data.folders || [], 
					response.data.services || [], 
					response.data.layers || []
				));
			}
			return response;
		})
		.catch( (response) => {
			console.error('[Custom Serivce] Could not load data from Arcgis Service.', response, arcserviceUrl);
			dispatch(customLayerStorageLoadFailed());
			return response;
		})
		
	}
}
/**
 * 
 * @param {*} arcserviceUrl 
 * @param {*} folders 
 * @param {*} services 
 * @param {*} layers 
 * @returns 
 * 
 * let baseUrl probably needs to be better...
 */
const _Compile_Server_Cache = (arcserviceUrl, folders, services, layers, _extraInfo={

} ) => {
	const cacheObj = {
		arcserviceUrl,
		title: 'Base Server',
		serviceDescription: _extraInfo.serviceDescription || '',
		folders: [],
		services: [],
		layers: [],
	};

	//Enforce trailing lash on service url.
	if( arcserviceUrl.endsWith('/') === false ) {
		arcserviceUrl = `${arcserviceUrl}/`;
	}
	
	const searchForService = 'services/';
	const indexOfUrl = arcserviceUrl.lastIndexOf(searchForService);
	let baseUrl = arcserviceUrl;

	if( indexOfUrl !== -1 ) {
		const getTitleIdx = indexOfUrl + searchForService.length;
		const newTitle = arcserviceUrl.substr(getTitleIdx);

		if( newTitle.length > 0 ) {
			cacheObj.title = newTitle;
		}
		baseUrl = arcserviceUrl.substring(0, getTitleIdx);

	}
	//console.log('Checking Title output: ', {baseUrl, title: cacheObj.title, indexOfUrl});
	//start with folders first.
	folders.forEach( (folderItem) => {
		cacheObj.folders.push({
			type: 'folder',
			url: `${baseUrl}${folderItem}/`,
			name: folderItem,
			items: null
		});
	});

	services.forEach( (serviceItem) => {

		cacheObj.services.push({
			type: 'service',
			url: `${baseUrl}${serviceItem.name}/${serviceItem.type}/`,
			name: serviceItem.name,
			serverType: serviceItem.type,
			items: null,
		});
	});
	

	if( _extraInfo.serviceHasTiles ) {
		cacheObj.layers.push({
			type: 'tiles',
			url: `${arcserviceUrl}`,
			name: `Use Service Tile Layer`,
			layer: null
		});
	}
	layers.forEach( (layerItem) => {
		
		cacheObj.layers.push({
			type: (layerItem.subLayerIds ? 'tiles' : 'layers'),
			url: (
				_extraInfo.isOnlyLayer ? 
				`${arcserviceUrl}/`
				: `${arcserviceUrl}${layerItem.id}/`
			),
			name: `${layerItem.name}`,
			layerId: layerItem.id,
			layer: null
		});
	});

	return cacheObj;
}

export const loadArcServerService = ( arcserviceUrl) => {
	console.log('[ArcServer Serivce] Loading from', arcserviceUrl);
	return async (dispatch, getState) => {
		dispatch(serverBrowserIsLoading());

		const state = getState();
		console.log('CURRENT STATE: ', state);
		if( state.serverBrowserCache[arcserviceUrl] ) {
			console.log('Using Cache');
			dispatch(serverBrowserLoadSuccessful());
			dispatch(serverBrowserAddPanel( state.serverBrowserCache[arcserviceUrl] ) );
		}else{
			axios
				.get(arcserviceUrl, {params:{f: 'json'}})
				.then( (response) => {
					console.log('[ArcServer Serivce] Loaded information from the Arcgis Service', response);
					if( response.data.code ) {
						//should only appear on an error
						dispatch(serverBrowserLoadFailed());
					}else{

						//If this is a direct link to a layer, we need to trim the url of the layer id so that we can have all items follow the same protocol.

						dispatch(serverBrowserLoadSuccessful());
						const cacheObj =  _Compile_Server_Cache (
							arcserviceUrl,
							response.data.folders || [], 
							response.data.services || [], 
							response.data.layers || (
								(
									response.data.type && (
										response.data.type === 'Feature Layer'
										|| response.data.type === 'Raster Layer'
										|| response.data.type === 'Tile Layer'
									)
								)
								? [response.data]
								: null

							)|| [],
							{
								serviceHasTiles: (response.data.tileInfo ? true : false),
								serviceDescription: (response.data.serviceDescription || ''),
								isOnlyLayer : (
									response.data.type && (
										response.data.type === 'Feature Layer'
										|| response.data.type === 'Raster Layer'
										|| response.data.type === 'Tile Layer'
									)
								)
							}
						);
						dispatch(serverBrowserAddCache(
							arcserviceUrl,
							cacheObj
						));
						dispatch(serverBrowserAddPanel(cacheObj));
						
					}
					return response;
				})
				.catch( (response) => {
					console.error('[ArcServer Serivce] Could not load data from Arcgis Service.', response, arcserviceUrl);
					dispatch(serverBrowserLoadFailed());
					return response;
				})
		}
		
		
	}
}