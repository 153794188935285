import React, { useRef, 
	useEffect 
} from "react";

import { CalcitePanel } from "@esri/calcite-components-react";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";

const BasemapGalleryPanel = ({isMapLoading, arcgisMap}) => {

	const basemapRef = useRef(null);
	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (basemapRef.current &&  arcgisMap.map) {

			arcgisMap.when( () => {
				new BasemapGallery({
					view: arcgisMap,
					container: basemapRef.current
				});
			});

		}
	}, [arcgisMap]);
	//onLoadMap();
	return (
		<CalcitePanel loading={isMapLoading}>
			<div ref={basemapRef}></div>
		</CalcitePanel>
	);
}


export default BasemapGalleryPanel;