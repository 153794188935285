import React
,
{ 
	useState
} 
from "react";
import { connect } from "react-redux";

import {

	CalciteButton,
	CalcitePanel,
	CalciteRadioGroup,
	CalciteRadioGroupItem,

	//CalcitePickList,
	//CalcitePickListItem,
} from "@esri/calcite-components-react";





/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPIntro = ({UpdateGeographicArea}) => {
	const IntroductionText = `Select the region you would like to run this tool on.`;

	const [selectedRegion, SetSelectedRegion] = useState(null);


	const UpdateSelectedRegion = (calciteRadioEvent) => {
		console.log('Updating selection: ', calciteRadioEvent, calciteRadioEvent.detail);
		SetSelectedRegion(calciteRadioEvent.detail || null);
	}
	const ConfirmSelection = () => {
		UpdateGeographicArea(selectedRegion);
	}




 /*
	console.log(
		'[AHP Intro] Current Region: ', 
		selectedRegion
	);
	*/

					//{/*checked={selectedRegion==='highlands-region'||undefined}*/}
					//{/*checked={selectedRegion==='pinelands-region'||undefined}*/}

	return (<>
		
			<CalcitePanel
				key={'head'}
				heading={'Select a region'}
				summary={IntroductionText}
				style={{marginBottom:'1rem'}}
			>
				<h3 style={{marginBottom:'.5rem'}}>Region</h3>
				<div>
					<CalciteRadioGroup layout="vertical" width="full" scale="l" onCalciteRadioGroupChange={UpdateSelectedRegion} >
						<CalciteRadioGroupItem value="highlands-region" >Highlands Region</CalciteRadioGroupItem>
						<CalciteRadioGroupItem value="pinelands-region">Pinelands Region</CalciteRadioGroupItem>
					</CalciteRadioGroup>
				</div>
				<div style={{marginTop:'1em'}}>
					<CalciteButton width="full" disabled={selectedRegion===null || undefined} onClick={ConfirmSelection}>Confirm Region</CalciteButton>
				</div>
			</CalcitePanel>

	</>);
};



const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPIntro);


/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/