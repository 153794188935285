import FeatureLayer from "@arcgis/core/layers/FeatureLayer";


const plEcoInfra =[
	{
		layerKey: 'pineEconInfr-rail',
		server: 'terrene',
		title: 'Railroad Stations',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 0,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-roadNet',
		server: 'terrene',
		title: 'Roadway Network',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 1,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-powerline',
		server: 'terrene',
		title: 'Electric Power Transmission Lines',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 2,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-roads',
		server: 'terrene',
		title: 'Primary and Secondary Roads',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 3,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-hotHousing',
		server: 'terrene',
		title: 'Housing Hot Spot 2010',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 4,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-housing',
		server: 'terrene',
		title: 'Housing 2010',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 5,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-hotPop',
		server: 'terrene',
		title: 'Population Hot Spot based on 2010 Census',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 6,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-pop2010',
		server: 'terrene',
		title: 'Population Counts 2010',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 7,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-openSpaceMgt',
		server: 'terrene',
		title: 'Open Space by Management Type',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 8,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-openSpaceUse',
		server: 'terrene',
		title: 'Open Space Use Label',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 9,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineEconInfr-sewer',
		server: 'terrene',
		title: 'Sewer Service Area',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Economic_Infrastructure',
		layerId: 10,
		parentDiv: 'pineEconInfr',
		defExp: '1=1',
		isFeatureLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}
];

export const plEconomicInfra = {
	id: 'aa-pl-eco',
	title:"Economic Infrastructure",
	type:'group',
	description:`Economic infrastructure GIS data can be used to support decisions, to pinpoint hot spots to allocate resources, and to advance other projects.`,
	items: [
		/*
		{layerId:'aa-pl-eco-railroad', type: 'layer', item: ecoRailroadLayer, meta:null},
		{layerId:'aa-pl-eco-power-lines', type: 'layer', item: ecoPowerlinesLayer, meta:null},
		{layerId:'aa-pl-eco-roadway', type: 'layer', item: ecoRoadwayLayer, meta:null},
		{layerId:'aa-pl-eco-pri-sec-roads', type: 'layer', item: ecoPriSecRoadsLayer, meta:null},
		{layerId:'aa-pl-eco-house-hot-spot-2010', type: 'layer', item: ecoHouseHotSpot2010Layer, meta:null},
		{layerId:'aa-pl-eco-house-2010', type: 'layer', item: ecoHouse2010Layer, meta:null},
		{layerId:'aa-pl-eco-pop-hot-spot-2010', type: 'layer', item: ecoPopHotSpot2010Layer, meta:null},
		{layerId:'aa-pl-eco-pop-2010', type: 'layer', item: ecoPop2010Layer, meta:null},
		{layerId:'aa-pl-eco-sewer', type: 'layer', item: ecoSewerLayerLayer, meta:null},
		*/
	]
};
plEconomicInfra.items = plEcoInfra.map( (floodmapperObj) => {
	const thisLayer = new FeatureLayer({
		url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
		, title: floodmapperObj.title
		, outFields: ['*']
		, popupEnabled: true
	});

	thisLayer.when(() => {
		//Create a template of literally all the fields
		thisLayer.popupTemplate = thisLayer.createPopupTemplate();
	});

	return {
		layerId: `aa-pl-eco-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: thisLayer,
		meta:null}
});
