import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const thisId = 'aa-pl-planning';
export const plPlanning = {
	id: thisId,
	title:"Planning",
	type:'group',
	description:``,
	items: [
		{
			layerKey: 'pineBound-openSpace',
			server: 'terrene',
			title: 'Open Space Use Label',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Pinelands_Boundary',
			layerId: 6,
			parentDiv: 'pineBound',
			defExp: '1=1',
			isFeatureLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, 
		{
			layerKey: 'pineBound-mgtType',
			server: 'terrene',
			title: 'Open Space Management Type',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Pinelands_Boundary',
			layerId: 5,
			parentDiv: 'pineBound',
			defExp: '1=1',
			isFeatureLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			const thisLayer = new FeatureLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
				, title: floodmapperObj.title
				, outFields: ['*']
				, popupEnabled: true
			});
		
			thisLayer.when(() => {
				//Create a template of literally all the fields
				thisLayer.popupTemplate = thisLayer.createPopupTemplate();
			});
		
			return {
				layerId: `aa-pl-os-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: thisLayer,
				meta:null}
		})
}