import React from 'react';
import { CalciteShellPanel } from "@esri/calcite-components-react";


const GenericFooter = () => {

	return ( <CalciteShellPanel slot="footer">Rutgers, The State University of New Jersey (&copy; {new Date().getFullYear()})</CalciteShellPanel> );
}


export default GenericFooter;