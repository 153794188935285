//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const plForestFM = [
	{
		layerKey: 'pineForest-type',
		server: 'terrene',
		title: 'Forest Type',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest',
		layerId: 0,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-canopy',
		server: 'terrene',
		title: 'Forest Canopy',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest',
		layerId: 1,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-group',
		server: 'terrene',
		title: 'Forest Group',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest',
		layerId: 2,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-biomass',
		server: 'terrene',
		title: 'Forest Biomass',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest',
		layerId: 3,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-abc',
		server: 'terrene',
		title: 'Carbon above ground',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 0,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-bgc',
		server: 'terrene',
		title: 'Belowground forest carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 1,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-downDead',
		server: 'terrene',
		title: 'Forest down dead carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 2,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-litter',
		server: 'terrene',
		title: 'Forest litter carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 3,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-standDead',
		server: 'terrene',
		title: 'Standing dead carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 4,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-soil',
		server: 'terrene',
		title: 'Soil organic carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 5,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-tfc',
		server: 'terrene',
		title: 'Total forest carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 6,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineForest-understory',
		server: 'terrene',
		title: 'Forest understory carbon',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Forest_Carbon',
		layerId: 7,
		parentDiv: 'pineForest',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}
];

const thisId = 'aa-pl-forest';
export const plForest = {
	id: thisId,
	title:"Forest",
	type:'group',
	description:`Forest assessment data include biomass, forest species, forest cover, forest types, and forest carbon stocks. The data can be helpful in visualization and assessing the existing forest resources.`,
	items: plForestFM.map( (floodmapperObj) => {
		return {
			layerId: `aa-pl-forest-${floodmapperObj.layerKey}`,
			type: 'layer',
			item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
				, sublayers: [{id:floodmapperObj.layerId}]
				, title: floodmapperObj.title
			}),
			meta:null}
	})
}
