
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const habLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/`
	, sublayers: [{id:5}]
	, title: 'Habitat Focused Priority'
});
export const hab = 
{
	id:'sa-pl-hab',
	title:"Habitat Focused Priority",
	type:'group',
	showScenario:'pinelandsHabitat',
	items:[
	
	{layerId:'sa-pl-hab-main', type: 'layer', item: habLayer, meta:null},
	
	{
		id: 'sa-pl-hab-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [ {
			layerKey: 'pineHab-lulc',
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 8,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineHab-train',
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 9,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineHab-roads',
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 10,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineHab-res',
			server: 'terrene',
			title: 'Distance to High Density Residential Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 11,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineHab-sewer',
			server: 'terrene',
			title: 'Sewer Service Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 12,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineHab-conHab',
			server: 'terrene',
			title: 'Connecting Habitats',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 13,
			parentDiv: 'pineHabDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}
	].map( (floodmapperObj) => {
			return {
				layerId: `sa-pl-hab-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};