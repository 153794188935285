import React
,
{ 
	useEffect,
	useState
} 
from "react";
import { connect } from "react-redux";

import {
	//CalciteIcon,
	CalcitePanel,
	CalciteButton,
	//CalciteRadioGroup,
	//CalciteRadioGroupItem
	CalciteLabel,
	CalciteInput
} from "@esri/calcite-components-react";

import {RCI_Table,GenerateMosaicRasterCalculation
	//, GenerateRasterCalculation
	, getAhpMosaicUrl
	//, AHP_MOSAIC_URL
	, LAYER_ID_HEADER
	,generateMaskRule
} from './logic';

//import * as rasterStretchCreator from "@arcgis/core/smartMapping/raster/renderers/stretch";

import {  
	layerListAddAHPLayer,
	createAlert,
	
} from '../../../../../Flux/actions';

import { loadLayer, attachRasterEvents } from '../../../../../Flux/thunks';
import RasterFunction from "@arcgis/core/layers/support/RasterFunction";



//PULL THESE FUNCTIONS OUT WHEN READY
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPCalculator = ({arcgisMap, addAHPLayerToMapLayerModule, addLayerToMap, onCreateAlert, attachRasterEvents, ratingsList, geographicArea, analysisClass,RemoveServicePanel, CloseModal}) => {

	//NOTICE:
	/**
	 * ratingList values:
	 *  6: Strongly Preferred towards first item
	 *  3: Preferred towards first item
	 *  1: equal Preference
	 *  -3: Preferred towards _SECOND_ item
	 *  -6: Strongly Preferred towards _SECOND_ item
	 */

	//Need to create the matrix
	const [ratingsMatrix, SetRatingsMatrix] = useState(null);
	const [userTitle, SetUserTitle] = useState('AHP Results');

	useEffect( () => {

		console.log('Using ratingsList: ', ratingsList)
		//This should be in order, but it's okay if it's not.
		const compiledUniqueList = ratingsList
		.reduce(
			(previousValue, currentValue, currentIndex, arrayList) => {
				if( !previousValue.find( (prevItem) => {
					return prevItem.key === currentValue.firstItem.key
				}) ) {
					previousValue.push({
						key: currentValue.firstItem.key,
						label: currentValue.firstItem.label,
						raster: currentValue.firstItem.raster,
						rasterId: currentValue.firstItem.rasterId
					});
				}
				if( !previousValue.find( (prevItem) => {
					return prevItem.key === currentValue.secondItem.key
				}) ) {
					previousValue.push({
						key: currentValue.secondItem.key,
						label: currentValue.secondItem.label,
						raster: currentValue.secondItem.raster,
						rasterId: currentValue.secondItem.rasterId
					});
				}
				
				return previousValue;
			},
			[] 
		)
		;

		//Create matrix slots (Step 3a and 3b)
		let myRatingsMatrix = compiledUniqueList.map( (varInfo) => {
			
			return {
				primaryKey: varInfo.key,
				primaryLabel: varInfo.label,
				rowAverage: null,
				consistancyValue: null,
				values: compiledUniqueList.map( (subVarInfo) => { 

					const foundRating = ratingsList.find( (findRating) => {
						return findRating.firstItem.key === varInfo.key && 
							findRating.secondItem.key === subVarInfo.key 
					}); 

					const foundInverseRating = ratingsList.find( (findRating) => {
						return findRating.firstItem.key === subVarInfo.key && 
							findRating.secondItem.key === varInfo.key 
					}); 


					console.log('????', foundRating, foundInverseRating);

					let myRating = null;

					if( foundRating ) {
						if( foundRating.value > 0 ) {
							myRating=Math.abs(foundRating.value);
						}else if( foundRating.value < 0 ) {
							myRating=1/Math.abs(foundRating.value);
						}
					}
					
					if( foundInverseRating ) {
						if( foundInverseRating.value > 0 ) {
							myRating=1/Math.abs(foundInverseRating.value);
						}else if( foundInverseRating.value < 0 ) {
							myRating=Math.abs(foundInverseRating.value);
						}
					}


					return {secondaryKey: subVarInfo.key, 
						secondaryLabel: subVarInfo.label,
						normalizedValue: null,
						value: (
							varInfo.key === subVarInfo.key ? 1 : (
							myRating
						)
					)};
				}),
				raster: varInfo.raster,
				rasterId: varInfo.rasterId
			};
		});
		//Step 4: Sum columns 
		const myColumnSum = compiledUniqueList.map( (varInfo) => { 
			const sumRatings = myRatingsMatrix
				.reduce(
					(previousValue, currentValue, currentIndex, arrayList) => {
						const findSubval = currentValue.values.find( (subVarInfo) => {
							return subVarInfo.secondaryKey === varInfo.key;
						})
						if(findSubval ) {
							previousValue+= findSubval.value
						}
					return previousValue;
				},
				0
			);
			return {key: varInfo.key, colSum: sumRatings};
		});
		console.log('Step 4a', myColumnSum);

		//Step 4b+4c: normalize + row average

		myRatingsMatrix = myRatingsMatrix.map((matrixRow) => {


			matrixRow.values = matrixRow.values.map( (valueElement) => {
				const findEigen = myColumnSum.find( (normalVal) => {
					return normalVal.key === valueElement.secondaryKey
				})

				if( findEigen && findEigen.colSum ) {
					valueElement.normalizedValue = (valueElement.value / findEigen.colSum);
				}

				return valueElement;
			});

			if( matrixRow.values.length ) {
				matrixRow.rowAverage = matrixRow.values.reduce( (prevValue, currValue) => {
					return prevValue += currValue.normalizedValue
				}, 0) /matrixRow.values.length;
			}

			return matrixRow;
		});
		



		//Step 5 time to update consistancy vectors
		myRatingsMatrix = myRatingsMatrix.map((matrixRow) => {
			
			matrixRow.consistancyValue = matrixRow.values.reduce( (prevValue, currItem) => {
				const findWeightValue = myRatingsMatrix.find( (varInfo) => {
					return varInfo.primaryKey === currItem.secondaryKey
				});

				if( !findWeightValue) {
					return prevValue;
				}
				return prevValue + (currItem.value * findWeightValue.rowAverage) ;
			}, 0);
			
			console.log( matrixRow.consistancyValue, matrixRow.rowAverage)
			matrixRow.consistancyValue = matrixRow.consistancyValue/ matrixRow.rowAverage;

			return matrixRow;
		});
		

		const lambdaValue = myRatingsMatrix.reduce( (prevValue, currentVarInfo) => {
			return prevValue + currentVarInfo.consistancyValue;
		}, 0) / myRatingsMatrix.length;

		const consistancyIndex = (lambdaValue - myRatingsMatrix.length) / (myRatingsMatrix.length- 1);
		const consistancyRatio = Number(consistancyIndex.toFixed(3)) / RCI_Table[myRatingsMatrix.length];

		console.log('Using ', RCI_Table[myRatingsMatrix.length]);



		console.log('My Final ratings Matrix: ', myRatingsMatrix);
		//detect any nulls because that would indicate a problem

		SetRatingsMatrix({
			matrix: myRatingsMatrix,
			columnSums: myColumnSum,
			lambdaValue,
			consistancyIndex,
			consistancyRatio

		});
	}, [ratingsList] );
	
	
	const AddCalculatedRasterToMap = async () => {

		console.log('AddCalculatedRasterToMap', ratingsMatrix.matrix);

		const AHP_SERVICE_URL = getAhpMosaicUrl(geographicArea, analysisClass)

		console.log('AHP_SERVICE_URL', AHP_SERVICE_URL);
		//The first service available
		const rasterArray = [];

		ratingsMatrix.matrix.forEach( (ratingItem) => {
			rasterArray.push({
				rasterId: ratingItem.rasterId,
				multiplier: ratingItem.rowAverage.toFixed(3)
			});

		});

		console.log("Running analysis using variables: ", rasterArray);

		const rasterFunction = GenerateMosaicRasterCalculation(rasterArray);

		console.log('Raster Function: ', rasterFunction);
		


		const remapFunction = new RasterFunction({
			functionName:'Remap',
			functionArguments: {
				InputRanges: [.00001,4,4,8,8,10.0001 ],
				OutputValues:[1,2,3],
				NoDataRanges:[Number.MIN_SAFE_INTEGER,0,10.0001, Number.MAX_SAFE_INTEGER],

			  raster: rasterFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});
		
		const colorFunction = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
			 colormap:[
				[3, 173, 60, 22],
				[2, 230, 130, 97],
				[1, 249, 210, 197]
			 ],
			  Raster: remapFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});

//Use to test individual pixel values
/*
		const maskFunction =new RasterFunction({
			functionName: "Mask",
			functionArguments: {
				//NoDataValues: [0,15,20,255,25,-2147483648,2147483647, 3.40282e+38, -3.40282e-38 ],
				IncludedRanges:[0.00000000001,10],
				NoDataInterpretation: 'all',
			  	Raster: rasterFunction//rasterFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'f32'
		});
		*/
		/*
		const colorFunction = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
			 "ColorrampName":"Yellow To Red",
			  Raster: rasterFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});

		const remapFunction = new RasterFunction({
			functionName:'Remap',
			functionArguments: {
				InputRanges: [.00001,4,4,7,7,10 ],
				OutputValues:[1,2,3],
				NoDataRanges:[Number.MIN_SAFE_INTEGER,.00001,10, Number.MAX_SAFE_INTEGER],

			  raster: rasterFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});

		const colorFunction = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
			 colormap:[
				[3, 255, 0, 0],
				[2, 0, 255, 0],
				[1, 0, 0, 255]
			 ],
			  Raster: remapFunction // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});
		*/

		
		/*
		{
			"rasterFunction": "Mask",
			"rasterFunctionArguments": {
			  "NoDataValues": [  //array of string
				"band0_val",
				"band1_val",
				...
			  ],
			  "IncludedRanges": [  //array of double
				band0_lowerbound,
				band0_upperbound,
				band1
				...
			  ],
			  "NoDataInterpretation": <NoDataInterpretation>,  //int 0=esriNoDataMatchAny, 1=esriNoDataMatchAll
			  "Raster": <raster>  //optional, default is the image service 
			},
			"outputPixelType": "<outputPixelType>",  //optional
			"variableName": "Raster"
		  }
*/
/*
const wtf = rasterStretchCreator.createRenderer({
	
	renderingRule: maskFunction
})
*/

		const now = new Date();
		let FINAL_RASTER = null;
		/*
		const pixelFilterCorrection = function(pixelData) {

			if (pixelData == null || pixelData.pixelBlock == null) {
			  return;
			}

			console.log(pixelData);
			let currentMin=0;
			let currentMax = 10;
  
			// The pixelBlock stores the values of all pixels visible in the view
			const pixelBlock = pixelData.pixelBlock;
			// The pixels visible in the view
			const pixels = pixelBlock.pixels;
			let mask = pixelBlock.mask;
  

			console.log('PIXEL CORRECTION: ', pixels);
			return;
			// The number of pixels in the pixelBlock
			const numPixels = pixelBlock.width * pixelBlock.height;
  
			// Get the min and max values of the data in the current view
			const minVal = pixelData.pixelBlock.statistics[0].minValue;
			const maxVal = pixelData.pixelBlock.statistics[0].maxValue;
  
			// Calculate the factor by which to determine the red and blue
			// values in the colorized version of the layer
			const factor = 255.0 / (maxVal - minVal);
			if (pixels == null) {
			  return;
			}
  
			// Get the pixels containing temperature values in the only band of the data
			const tempBand = pixels[0];
			const p1 = pixels[0];
			// Create empty arrays for each of the RGB bands to set on the pixelBlock
			const rBand = new Uint8Array(p1.length);
			const gBand = new Uint8Array(p1.length);
			const bBand = new Uint8Array(p1.length);
  
			if (mask == null) {
			  mask = new Uint8Array(p1.length); //mask = new Uint8Array(p1.length);
			  mask.fill(1);
			  pixelBlock.mask = mask;
			}
  
			// Loop through all the pixels in the view
			for (let i = 0; i < numPixels; i++) {
			  // skip noData pixels
			  mask[i] =
				p1[i] >= Math.floor(currentMin) && p1[i] <= Math.floor(currentMax)
				  ? 0
				  : 1;
  
			  //apply color based on temperature value of each pixel
			  if (mask[i]) {
				// p[i] = Math.floor((p1[i] - minVal) * factor);
				rBand[i] = pixelData.pixelBlock.pixels[0][i];//red; //pixels[i][0];
				gBand[i] = pixelData.pixelBlock.pixels[1][i];
				bBand[i] = pixelData.pixelBlock.pixels[2][i];
			  }else{
				gBand[i] = 255;
			  }
			}
  
			// Set the new pixel values on the pixelBlock
			pixelData.pixelBlock.pixels = [rBand, gBand, bBand]; //assign rgb values to each pixel
			pixelData.pixelBlock.statistics = null;
			pixelData.pixelBlock.pixelType = "f32";
		  }
		  */

		FINAL_RASTER = new ImageryLayer({
			title: `${userTitle || 'AHP Result'} ${now.toISOString()}`,
			//url: AHP_MOSAIC_URL
			url: AHP_SERVICE_URL
			// apply the most recent raster function to the chain
			//, renderingRule: maskFunction
			//, renderingRule:rasterFunction
			//, renderingRule: maskFunction

			,renderingRule:colorFunction 

			, renderer: {
				type: 'unique-value',
				field:'Raster.ServicePixelValue',
				legendOptions: {
					title: "Importance"
				},
				defaultLabel: 'Unknown Value',
				defaultSymbol: {
					type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
					size: 1,
					color: "black",
					outline: {  // autocasts as new SimpleLineSymbol()
					  width: 1,
					  color: "white"
					}
				},
				uniqueValueInfos: [{
					value:1,
					label: 'Low',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [249, 210, 197, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [249, 210, 197, 1]
						}
					}
				},{
					value:2,
					label: 'Medium',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [230, 130, 97, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [230, 130, 97, 1]
						}
					}
				},{
					value:3,
					label: 'High',
					symbol: {
						type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
						size: 1,
						color: [173, 60, 22, 1],
						outline: {  // autocasts as new SimpleLineSymbol()
							width: 1,
							color: [173, 60, 22, 1]
						}
					}
				}]
			}
			//, renderingRule: colorMapper
			, format: 'lerc'
			//, interpolation: 'nearest'
			, noDataInterpretation: 'any'
			, pixelType: 'u8'
			//, noData:[-1,0]
			//,noDataInterpretation:'all'
			//mosaicRule: mosaicRule
			//, pixelType: 'u8'
			, noData: 0
			//, noData: [-1,0,15,20,255, -3.9e39, 3.4e39,-2147483648,2147483647, 3.40282e+38, -3.40282e-38 ]
			//mosaicRule: mosaicRule
			
			//, pixelFilter: pixelFilterCorrection
		});

		FINAL_RASTER.on('layerview-create', ({view, layerView}) => {
			console.log('layerview-create: ', {view, layerView})
			attachRasterEvents(FINAL_RASTER, layerView);
		});
		
		FINAL_RASTER.when(() => {
			//Create a template of literally all the fields because screw it. :3
/*			
			FINAL_RASTER.popupTemplate ={
				title: `${userTitle || 'AHP Result'} ${now.toISOString()}`,
				content: "Pixel Value: {Raster.ServicePixelValue}"
			  }
			FINAL_RASTER.popupTemplate.title=`${userTitle || 'AHP Result'} ${now.toISOString()}`;
			FINAL_RASTER.popupTemplate.fieldInfos = FINAL_RASTER.popupTemplate.fieldInfos.filter( (fieldItem) => {
				return (
					fieldItem.fieldName === 'CenterX'
					|| fieldItem.fieldName === 'CenterY'
					|| fieldItem.fieldName === 'Raster.ServicePixelValue'
					|| fieldItem.fieldName === 'Raster.ServicePixelValue.Raw'
				);
			});
			//FINAL_RASTER.redraw();
*/
			
			FINAL_RASTER.popupTemplate =FINAL_RASTER.createPopupTemplate();

			FINAL_RASTER.popupTemplate.title=`${userTitle || 'AHP Result'} ${now.toISOString()}`;
			FINAL_RASTER.popupTemplate.fieldInfos = FINAL_RASTER.popupTemplate.fieldInfos.filter( (fieldItem) => {
				return (
					fieldItem.fieldName === 'CenterX'
					|| fieldItem.fieldName === 'CenterY'
					|| fieldItem.fieldName === 'Raster.ServicePixelValue'
					|| fieldItem.fieldName === 'Raster.ServicePixelValue.Raw'
				);
			});
			FINAL_RASTER.ora.export = generateMaskRule(rasterFunction);
			console.log('Final raster: ', FINAL_RASTER);
		});
		
		console.log('Arcmap: ', arcgisMap);

		addAHPLayerToMapLayerModule(`${LAYER_ID_HEADER}-${now.getTime()}`, FINAL_RASTER, 
			JSON.stringify(
				FINAL_RASTER.exportImageServiceParameters.renderingRule.toJSON()
			) 
		);
		addLayerToMap(FINAL_RASTER);



		onCreateAlert('blue','Raster Request Generated',`Your raster is generating and should appear on the map soon.`);
		CloseModal();

		return true;
		/*
		GenerateRasterCalculation(baseRasterServiceURL,rasterArray)
		.then( (myRenderingRule ) => {
			console.log('My Rendering Rule: ', myRenderingRule);
			const now = new Date();
			const FINAL_RASTER = new ImageryLayer({
				title: `${userTitle || 'AHP Result'} ${now.toISOString()}`,
				url: baseRasterServiceURL,
				// apply the most recent raster function to the chain
				renderingRule:myRenderingRule 
				//renderingRule: colorMapper
				, interpolation: 'nearest'
				, pixelType: 'unknown'
				//mosaicRule: mosaicRule
				//, pixelType: 'u8'
				//, noData: 255
				//mosaicRule: mosaicRule
			});
		
			console.log('FINAL RASTER?????', FINAL_RASTER);

			addAHPLayerToMapLayerModule(`${LAYER_ID_HEADER}-${now.getTime()}`, FINAL_RASTER, JSON.stringify(myRenderingRule.toJSON()) );
			addLayerToMap(FINAL_RASTER);

			onCreateAlert('blue','Raster generated',`(GenerateRasterCalculation) Should appear on map soon.`);
			CloseModal();
		})
		.catch( (failure) => {
			onCreateAlert('red','Raster Generation Failed.',`The raster generation failed because it failed. I'm sure there's a reason but I didn't output it yet.`);
		});



		return false;
		*/
	}

			
			/*
			const colorMapper = new RasterFunction({
				functionName: "Colormap",
				functionArguments: {
					//"ColorrampName": "Partial Spectrum",
						"colorramp": {
							"type": "multipart",
							"colorRamps": [
							{
								start:0,
								stop: 3,
								"type": "algorithmic",
								"fromColor": [
								255,
								0,
								0,
								255
								],
								"toColor": [
								0,
								255,
								0,
								255
								],
								"algorithm": "esriHSVAlgorithm"
							},
							{
								start:3,
								stop: 60,
								"type": "algorithmic",
								"fromColor": [
								155,
								34,
								78,
								255
								],
								"toColor": [
								255,
								0,
								0,
								255
								],
								"algorithm": "esriCIELabAlgorithm"
							}
							]
						},
					"Raster": myRenderingRule
				},
				outputPixelType:"unknown",
				variableName: "Raster"
			});
*/

	if( !ratingsMatrix || ratingsMatrix.matrix.length === 0 ) {
		return null;
	}
	return (<>
		
		<CalcitePanel
				key={'doing-calcs'}
				heading={'Your Rating Matrix'}
				summary={'Here is the output of your data'}
				onCalcitePanelBackClick={RemoveServicePanel}
				loading={ratingsMatrix===null || undefined}
			>
			<div className="content-area">
				
				<div>
					<p>CV<sup>1</sup>: Consistancy Vector</p>
				</div>

				<table className="table">
					<thead>
						<tr>
							<th>&nbsp;</th>
							{ratingsMatrix.matrix.map( (ratingItem) => {
								return <th>{ratingItem.primaryLabel}</th>;
							})}
							<th>Row Avg</th>
							<th>CV<sup>1</sup></th>
						</tr>
					</thead>
					<tbody>
						{ratingsMatrix.matrix.map( (ratingItem) => {
							return (
								<tr>
									<th>{ratingItem.primaryLabel}</th>
									{ratingItem.values.map( (valueItem) => {
										return <td>{valueItem.value.toFixed(2)} ({valueItem.normalizedValue.toFixed(3)})</td>;
									})}
									<th>{ratingItem.rowAverage.toFixed(3)}</th>
									<th>{ratingItem.consistancyValue.toFixed(2)}</th>
								</tr>
							);
						})}
						<tr>
							<th>Total(s): </th>
							{ratingsMatrix.columnSums.map( (columnSumItem) => {

								
								return <th>{columnSumItem.colSum.toFixed(3)}</th>
							} ) }
							<th>{ratingsMatrix.matrix.reduce( (sumRowAvg, currItem) => {
								return sumRowAvg += currItem.rowAverage;
							}, 0).toFixed(3)}</th>
						</tr>
					</tbody>
				</table>
				<div>
					<h4>Lambda: {ratingsMatrix.lambdaValue.toFixed(3)}</h4>
					<h4>Consistancy Index: {ratingsMatrix.consistancyIndex.toFixed(3)}</h4>
					<h4>Consistancy Ratio: {ratingsMatrix.consistancyRatio.toFixed(3)}</h4>
				</div>
				<hr />
				<div>
					<CalciteLabel>
						<strong>Result Name</strong>
						<CalciteInput prefixText="(Optional)" placeholder="AHP Results" onCalciteInputInput={(e)=>{
							///console.log('Input ', e);
							SetUserTitle(e.detail.value);
						}}></CalciteInput>
					</CalciteLabel>
				</div>
				<div>
					{/* ratingsMatrix.consistancyRatio >= 0.1 ||  */}
					<div style={{marginTop:'1em'}}>
						<CalciteButton width="full" disabled={undefined} onClick={AddCalculatedRasterToMap}>Load Raster With These Weights</CalciteButton>
					</div>{
						ratingsMatrix.consistancyRatio >= 0.1
						? <p>Note: The Consistancy Ratio is not less than 0.1.</p>
						: null
					}
					
				</div>
			</div>
		</CalcitePanel>

	</>);
};
/*

				<ul>
					{ratingsList.map( (varInfo) => {
						return <li>{varInfo.firstItem.key} - {varInfo.secondItem.key}: {varInfo.value}</li>
					})}
				</ul>
*/



const mapStateToProps = (state) => ({
	arcgisMap: state.arcgisMap,
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({

	//onAddLayerClick: (layer) => {dispatch(loadLayer(layer))},

	addAHPLayerToMapLayerModule: (layerKey, layerItem, matrix) =>{dispatch(layerListAddAHPLayer(layerKey, layerItem, matrix))},
	addLayerToMap: (layer) => {dispatch(loadLayer(layer))},

	onCreateAlert: (color, title, message) => {dispatch(createAlert(color, title, message))},
	attachRasterEvents: (layer, layerView) => {dispatch(attachRasterEvents(layer, layerView))}
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPCalculator);