import React, { useRef, 
	useEffect,
	useState 
} from "react";
import { connect } from "react-redux";


//import LayerList from "@arcgis/core/widgets/LayerList";
//import { layerCompilation } from "./Layers/allLayersJson";
import { CalciteFlow, CalcitePanel, 
	CalciteButton,
	CalciteAction,
	CalcitePickList,
	//CalcitePickListGroup,
	CalcitePickListItem,
	//CalcitePopoverManager,
	//CalcitePopover,
	//CalcitePopoverManager,
	//CalcitePopover
	CalciteRadioGroup, CalciteRadioGroupItem
} from "@esri/calcite-components-react";
import {loadLayer, removeLayer} from '../../../../Flux/thunks';
import { updateTip } from "../../../../Flux/actions";

const MapLayersPanel = ({availableLayers, onUpdateTip, loadLayerOnMap, removeLayerFromMap, isMapLoading, arcgisMap}) => {
	//console.log('MAP LAYERS: ', layerCompilation, availableLayers);
	const mapLayersDiv = useRef(null);
	
	const [panels, UpdatePanels] = useState([]);

	useEffect(() => {
		
	}, [panels]);
	

	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
		
	  	if (mapLayersDiv.current && arcgisMap.map) {
			  
		}
	}, [arcgisMap]);
	
	
	const ClearAllPanels = () => {
		console.log('[MapLayersPanel] Clearing all panels')
		UpdatePanels([]);
	}

/*
Layer item: 

*/
	const AddLayerToMap = (layerItem, _event) => {
		//console.log('[MapLayersFlowPanel] DUMB: Adding layer to map.', layerItem);
		if( layerItem.containsSublayers ) {
			//If this is the main item, then pass layerItem.item
			if( layerItem.containsSublayers.main 
				&& layerItem.containsSublayers.main.key
				&& layerItem.containsSublayers.main.key === layerItem.containsSublayers.useSublayer ) {
				loadLayerOnMap(layerItem.item);
			}else{
				//find the sublayer
				layerItem.containsSublayers.sublayerItems.forEach( (sublayerLayerItem) => {
					if( 
						sublayerLayerItem.key
						&& sublayerLayerItem.key === layerItem.containsSublayers.useSublayer
					){
						loadLayerOnMap(sublayerLayerItem.item);
					}
				})
			}
		}else{
			loadLayerOnMap(layerItem.item);
		}
		//loadLayerOnMap
	};

	const RemoveServicePanel = (event) => {
		//console.log('Removing Panel: ', event);
		event.stopPropagation();
		UpdatePanels( panels.slice(0,-1) );
	}
	const GoToMenu = (_e) => {
		//console.log('EVENT TEST BUTTON', _e);
		UpdatePanels( [...panels, 
			{key: _e.target.value, title: _e.target.title, items: _e.target.items}] 
			
			);
	}


	const CreateMenuSystem = (currentSelection, title="", mainKey=null) => {
		//console.log('Creating Menu System with the following items: ', currentSelection);
		if( currentSelection && currentSelection.filter( (selectionItem) => {
			return (
				selectionItem.id === 'custom' && selectionItem.items.length < 1
				? false
				: true
			)
		} ).length < 1 ) {
			return (
				<CalcitePanel
				key={mainKey || 'head'}
					heading={title === '' ? 'Main Menu' : title}
					onCalcitePanelBackClick={(e)=>{RemoveServicePanel(e);}}
				>
					<h4>There are no available map layers at this time.</h4>
				</CalcitePanel>
			);	
		}
		return (
			<CalcitePanel
			key={mainKey || 'head'}
				heading={title === '' ? 'Main Menu' : title}
				onCalcitePanelBackClick={(e)=>{RemoveServicePanel(e);}}
			>
				<CalcitePickList>
				{currentSelection.filter( (selectionItem)=>{
					return selectionItem.hideInMenu !== true;
				}).map( (selectionItem, key) => {
					if( selectionItem.type === 'group' ) {

						//SPECIAL CONDITION, 'custom' and no items
						if( selectionItem.id === 'custom' && selectionItem.items.length < 1 ) {
							return null;
						}


						return (

						<CalcitePickListItem key={key} 
							heading={selectionItem.title} 
							description={selectionItem.title} 
							onCalciteListItemChange={ (_e) => { 
								if( _e.detail && _e.detail.selected) {
									//SearchServer(_e.detail.value);
									GoToMenu(_e);
									_e.target.selected=false;
								}
							}}
							title={selectionItem.title}
							items={selectionItem.items}
							>
							<CalciteAction slot="actions-end" icon="chevrons-right" 
								title={selectionItem.title}
								items={selectionItem.items}
								onClick={ (_e) => { GoToMenu(_e) }}></CalciteAction>
						</CalcitePickListItem>


						);
					}else if( selectionItem.type === 'layer') {
						const appendHelper = {};
						if( !selectionItem.meta ) {
							appendHelper.disabled=true;
						}

						console.log('layer type', selectionItem);
						return (
							<>
							<CalcitePickListItem key={key} 
							heading={selectionItem.item.title} 
							description={selectionItem.item.title} 
							title={selectionItem.item.title} 

							value={selectionItem.item} 
							onCalciteListItemChange={ (_e) => { 
								if( _e.detail && _e.detail.selected) {
									//SearchServer(_e.detail.value);
									//AddLayerToMap()
								}
							}}
							>{
								selectionItem.downloadLink 
								? 
								<CalciteAction 
								slot="actions-end"
								icon="download" 
								title="Download"
								onClick={ (_e) => { 
									console.log('Downloading Data...', selectionItem, selectionItem.downloadLink ); 
									const myItems = selectionItem.item.exportImageServiceParameters.toJSON();
				
									myItems.format = 'tiff';
									myItems.f = 'image';
									myItems.noData = '-1,0';
				
									const myUrlParams=Object.keys(myItems).map( (myItemKey) => {
										console.log(myItems[myItemKey]);
										if(typeof myItems[myItemKey]  === 'object') {
											return `${myItemKey}=${encodeURIComponent(JSON.stringify(myItems[myItemKey]))}`;
										}else if( Array.isArray(myItems[myItemKey])) {
											return `${myItemKey}=${encodeURIComponent(myItems[myItemKey].join(','))}`;
										}
										return `${myItemKey}=${myItems[myItemKey]}`;
									});
				
									myUrlParams.push(`size=${encodeURIComponent('4096,4096')}`);
									myUrlParams.push(`bbox=${selectionItem.item.fullExtent.xmin},${selectionItem.item.fullExtent.ymin},${selectionItem.item.fullExtent.xmax},${selectionItem.item.fullExtent.ymax}`);
									myUrlParams.push(`bboxSR=${selectionItem.item.fullExtent.spatialReference.wkid}`);
				
									window.open(
										`${selectionItem.downloadLink}/exportImage?${myUrlParams.join('&')}`
									);
				
								}}></CalciteAction>
								: null
				
							}
							<CalciteAction 
								slot="actions-end"
								icon="information" 
								title="Metadata"
								{...appendHelper}
								onClick={ (_e) => { console.log('Activating Metadata...', _e); 
									if(selectionItem.meta ) {
										const {layerTitle, layerDescription, mapService, authoritiveSource} = selectionItem.meta;
										onUpdateTip(layerTitle, layerDescription, mapService, authoritiveSource);
									}
								}}></CalciteAction>
								{
									selectionItem.containsSublayers ? 
									<CalciteAction slot="actions-end" icon="sliders" onClick={ (_e) => { 
										 document.getElementById(`${selectionItem.layerId}-sublayerOptions`)?.classList.toggle('hide-options')
									}}></CalciteAction>
									: null
								}
								
							<CalciteAction slot="actions-end" icon={
								selectionItem.type === 'tiles' ? 'tile-layer' : 'add-layer'
							 } onClick={ (_e) => { 
								 console.log(selectionItem);
								//SearchServer(layers.url); 
								AddLayerToMap(selectionItem);
							}}></CalciteAction>
							
							</CalcitePickListItem>
							{
								selectionItem.containsSublayers ? 
								
								<div id={`${selectionItem.layerId}-sublayerOptions`} 
									className="content-area layer-options hide-options">
										
									<CalciteRadioGroup
										onCalciteRadioGroupChange={(e)=>{
											console.log('[MapLayersPanel] Switched Sublayer Option: ', e);
											if( e.detail ) {
												selectionItem.containsSublayers.useSublayer = e.detail;
											}
										}}
										width="full" layout="vertical">
											{
											/* */
											selectionItem.containsSublayers.main ? 
											<CalciteRadioGroupItem value={selectionItem.containsSublayers.main.key} 
											checked={true}
											>{selectionItem.containsSublayers.main.optionTitle}</CalciteRadioGroupItem>
											: null
											}
											{
												selectionItem.containsSublayers.sublayerItems ?
												selectionItem.containsSublayers.sublayerItems
												.map( (sublayerItem) => {
													return <CalciteRadioGroupItem value={sublayerItem.key}>{sublayerItem.optionTitle}</CalciteRadioGroupItem>;
												})
												: null
											}
										</CalciteRadioGroup>
									</div>
								:null

							}
							</>
						);
					}
					return null;

				})}
				</CalcitePickList>
			</CalcitePanel>
		);

	}

	return (
		<>
			<div className="content-area">
			<CalciteButton
				width={"full"}
				onClick={(_e) => { 
					ClearAllPanels();
				}}
				iconStart="reset"
			>
				Go to Main Menu
			</CalciteButton>
			</div>
			<CalciteFlow>
				{CreateMenuSystem(availableLayers.filter( (primaryLayerContent) => {
					return !primaryLayerContent.ignoreInMapLayersPanel
				} ) )}
				{panels.map( (panelItem, key)=> {
					return CreateMenuSystem(panelItem.items.filter( (primaryLayerContent) => {
						return !primaryLayerContent.ignoreInMapLayersPanel
					} ), panelItem.title, key);
				})}
			</CalciteFlow>
		</>
	);
}

const mapStateToProps = (state) => ({
    availableLayers: state.availableLayers,
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onUpdateTip: (layerTitle, layerDescription, mapService, authoritiveSource) => {dispatch(updateTip(layerTitle, layerDescription, mapService, authoritiveSource))},
	loadLayerOnMap: (layer) => {dispatch(loadLayer(layer))},
	removeLayerFromMap: (layer) => {dispatch(removeLayer(layer))}
});

export default connect(mapStateToProps, mapDispatchToProps)(MapLayersPanel);
