import FeatureLayer from "@arcgis/core/layers/FeatureLayer";


const habitatWildlifeLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Connecting_Habitat/MapServer/0"
	, title: 'NJDEP Terrestrial Wildlife Habitat Stepping Stones'
	, outFields: ['*']
	, popupEnabled: true
});
const habitatChanjLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Connecting_Habitat/MapServer/1"
	, title: 'The CHANJ feature'
	, outFields: ['*']
	, popupEnabled: true
});
const roadThreatLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Connecting_Habitat/MapServer/2"
	, title: 'Road Threat'
	, outFields: ['*']
	, popupEnabled: true
});
const cummulativeMovementCostLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Connecting_Habitat/MapServer/3"
	, title: 'Cummulative Movement Cost'
	, outFields: ['*']
	, popupEnabled: true
});

habitatWildlifeLayer.when(() => {
	//Create a template of literally all the fields
	habitatWildlifeLayer.popupTemplate = habitatWildlifeLayer.createPopupTemplate();
});

habitatChanjLayer.when(() => {
	//Create a template of literally all the fields
	habitatChanjLayer.popupTemplate = habitatChanjLayer.createPopupTemplate();
});

roadThreatLayer.when(() => {
	//Create a template of literally all the fields
	roadThreatLayer.popupTemplate = roadThreatLayer.createPopupTemplate();
});

cummulativeMovementCostLayer.when(() => {
	//Create a template of literally all the fields
	cummulativeMovementCostLayer.popupTemplate = cummulativeMovementCostLayer.createPopupTemplate();
});



export const hlConnectingHabitats = {
	id: 'aa-hl-ch',
	title:"Connecting Habitats",
	type:'group',
	description:`Connecting Habitat Across New Jersey (CHANJ) administered by the Endangered and Nongame Species Program within the DEP’s Division of Fish and Wildlife. It identifies intact habitats, called “Cores” and the opportunities to link them, called “Corridors.” The tab also includes road segments that cut through habitats and are likely to pose problems for animal movement. The data was collected form NJDEP GIS Environmental Open Data via an online CHANJ Web Viewer.`,
	items: [
		{layerId:'aa-hl-ch-wildlife', type: 'layer', item: habitatWildlifeLayer, meta:null},
		{layerId:'aa-hl-ch-chanj', type: 'layer', item: habitatChanjLayer, meta:null},
		{layerId:'aa-hl-ch-roadt-hreat', type: 'layer', item: roadThreatLayer, meta:null},
		{layerId:'aa-hl-ch-move-cost', type: 'layer', item: cummulativeMovementCostLayer, meta:null},
	]
}


/*
{
									layerKey: 'habitat-wildlife',
									server: 'terrene',
									title: 'NJDEP Terrestrial Wildlife Habitat Stepping Stones',
									folder: 'SAFEA',
									mapServer: 'Connecting_Habitat',
									layerId: 0,
									parentDiv: 'habitat',
									defExp: '1=1',
									isFeatureLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'habitat-chanj',
									server: 'terrene',
									title: 'The CHANJ feature',
									folder: 'SAFEA',
									mapServer: 'Connecting_Habitat',
									layerId: 1,
									parentDiv: 'habitat',
									defExp: '1=1',
									isFeatureLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'habitat-roadThreat',
									server: 'terrene',
									title: 'Road Threat',
									folder: 'SAFEA',
									mapServer: 'Connecting_Habitat',
									layerId: 2,
									parentDiv: 'habitat',
									defExp: '1=1',
									isFeatureLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'habitat-movement',
									server: 'terrene',
									title: 'Cumulative Movement Cost',
									folder: 'SAFEA',
									mapServer: 'Connecting_Habitat',
									layerId: 3,
									parentDiv: 'habitat',
									defExp: '1=1',
									isFeatureLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}
*/