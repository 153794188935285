//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

import { lulcImpDesc, lulcNlcd, lulcUrbanImp } from "./lulcGroupCompilation";

const thisId = 'aa-hl-lulc';
export const hlLulc = {
	id: thisId,
	title:"Land Use / Land Cover ",
	type:'group',
	description:`The National Land Cover Database (NLCD) data provides data on land cover and land cover change at a 30m resolution with a land use classification based on a modified Anderson Level II classification system.`,
	items: [lulcImpDesc, lulcNlcd, lulcUrbanImp]
}