import { alcp } from "./alcp";
import { agriPrio } from "./agriprio";
import { amenity } from './amenity';
import { cpa } from "./cpa";
import { factors } from "./factors";
import { ifp } from "./ifp";








const scenarioAssessHighlands = [
	alcp
	, agriPrio 
	, amenity
	, cpa
	, factors
	, ifp
];
export default scenarioAssessHighlands;