
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import { trans } from "./trans";

const infraLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/`
	, sublayers: [{id:2}]
	, title: 'Infrastructure Focused Priority'
});
export const infra = 
{
	id:'sa-pl-infra',
	title:"Infrastructure Focused Priority",
	type:'group',
	showScenario:'generalInfraPriority',
	items:[
		{
			id:'sa-pl-infra',
			title:"Infrastructure Focused Priority (Scenario 1)",
			type:'group',
			showScenario:'pinelandsInfra',
			items:[

				{layerId:'sa-pl-infra-main', type: 'layer', item: infraLayer, meta:null},





				{
					id: 'sa-pl-infra-df',
					type: 'group-set',
					//type: 'group',
					title: 'Driving Factors',
					items: [ {
						layerKey: 'pineInfr-lulc',
						server: 'terrene',
						title: 'Land Use / Land Cover 2015',
						folder: 'SEFEA_Pinelands',
						mapServer: 'Weighted_Overlay',
						layerId: 8,
						parentDiv: 'pineInfrDf',
						defExp: '1=1',
						isRasterLayer: true,
						hasFeatureServer: false,
						detailsText: ''
					}, {
						layerKey: 'pineInfr-train',
						server: 'terrene',
						title: 'Distance to Train Stations',
						folder: 'SEFEA_Pinelands',
						mapServer: 'Weighted_Overlay',
						layerId: 9,
						parentDiv: 'pineInfrDf',
						defExp: '1=1',
						isRasterLayer: true,
						hasFeatureServer: false,
						detailsText: ''
					}, {
						layerKey: 'pineInfr-roads',
						server: 'terrene',
						title: 'Distance to Major Roads',
						folder: 'SEFEA_Pinelands',
						mapServer: 'Weighted_Overlay',
						layerId: 10,
						parentDiv: 'pineInfrDf',
						defExp: '1=1',
						isRasterLayer: true,
						hasFeatureServer: false,
						detailsText: ''
					}, {
						layerKey: 'pineInfr-res',
						server: 'terrene',
						title: 'Distance to High Density Residential Areas',
						folder: 'SEFEA_Pinelands',
						mapServer: 'Weighted_Overlay',
						layerId: 11,
						parentDiv: 'pineInfrDf',
						defExp: '1=1',
						isRasterLayer: true,
						hasFeatureServer: false,
						detailsText: ''
					}, {
						layerKey: 'pineInfr-sewer',
						server: 'terrene',
						title: 'Sewer Service Areas',
						folder: 'SEFEA_Pinelands',
						mapServer: 'Weighted_Overlay',
						layerId: 12,
						parentDiv: 'pineInfrDf',
						defExp: '1=1',
						isRasterLayer: true,
						hasFeatureServer: false,
						detailsText: ''
					}].map( (floodmapperObj) => {
						return {
							layerId: `sa-pl-infra-df-${floodmapperObj.layerKey}`,
							type: 'layer',
							item: new MapImageLayer({
								url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
								, sublayers: [{id:floodmapperObj.layerId}]
								, title: floodmapperObj.title
							}),
							meta:null}
					})
				}
			]
		},
		
		trans
] 
};