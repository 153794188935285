import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const wsEcoHuc14 = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Watershed_Ecological_Health/MapServer/0"
	, title: 'Watershed HUC14'
	, outFields: ['*']
	, popupEnabled: true
});
wsEcoHuc14.when(() => {
	//Create a template of literally all the fields
	wsEcoHuc14.popupTemplate = wsEcoHuc14.createPopupTemplate();
});

const wsNewGround = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Watershed_Ecological_Health/MapServer/1"
	, title: 'Net Ground Water Availability for Human Use by Subwatershed'
	, outFields: ['*']
	, popupEnabled: true
});
wsNewGround.when(() => {
	//Create a template of literally all the fields
	wsNewGround.popupTemplate = wsNewGround.createPopupTemplate();
});


const wsFis = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Watershed_Ecological_Health/MapServer/"
	, sublayers: [{id:2}]
	, title: 'Forest Integrity Score'
});
const wsRis = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Watershed_Ecological_Health/MapServer/"
	, sublayers: [{id:3}]
	, title: 'Riparian Integrity Score'
});
const wsWv = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Watershed_Ecological_Health/MapServer/"
	, sublayers: [{id:4}]
	, title: 'Water Values'
});


export const wsHighlandEcoHealth = {
	id: 'ws-hl',
	title:"Highlands Region",
	type:'group',
	
	items: [
		{layerId:'ws-hl-hic-14', type: 'layer', item: wsEcoHuc14, meta:null},
		{layerId:'ws-hl-new-ground', type: 'layer', item: wsNewGround, meta:null},
		{layerId:'ws-hl-fis', type: 'layer', item: wsFis, meta:null},
		{layerId:'ws-hl-ris', type: 'layer', item: wsRis, meta:null},
		{layerId:'ws-hl-wv', type: 'layer', item: wsWv, meta:null}
	]
}

