
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const amenityLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Scenario_Assess_LUChg/MapServer/`
	, sublayers: [{id:3}]
	, title: 'Amenity Focused Priority'
});

export const amenity = 
{
	id:'sa-hl-amenity',
	title:"Amenity Focused Priority",
	type:'group',
	showScenario:'highlandsAmenity',
	items:[
	
	{layerId:'sa-hl-amenity-main', type: 'layer', item: amenityLayer, meta:null},
	
	{
		id: 'sa-hl-amenity-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [{
			layerKey: 'saluc-afp-os',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Open Space ',
			folder: 'SAFEA_Highlands',
			mapServer: 'Amenity_Focused_Priority',
			layerId: 3,
			opacityBar: null,
			parentDiv: 'afpDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-afp-sa',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Scenic Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Amenity_Focused_Priority',
			layerId: 4,
			opacityBar: null,
			parentDiv: 'afpDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-afp-lulc15',
			layerItem: null,
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SAFEA_Highlands',
			mapServer: 'Amenity_Focused_Priority',
			layerId: 5,
			opacityBar: null,
			parentDiv: 'afpDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-afp-mr',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SAFEA_Highlands',
			mapServer: 'Amenity_Focused_Priority',
			layerId: 6,
			opacityBar: null,
			parentDiv: 'afpDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-afp-ts',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SAFEA_Highlands',
			mapServer: 'Amenity_Focused_Priority',
			layerId: 7,
			opacityBar: null,
			parentDiv: 'afpDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-hl-amenity-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};