import React, { 
	useRef, 
	useEffect,
	//useState
} from "react";
//import ReactDOM from 'react-dom';
import { connect } from "react-redux";

//import * as watchUtils from "@arcgis/core/core/watchUtils";


//import LayerList from "@arcgis/core/widgets/LayerList";
//import ListItem from "@arcgis/core/widgets/LayerList/ListItem";

//import { CalciteTree, CalciteTreeItem } from "@esri/calcite-components-react";
import { 
	//loadLayer, 
	removeLayer
	, removeAllLayers
} from '../../../../Flux/thunks';
//import { getLayerList } from "../../../../Flux/reducers";
import { 
	//CalciteValueList, CalciteValueListItem, 
	CalciteSlider,
	CalciteBlock, //CalciteNotice, 
	CalciteSortableList ,
	CalciteButton,
	//CalciteSwitch,
	CalciteLabel,
	CalciteRadioGroup,
	CalciteRadioGroupItem,
	//CalcitePanel
} from "@esri/calcite-components-react";


//import { CalciteSlider } from "@esri/calcite-components-react";



//https://github.com/Esri/calcite-components/blob/master/src/demos/calcite-sortable-list.html

const LayerControlPanel = ({layerList, isMapLoading, removeLayerFromMap, removeAllLayersFromMap, arcgisMap}) => {

	const layerControl = useRef(null);	
	//const [initDraggable, SetDraggable] = useState(true);

	const CalciteSliderChange = (event, layerItem) => {
		//console.log('Layer Control Panel][CalciteSliderChange]', event, itemRef);
		if( event.target.value === 0 ) {
			layerItem.opacity = 0;
			return;
		}
		layerItem.opacity = (event.target.value ? (event.target.value/100) : 1 );
		return;
	}
	const FullReordering = (myNodeList) => {
		//console.log('Apparently reversing a node list is hard', myNodeList);
		for (let index = myNodeList.length -1; index >= 0; index--) {
			const layerId = myNodeList[index].layerId;
			const findLayer = layerList.find( (layerItem) => {
				return layerItem.id === layerId;
			});
			//console.log('Found layer...', findLayer);
			//console.log(`'Setting ${findLayer.title} to Index: ${myNodeList.length - index} `, arcgisMap.map);
			if( findLayer) {
				arcgisMap.map.reorder(findLayer, myNodeList.length -index );
			}
		}
		/*
		myNodeList.forEach( (nodeItem, index) => {
			console.log(nodeItem);
			const layerId = nodeItem.layerId;

			const findLayer = layerList.find( (layerItem) => {
				return layerItem.id === layerId;
			});
			console.log('Found layer...', findLayer);
			console.log(`'Setting ${findLayer.title} to Index: ${myNodeList.length - index} `, arcgisMap.map);
			if( findLayer) {
				arcgisMap.map.reorder(findLayer, myNodeList.length - index );
			}
		});
		  */
	}
	const SortableListOrderChangeEvent = (event) => {
		FullReordering(event.target.childNodes);
	}
	/*
	const OnDragEnd = (event) => {
		console.log('[LayerControlPanel] OnDragEnd', event );

		console.log(layerList);
		//FullReordering(event.target.childNodes)

	}*/
	const ToggleLayer = (layerItem, isVisible="on") => {
		console.log(`[ToggleLayer] The layer "${layerItem.title}" `, isVisible);
		layerItem.visible = (isVisible==='on');
	}
	

	useEffect(() => {
		
	  	if (layerControl.current && !layerControl.current.children.length && arcgisMap.map) {

		}
		//SetDraggable(false);
	}, [arcgisMap]);

	const reversedLayerList = layerList.map( (item=>item)).reverse();

	//draggable={initDraggable || null}
	return (
		<>
		
		<div className="content-area">
			<div>
			<CalciteButton 
				style={{marginBottom:'1em'}}
				color="red"
				width={"full"}
				onClick={(_e) => { 
					_e.preventDefault();
					removeAllLayersFromMap()
				}}
			>Remove All Layers From Map</CalciteButton>
			</div>
			{layerList.length > 0 ? (
			<CalciteSortableList
			//Leaving these for now since I need to figure out mobile-list dragging. Maybe a future calcite update will fix it as these are attempts at workarounds.
			/*
			onPointerDown={(e) => { 
				console.log('Pointer down;', e);
			}}
			onPointerUp={(e) => { 
				console.log('Pointer up', e);
			}}
			onDragStart={(e) => { 
				console.log('drag start;', e);
			}}
			onDragEnter={(e) => { 
				console.log('drag enter;', e);
			}}
			
			onDragOver={(e) => { 
				//console.log('drag over;', e);
			}}
			onTouchStart={(e) => { 
				console.log('touch start;', e);
				const onDragStart = new Event('dragstart')
				e.target.dispatchEvent(onDragStart);
			}}
			onTouchEnd={(e) => { 
				console.log('touch end;', e);
				const onDragEnd = new Event('dragend')
				e.target.dispatchEvent(onDragEnd);
			}}
			onTouchMove={(e)=>{
				const onDragOver = new Event('dragover')
				console.log(e, onDragOver);
			}}
			*/

			onCalciteListOrderChange={ (e) => { 
				SortableListOrderChangeEvent(e);
				//console.log('The order changed in this list: ', e);
				
			}}
				title={'Layer Control List'}
			>
						{reversedLayerList.map( (layerItem, key) => {
							console.log(`The layer "${layerItem.title}" `, layerItem
							//	, layerItem.ora.diseaseSeasonRange
							);

							// since passing null or false to a radio group will actually remove the button, which is dumb, we'll conditional props here.
							const visibleOn ={};
							const visibleOff ={};
							//console.log('Is this item visible? ', layerItem.visible, layerItem);
							if( layerItem.visible ) {
								visibleOn.checked=true;
							}else{
								visibleOff.checked=true;
							}
							//console.log('Is this item visible? ', visibleOn, visibleOff, layerItem.visible, layerItem);

							return (<CalciteBlock  
								key={key}
								onDragEnd={ (e) => { 

									//OnDragEnd(e);
								}} 
								collapsible 
								heading={layerItem.title} 
								summary={layerItem.type}
								layerId={layerItem.id}
								dragHandle={true}
								/*
								//Leaving these for now since I need to figure out mobile-list dragging. Maybe a future calcite update will fix it as these are attempts at workarounds.
								onTouchStart={(e)=>{console.log('touch start', e)}}
								onTouchStartCapture={(e)=>{console.log('touch start capture', e)}}
								onTouchMove={(e)=>{console.log('touch move', e)}}
								onTouchMoveCapture={(e)=>{console.log('touch move capture', e)}}
								onLoad={(e)=>{console.log('on load', e)}}
									<CalciteButton 
										onClick={(_e)=>{
											console.lo
											ToggleLayer(layerItem, true);
										}}
										appearance={layerItem.visible ? 'solid' : 'outline'}
										color="blue"
									>ON</CalciteButton>
									<CalciteButton 
										onClick={(_e)=>{ToggleLayer(layerItem, false);}}
										appearance={layerItem.visible ? 'outline' : 'solid'}
										color="red"
									>OFF</CalciteButton>			
								*/
								>

								<CalciteLabel layout="inline">
									Visibility:
									{/* selectedItem={'off'} value="off" */}
									<CalciteRadioGroup scale="s" 
									onClick={(e) => {

											let {value} = e.target
											//console.log(`event:`, value, e);

											ToggleLayer(layerItem, value);
										}
									}>
										<CalciteRadioGroupItem value="on" {...visibleOn} >
											ON
										</CalciteRadioGroupItem>
										<CalciteRadioGroupItem  value="off" {...visibleOff}>
											OFF
										</CalciteRadioGroupItem>
									</CalciteRadioGroup>
									
								</CalciteLabel>
								<CalciteSlider style={{marginBottom:'2rem'}} has-histogram label-ticks max-label="100" max="100" page-step="1" snap step="1" value={layerItem.opacity * 100}
									ticks="50" onCalciteSliderInput={(_e) => { CalciteSliderChange(_e, layerItem); }}></CalciteSlider>
								<CalciteButton 
								 	style={{marginBottom:'1em'}}
									color="red"
									width={"full"}
									onClick={(_e) => { 
										_e.preventDefault();
										removeLayerFromMap(layerItem);
									}}
								>Remove Layer From Map</CalciteButton>

							</CalciteBlock>);
						})}
				</CalciteSortableList>
			
			
			
			) : <div>No Layers Loaded On Map Yet</div>}
			
		</div>
		</>
					
	);
}


const mapStateToProps = (state) => ({
    layerList: state.layerList,
    isMapLoading: state.isMapLoading,
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	//loadLayerOnMap: (layer) => {dispatch(loadLayer(layer))},
	removeLayerFromMap: (layer) => {dispatch(removeLayer(layer))},
	removeAllLayersFromMap: () => {dispatch(removeAllLayers())}
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerControlPanel);


//Trash zone
/*

				<CalciteBlock  onDragEnd={ (e) => { console.log('Drag End: ', e)}} collapsible heading="1 Example block heading" summary="example summary heading"
				dragHandle={true} draggable={true}>
				<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
				</CalciteBlock>
				<CalciteBlock onDragEnd={ (e) => { console.log('Drag End: ', e)}} collapsible heading="2 Example block heading" summary="example summary heading"
				dragHandle={true} draggable={true}>
				<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
				</CalciteBlock>
			<CalciteBlock onDragEnd={ (e) => { console.log('Drag End: ', e)}} collapsible heading="3 Example block heading" summary="example summary heading"
			dragHandle={true} draggable={true}>
			<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
			</CalciteBlock>
			<CalciteBlock onDragEnd={ (e) => { console.log('Drag End: ', e)}} collapsible heading="4 Example block heading" summary="example summary heading"
			dragHandle={true} >
			<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
			</CalciteBlock>
*/
/*

		<div className="content-area">

		<CalciteValueList label-editing-enabled drag-enabled>
		<CalciteBlock  collapsible heading="4 Example block heading" summary="example summary heading"
		dragHandle={true} >
	<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
	</CalciteBlock>
    <CalciteValueListItem
        label="2018 Generation Alpha Population (Born 2017 or Later) [updated 2019-09-18]"
        description="GENALPHACY" value="GENALPHACY">
		<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice></CalciteValueListItem>
    <CalciteValueListItem label="2010-2018 Households: Annual Growth Rate (Esri)"
        description="HHGRW10-cY-2019-09-18.001-zZYLKJ" value="HHGRW10-cY">
		<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice></CalciteValueListItem>
    <CalciteValueListItem label="2010-2018 Households: Annual Growth Rate (Esri)"
        description="HHGRW10-cY-2019-09-18.001-zZYYZLKJ" value="HHGRW10-cY2">
		<CalciteNotice active={true}><div slot="message">An excellent assortment of content.</div></CalciteNotice>
    </CalciteValueListItem>
</CalciteValueList>


		</div>
*/
