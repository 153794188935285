
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const cpaLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Scenario_Assess_LUChg/MapServer/`
	, sublayers: [{id:2}]
	, title: 'Conservation High Prioirty Areas Restricted'
});

export const cpa = 
{
	id:'sa-hl-cpa',
	title:"Conservation Priority Areas",
	type:'group',
	showScenario:'highlandsConservation',
	items:[
	
	{layerId:'sa-hl-cpa-main', type: 'layer', item: cpaLayer, meta:null},
	
	{
		id: 'sa-hl-cpa-cl',
		type: 'group-set',
		//type: 'group',
		title: 'Classified Layers',
		items: [{
			layerKey: 'saluc-cpa-cpa',
			layerItem: null,
			server: 'terrene',
			title: 'Conservation Priority Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Conservation_Priority_Areas',
			layerId: 3,
			opacityBar: null,
			parentDiv: 'cpaDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-cpa-lulc15',
			layerItem: null,
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SAFEA_Highlands',
			mapServer: 'Conservation_Priority_Areas',
			layerId: 4,
			opacityBar: null,
			parentDiv: 'cpaDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-cpa-mr',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SAFEA_Highlands',
			mapServer: 'Conservation_Priority_Areas',
			layerId: 5,
			opacityBar: null,
			parentDiv: 'cpaDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-cpa-ts',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SAFEA_Highlands',
			mapServer: 'Conservation_Priority_Areas',
			layerId: 6,
			opacityBar: null,
			parentDiv: 'cpaDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-cpa-hdra',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to High Density Residential Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Conservation_Priority_Areas',
			layerId: 7,
			opacityBar: null,
			parentDiv: 'cpaDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-hl-cpa-cl-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};