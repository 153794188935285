import React from 'react';
import { CalcitePanel } from "@esri/calcite-components-react";


const DataSourcesPage = () => {

	return ( <CalcitePanel slot="center-row">
		
	<div className="datasources">


		
		<h3 className="text-center ">Spatial Boundary Data</h3>
		<div>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Counties </td>
							<td>The 2014 cartographic boundary shapefiles are simplified representations of selected geographic areas from the U.S. Census Bureau's Master Address File / Topologically Integrated Geographic Encoding and Referencing (MAF/TIGER) Database (MTDB).</td>
							<td> 2014 </td>
							<td> U.S. Census Bureau as the source </td>
							<td className="has-link"> <a href="https://www2.census.gov/geo/tiger/TIGER2019/COUNTY/" rel="noopener noreferrer" target="_blank"> https://www2.census.gov/geo/tiger/TIGER2019/COUNTY/</a></td>
						</tr>

						<tr>
							<td> Municipal Boundaries of NJ </td>
							<td> This data set is a spatial representation of municipalities in New Jersey developed by the NJ Office of Information Technology, Office of GIS (NJOGIS) in NJ State Plane Coordinate System (NAD83). </td>
							<td> 2020 </td>
							<td> NJDEP GIS Government Open Data </td>
							<td className="has-link"> <a href="https://njogis-newjersey.opendata.arcgis.com/datasets/3d5d1db8a1b34b418c331f4ce1fd0fef_2" rel="noopener noreferrer" target="_blank"> https://njogis-newjersey.opendata.arcgis.com/datasets/3d5d1db8a1b34b418c331f4ce1fd0fef_2</a></td>
						</tr>

						<tr>
							<td> Parcels Composite of New Jersey </td>
							<td> Parcels are a key framework dataset for the State of New Jersey's GIS spatial data network which serves the State of New Jersey information management system. To provide basic information regarding property location and ID, the normalized parcels data were developed to be compatible with the New Jersey Department of Treasury property assessment MOD-IV system. </td>
							<td> 2019 </td>
							<td> NJ Office of Information Technology, Office of GIS (NJOGIS) </td>
							<td className="has-link"> <a href="https://gisdata-njdep.opendata.arcgis.com/datasets/newjersey::parcels-composite-of-nj-download" rel="noopener noreferrer" target="_blank"> https://gisdata-njdep.opendata.arcgis.com/datasets/newjersey::parcels-composite-of-nj-download</a></td>
						</tr>

						<tr>
							<td> Highlands Boundary </td>
							<td> This dataset was developed to correspond to language in the codified version of the NJ Highlands Water Protection and Planning Act (P.L. 2004, Chapter 120) and in support of the 2008 Regional Master Plan. </td>
							<td> </td>
							<td> NJ Department of Environmental Protection, Division of Information Technology, Bureau of Geographic Information Systems. </td>
							<td className="has-link"> <a href="https://maps.nj.gov/arcgis/rest/services/Applications/NJ_Highlands/MapServer/2" rel="noopener noreferrer" target="_blank"> https://maps.nj.gov/arcgis/rest/services/Applications/NJ_Highlands/MapServer/2</a></td>
						</tr>

						<tr>
							<td> Pinelands Boundary </td>
							<td> This data represents the New Jersey Pinelands boundary (PA_Boundary). The Pinelands boundary data was created by digitizing 68 photo quarterquads. The photo quarterquads were then appended together to create this data (PA_Boundary). </td>
							<td> 2005 </td>
							<td> NJDEP </td>
							<td className="has-link"> <a href="https://www.state.nj.us/dep/gis/digidownload/metadata/statewide/pinelands.htm" rel="noopener noreferrer" target="_blank"> https://www.state.nj.us/dep/gis/digidownload/metadata/statewide/pinelands.htm</a></td>
						</tr>

						<tr>
							<td> Open Space by Management Type & Use Label </td>
							<td> This New Jersey Open Space dataset contains Green Acres encumbered and unencumbered protected open space and recreation areas. Types of open space property in this data layer include parks, conservation areas, preserves, historic sites, recreational fields, beaches, etc. The data was derived from a variety of mapped sources which vary in scale and level of accuracy. </td>
							<td> 2020 </td>
							<td> NJ Department of Environmental Protection (NJDEP) </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/gis/digidownload/zips/OpenData/Land_owner_openspace.zip" rel="noopener noreferrer" target="_blank"> https://www.nj.gov/dep/gis/digidownload/zips/OpenData/Land_owner_openspace.zip</a></td>
						</tr>
						<tr>
							<td> Sustainable Jersey Certified Municipalities </td>
							<td> This layer shows the New Jersey municipalities that were certified under the Sustainable Jersey Program in 2015. Sustainable Jersey certification is a prestigious designation for municipal governments in New Jersey. Municipalities that achieve the certification are considered by their peers, by state government and by the experts and civic organizations in New Jersey, to be among the leading municipalities striving for sustainability </td>
							<td> 2020 </td>
							<td> NJ Department of Environmental Protection (NJDEP) </td>
							<td className="has-link"> <a href="https://njogis-newjersey.opendata.arcgis.com/datasets/njdep::sustainable-jersey-certified-municipalities-of-new-jersey" rel="noopener noreferrer" target="_blank"> https://njogis-newjersey.opendata.arcgis.com/datasets/njdep::sustainable-jersey-certified-municipalities-of-new-jersey</a></td>
						</tr>

					</tbody>
				</table>
			</div>

			<div id="economic_infrastructure">
				<h3 className="text-center ">Economic Infrastructure </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Railroad Stations in NJ </td>
							<td> Point data delineating station point locations for rail, light rail, and subway service lines operated by NJ TRANSIT and connecting commuter lines operated by Port Authority Trans Hudson (PATH), Port Authority Transit Corporation (PATCO) and Southeastern Pennsylvania Transportation Authority (SEPTA).  </td>
							<td> 2018 </td>
							<td> NJ Office of Information Technology, Office of GIS (NJOGIS) </td>
							<td className="has-link"> <a href="https://njogis-newjersey.opendata.arcgis.com/datasets/railroad-stations-in-nj" rel="noopener noreferrer" target="_blank"> https://njogis-newjersey.opendata.arcgis.com/datasets/railroad-stations-in-nj</a></td>
						</tr>

						<tr>
							<td> Electric Power Transmission Lines </td>
							<td> This feature class/shapefile represents electric power transmission lines. Transmission Lines are the system of structures, wires, insulators and associated hardware that carry electric energy from one point to another in an electric power system. Lines are operated at relatively high voltages varying from 69 kV up to 765 kV, and are capable of transmitting large quantities of electricity over long distances. Underground transmission lines are included where sources were available.  </td>
							<td> 2020 (last update) </td>
							<td> Homeland Infrastructure Foundation Level Database (HIFLD): Oak Ridge National Laboratory (ORNL), Los Alamos National Laboratory (LANL), Idaho National Laboratory (INL), National Geospatial-Intelligence Agency (NGA) Homeland Security Infrastructure Program (HSIP) Team </td>
							<td className="has-link"> <a href="https://hifld-geoplatform.opendata.arcgis.com/datasets/electric-power-transmission-lines" rel="noopener noreferrer" target="_blank"> https://hifld-geoplatform.opendata.arcgis.com/datasets/electric-power-transmission-lines</a></td>
						</tr>

						<tr>
							<td> Roadway Network </td>
							<td> The NJ Roadway Network file is the Official LRS for the New Jersey Department of Transportation. The file is a geodatabase that includes a linear referencing system that represents the public roadways in New Jersey. It was developed for use with ESRI’s geographic information systems applications and was structured to conform to the UNETRANS data model. </td>
							<td> 2003 </td>
							<td> New Jersey Department of Transportation(NJDOT) </td>
							<td className="has-link"> <a href="https://www.state.nj.us/transportation/refdata/gis/data.shtm" rel="noopener noreferrer" target="_blank"> https://www.state.nj.us/transportation/refdata/gis/data.shtm</a></td>
						</tr>

						<tr>
							<td> Primary and Secondary Roads </td>
							<td> Primary roads are generally divided, limited-access highways within the interstate highway system or under State management, and are distinguished by the presence of interchanges. These highways are accessible by ramps and may include some toll highways. The MAF/TIGER Feature Classification Code (MTFCC) is S1100 for primary roads. Secondary roads are main arteries, usually in the U.S. Highway, State Highway, and/or County Highway system. These roads have one or more lanes of traffic in each direction, may or may not be divided, and usually have at-grade intersections with many other roads and driveways. They usually have both a local name and a route number. The MAF/TIGER Feature Classification Code (MTFCC) is S1200 for secondary roads. </td>
							<td> 2019 </td>
							<td> US Census Bureau, Department of Commerce </td>
							<td className="has-link"> <a href="https://catalog.data.gov/dataset/tiger-line-shapefile-2019-state-new-jersey-primary-and-secondary-roads-state-based-shapefile" rel="noopener noreferrer" target="_blank"> https://catalog.data.gov/dataset/tiger-line-shapefile-2019-state-new-jersey-primary-and-secondary-roads-state-based-shapefile</a></td>
						</tr>

						<tr>
							<td> Population and Housing 2010 </td>
							<td> The data shapefiles contains the 2010 Census block geography and the 2010 Census population and housing unit counts. For SEFEA, Population 2010 data was divided into three classes including low, medium and high. Low population represents less or equal to 122 population counts, medium 123-501 and High equal or above 502 population counts. The classifcation were based on Natural Breaks (Jenks) in ArcGIS. For SEFEA, Housing 2010 data was divided into three classes including low, medium and high. Low population represents less or equal to 50 housing counts, medium 51-215 and High equal or above 216 population counts. The classifcation were based on Natural Breaks (Jenks) in ArcGIS. </td>
							<td> 2012 (last update) </td>
							<td> U.S. Census Bureau </td>
							<td className="has-link"> <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/tiger-line-file.2010.html" rel="noopener noreferrer" target="_blank"> https://www.census.gov/geographies/mapping-files/time-series/geo/tiger-line-file.2010.html</a></td>
						</tr>

						<tr>
							<td> Hot Spot Analysis (Getis-Ord Gi*) </td>
							<td> For given weighted population and housing count features, identifies statistically significant hot spots and cold spots using the Getis-Ord Gi* statistic. </td>
							<td> 2020 </td>
							<td> Generated for SEFEA using ArcGIS tools </td>
							<td className="has-link"> <a href="https://mosaic.njaes.rutgers.edu/sefea/" rel="noopener noreferrer" target="_blank"> https://mosaic.njaes.rutgers.edu/sefea/</a></td>
						</tr>

						<tr>
							<td> Sewer Service Area </td>
							<td> This is a graphical representation of the States Sewer Service Area (SSA) mapping. The SSA mapping shows the planned method of wastewater disposal for specific areas, i.e. whether the wastewater will be collected to a regional treatment facility or treated on site and disposed of through a Surface Water (SW) discharge or a groundwater (GW) discharge. </td>
							<td> 2020 </td>
							<td>  NJ Department of Environmental Protection (NJDEP) </td>
							<td className="has-link"> <a href=" https://njogis-newjersey.opendata.arcgis.com/datasets/7f614776818e4b8580a95efd30528ebe_8" rel="noopener noreferrer" target="_blank">  https://njogis-newjersey.opendata.arcgis.com/datasets/7f614776818e4b8580a95efd30528ebe_8 </a></td>
						</tr>

					</tbody>
				</table>
			</div>
			<div id="forest">
				<h3 className="text-center ">Forest </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Forest Biomass </td>
							<td> A spatially explicit dataset of aboveground live forest biomass was made from ground measured inventory plots for the conterminous U.S., Alaska and Puerto Rico. The plot data are from the USDA Forest Service Forest Inventory and Analysis (FIA) program. </td>
							<td> 2008 </td>
							<td> USDA Forest Service Forest Inventory and Analysis (FIA) program </td>
							<td className="has-link"> <a href="https://data.fs.usda.gov/geodata/rastergateway/biomass/" rel="noopener noreferrer" target="_blank"> https://data.fs.usda.gov/geodata/rastergateway/biomass/</a></td>
						</tr>

						<tr>
							<td> Forest Group </td>
							<td> This dataset portrays 28 forest type groups across the contiguous United States. These data were derived from MODIS composite images from the 2002 and 2003 growing seasons in combination with nearly 100 other geospatial data layers, including elevation, slope, aspect, ecoregions, and PRISM climate data. The dataset was developed as a collaborative effort between the USFS Forest Inventory and Analysis and Forest Health Monitoring programs and the USFS Geospatial Technology and Applications Center. </td>
							<td> 2013 </td>
							<td> USDA Forest Service - Forest Inventory and Analysis (FIA) Program & Geospatial Technology and Applications Center (GTAC) </td>
							<td className="has-link"> <a href="https://data.fs.usda.gov/geodata/rastergateway/forest_type/" rel="noopener noreferrer" target="_blank"> https://data.fs.usda.gov/geodata/rastergateway/forest_type/</a></td>
						</tr>

						<tr>
							<td> Forest Type </td>
							<td>
								<p>These data were derived from MODIS composite images from the 2002 and 2003 growing seasons in combination with nearly 100 other geospatial data layers, including elevation, slope, aspect, ecoregions, and PRISM climate data.</p>
								<p>The dataset was developed as a collaborative effort between the USFS Forest Inventory and Analysis and Forest Health Monitoring programs and the USFS Geospatial Technology and Applications Center.</p>
							</td>
							<td> 2013 </td>
							<td> USDA Forest Service - Forest Inventory and Analysis (FIA) Program & Geospatial Technology and Applications Center (GTAC) </td>
							<td className="has-link"> <a href="https://data.fs.usda.gov/geodata/rastergateway/forest_type/" rel="noopener noreferrer" target="_blank"> https://data.fs.usda.gov/geodata/rastergateway/forest_type/</a></td>
						</tr>

						<tr>
							<td> Tree Canopy, Tree Canopy Change </td>
							<td> The USFS derives tree canopy cover from multi-spectral Landsat imagery and other available ground and ancillary information. 2011 and 2016 Forest canopy products are available for the Continental United States, coastal Alaska, Hawaii, and Puerto Rico. </td>
							<td> 2014, 2019 </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://data.fs.usda.gov/geodata/rastergateway/treecanopycover/index.php#table1" rel="noopener noreferrer" target="_blank"> https://data.fs.usda.gov/geodata/rastergateway/treecanopycover/index.php#table1</a></td>
						</tr>

						<tr>
							<td> Forest Carbon </td>
							<td> Through application of a nearest-neighbor imputation approach, mapped estimates of forest carbon density were developed for the contiguous United States using the annual forest inventory conducted by the USDA Forest Service Forest Inventory and Analysis (FIA) program, MODIS satellite imagery, and ancillary geospatial datasets. This data publication contains the following 8 raster maps: total forest carbon in all stocks, live tree aboveground forest carbon, live tree belowground forest carbon, forest down dead carbon, forest litter carbon, forest standing dead carbon, forest soil organic carbon, and forest understory carbon. </td>
							<td> 2013 </td>
							<td> Wilson, Barry Tyler; Woodall, Christopher W.; Griffith, Douglas M. 2013. Forest carbon stocks of the contiguous United States (2000-2009). Newtown Square, PA: U.S. Department of Agriculture, Forest Service, Northern Research Station.
								<a href="https://doi.org/10.2737/RDS-2013-0004" rel="noopener noreferrer" target="_blank">https://doi.org/10.2737/RDS-2013-0004</a>
							</td>
							<td className="has-link"> <a href="https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0004" rel="noopener noreferrer" target="_blank"> https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0004</a></td>
						</tr>

						<tr>
							<td> Total basal area (2000-2009) </td>
							<td> This data publication contains raster maps of live tree basal area for each tree species along with corresponding assessment data. </td>
							<td> 2013 </td>
							<td> Wilson, Barry Tyler; Lister, Andrew J.; Riemann, Rachel I.; Griffith, Douglas M. 2013. Live tree species basal area of the contiguous United States (2000-2009). Newtown Square, PA: USDA Forest Service, Rocky Mountain Research Station.
								<a href="https://doi.org/10.2737/RDS-2013-0013" rel="noopener noreferrer" target="_blank">https://doi.org/10.2737/RDS-2013-0013</a> </td>
							<td className="has-link"> <a href="https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0013" rel="noopener noreferrer" target="_blank"> https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0013</a></td>
						</tr>

						<tr>
							<td> Treed Area </td>
							<td> Tree mask based on occurrence (modeled extent) for all tree species. </td>
							<td> 2013 </td>
							<td> Wilson, Barry Tyler; Lister, Andrew J.; Riemann, Rachel I.; Griffith, Douglas M. 2013. Live tree species basal area of the contiguous United States (2000-2009). Newtown Square, PA: USDA Forest Service, Rocky Mountain Research Station. <a href="https://doi.org/10.2737/RDS-2013-0013" rel="noopener noreferrer" target="_blank">https://doi.org/10.2737/RDS-2013-0013</a></td>
							<td className="has-link"> <a href="https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0013" rel="noopener noreferrer" target="_blank"> https://www.fs.usda.gov/rds/archive/catalog/RDS-2013-0013</a></td>
						</tr>

						<tr>
							<td> BA loss from all agents (TBA Loss) </td>
							<td> The purpose of individual risk models is to derive the basal area loss associated with each damage agent/tree species host pair wherever they are found on US forested lands. By creating a composite of all models this dataset shows the projected basal area loss from all forest pests and pathogens over a 15-year period starting in 2013. </td>
							<td> 2018 ( Updated) </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml" rel="noopener noreferrer" target="_blank"> https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml</a></td>
						</tr>

					</tbody>
				</table>
			</div>

			<div id="hazards">
				<h3 className="text-center ">Hazards </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Projected Loss: Pests East, Emerald Ash Borer, Species </td>
							<td> The purpose of individual risk models is to derive the basal area loss associated with each damage agent/tree species host pair wherever they are found on US forested lands. By creating a composite of all models this dataset shows the projected basal area loss from all forest pests and pathogens over a 15-year period starting in 2013.
							</td>
							<td> 2018 ( Updated) </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml" rel="noopener noreferrer" target="_blank"> https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml</a></td>
						</tr>

						<tr>
							<td> TBA Loss </td>
							<td>
								<p>This raster dataset shows the projected basal area loss due to all forest pests and pathogen activity, assuming no remediating management, over the 2013-2027 time frame.</p>
								<p>The 2012 National Insect and Disease Risk Map (NIDRM) Project integrates 186 individual risk models constructed within a common, consistent framework that accounts for regional variations in forest health conditions. These 186 models are built to portray the expected loss of host basal area for each pair of damage agents and hosts over each unique landscape where they coexist.</p>
								<p>This dataset composites the projected BA loss for XX by tree species host model and then divides the total BA losses from XX by total basal area. </p>
							</td>
							<td> 2018 ( Updated) </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml" rel="noopener noreferrer" target="_blank"> https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml</a></td>
						</tr>

						<tr>
							<td> Risk </td>
							<td>
								<p>The composite risk dataset is the central product of the 2012 National Insect and Disease Risk Map (NIDRM) project. For this analysis, the threshold for mapping risk is: the expectation that, without remediation, 25 percent or more of the standing live basal area of trees greater than 1 inch in diameter will die over a 15-year (2013 to 2027) time frame due to insects and diseases. </p>
								<p>The 2012 NIDRM represents the integration of 186 individual insect and disease hazard models, all constructed within a common GIS-based, multi-criteria framework that can account for regional variations in forest health. </p>
								<p>The production of the 2012 Risk Map has been a highly collaborative process led by the Forest Health Monitoring Program (FHM) of the USDA Forest Service (Forest Service).
									FHM staffs from all Regions, State forestry agencies, Forest Service Forest Health Protection, and Forest Service Research and Development participated in developing the 2012 NIDRM. </p>
							</td>
							<td> 2018 ( Updated) </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml" rel="noopener noreferrer" target="_blank"> https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml</a></td>
						</tr>
						<tr>
							<td> PCT TBA Loss </td>
							<td>
								<p>This raster dataset shows the projected percentage loss of total basal area from all forest pests and pathogens, assuming no remediating management, over the 2013-2027 time frame.</p>
								<p>The 2012 National Insect and Disease Risk Map (NIDRM) Project integrates 186 individual risk models constructed within a common, consistent framework that accounts for regional variations in forest health conditions.
									These 186 models are built to portray the expected loss of host basal area for each pair of damage agents and hosts over each unique landscape where they coexist. </p>
							</td>
							<td> 2018 ( Updated) </td>
							<td> United States Forest Service (USFS) </td>
							<td className="has-link"> <a href="https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml" rel="noopener noreferrer" target="_blank"> https://www.fs.fed.us/foresthealth/applied-sciences/mapping-reporting/national-risk-maps.shtml</a></td>
						</tr>

					</tbody>
				</table>
			</div>
			<div id="lulc">
				<h3 className="text-center ">Land Use Land Cover NLCD </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Land Use Land Cover NLCD </td>
							<td> NLCD 2016 now offers land cover for years 2001, 2003, 2006, 2008, 2011, 2013, 2016, and impervious surface for 2001, 2006, 2011, and 2016. </td>
							<td> 2020 </td>
							<td>U.S. Geological Survey (USGS), Earth Resources Observation and Science (EROS) Center, MRLC Project</td>
							<td className="has-link"> <a href="https://www.mrlc.gov/" rel="noopener noreferrer" target="_blank"> https://www.mrlc.gov/</a></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div id="hydrology">
				<h3 className="text-center ">Hydrology </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Streams </td>
							<td> This metadata file contains information for Flowlines delineated for NJ from 2002 color infrared (CIR) imagery with attributes extracted from the National Hydrography Dataset (NHD) . </td>
							<td> 2010 </td>
							<td> NJ Department of Environmental Protection (NJDEP), Office of Information Resources Management (OIRM), Bureau of Geographic Information System (BGIS) </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/gis/nhd2002.html" rel="noopener noreferrer" target="_blank"> https://www.nj.gov/dep/gis/nhd2002.html</a></td>
						</tr>
						<tr>
							<td> Water bodies </td>
							<td> Water Bodies provides a database of areal water features that identifies the water bodies or reaches that comprise the surface water drainage system of United States. </td>
							<td> 2010 </td>
							<td> NJ Department of Environmental Protection (NJDEP), Office of Information Resources Management (OIRM), Bureau of Geographic Information System (BGIS) </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/gis/nhd2002.html" rel="noopener noreferrer" target="_blank">https://www.nj.gov/dep/gis/nhd2002.html</a></td>
						</tr>

					</tbody>
				</table>
			</div>
			<div id="wildlife">
				<h3 className="text-center ">Wildlife </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Connecting Habitat  </td>
							<td> Connecting Habitat Across New Jersey (CHANJ) represents a statewide habitat connectivity plan, developed in collaboration with a multi-partner, multi-disciplinary working group representing over 40 different agencies across the state, convened by the ENSP to address the importance of landscape permeability for the persistence of native terrestrial wildlife species.</td>
							<td> 2018 </td>
							<td> NJDEP GIS Environmental Open Data </td>
							<td className="has-link"> <a href="https://njdep.maps.arcgis.com/apps/webappviewer/index.html?id=53339ff12f27488d8462e5e2c4c21b5c" rel="noopener noreferrer" target="_blank">https://njdep.maps.arcgis.com/apps/webappviewer/index.html?id=53339ff12f27488d8462e5e2c4c21b5c</a></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div id="watershed">
				<h3 className="text-center ">Watershed Ecological Health </h3>
				<table className="data-sources-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Meta Data Description</th>
							<th>Publication Year</th>
							<th>Publisher Information</th>
							<th>Online Linkages</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td> Surface Water Quality Standards </td>
							<td> This data is a digital representation of New Jersey's Surface Water Quality Standards in accordance with "Surface Water Quality Standards for New Jersey Waters" as designated in N.J.A.C. 7:9 B. The Surface Water Quality Standards (SWQS) establish the designated uses to be achieved and specify the water quality (criteria) necessary to protect the State's waters. Designated uses include potable water, propagation of fish and wildlife, recreation, agricultural and industrial supplies, and navigation. These are reflected in use classifications assigned to specific waters.</td>
							<td> 2020 </td>
							<td> NJDEP GIS Environmental Open Data </td>
							<td className="has-link"> <a href="https://gisdata-njdep.opendata.arcgis.com/datasets/surface-water-quality-classification-of-new-jersey" rel="noopener noreferrer" target="_blank"> https://gisdata-njdep.opendata.arcgis.com/datasets/surface-water-quality-classification-of-new-jersey</a></td>
						</tr>

						<tr>
							<td> General Aquatic Life Use Assessment </td>
							<td> The Assessment Unit (AU) Level General Aquatic Life Use Assessment Results incorporates the water quality results for all monitoring stations associated within an AU that is included in the 2016 NJ Integrated Water Quality Monitoring and Assessment Report (Integrated Report). This data represents the assessment results in NJ's 958 AUs to determine if the General Aquatic Life Use designated use was attained and the results for the 22 water quality parameters associated with the designated use. If an AU includes more than one station, the results for each parameter are combined with the ‘worst case’ station assessment representing the AU. That is, if any of the stations are impaired for a parameter, then the parameter is impaired at the AU level. If some stations are fully attaining for a parameter but others have insufficient data, then the parameter is fully attaining. The data reflects which of three assessment results each assessment was assigned: Attaining- Fully Supporting, Insufficient Data- Insufficient data was available to assess, Non-Support- Non-Supporting. </td>
							<td> 2020 </td>
							<td> NJDEP GIS Environmental Open Data </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/wms/bears/assessment.htm" rel="noopener noreferrer" target="_blank"> https://www.nj.gov/dep/wms/bears/assessment.htm </a></td>
						</tr>

						<tr>
							<td> Watershed management areas </td>
							<td> The Assessment Unit (AU) Level General Aquatic Life Use Assessment Results incorporates the water quality results for all monitoring stations associated within an AU that is included in the 2016 NJ Integrated Water Quality Monitoring and Assessment Report (Integrated Report). This data represents the assessment results in NJ's 958 AUs to determine if the General Aquatic Life Use designated use was attained and the results for the 22 water quality parameters associated with the designated use. If an AU includes more than one station, the results for each parameter are combined with the ‘worst case’ station assessment representing the AU. That is, if any of the stations are impaired for a parameter, then the parameter is impaired at the AU level. If some stations are fully attaining for a parameter but others have insufficient data, then the parameter is fully attaining. The data reflects which of three assessment results each assessment was assigned: Attaining- Fully Supporting, Insufficient Data- Insufficient data was available to assess, Non-Support- Non-Supporting. </td>
							<td> 2009 </td>
							<td> NJDEP GIS Environmental Open Data </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/gis/digidownload/metadata/html/Govt_admin_WMA_bnd.html" rel="noopener noreferrer" target="_blank"> https://www.nj.gov/dep/gis/digidownload/metadata/html/Govt_admin_WMA_bnd.html </a></td>
						</tr>

						<tr>
							<td> Watershed HUC14 </td>
							<td> This coverage defines hydrologic units for New Jersey. It is designed to identify the area which drains to a point of interest. It can be used as a starting point to delineate a study area with a drainage basin boundary. </td>
							<td> 2016 </td>
							<td> NJDEP GIS Environmental Open Data </td>
							<td className="has-link"> <a href="https://www.nj.gov/dep/gis/digidownload/zips/OpenData/Hydr_HUC14_bnd.zip" rel="noopener noreferrer" target="_blank"> https://www.nj.gov/dep/gis/digidownload/zips/OpenData/Hydr_HUC14_bnd.zip </a></td>
						</tr>

						<tr>
							<td> Net Water Availability by Subwatershed </td>
							<td> A central goal of the Regional Master Plan is to determine the amount and type of human development and activity that the ecosystem of the Highlands Region can sustain while still maintaining the overall ecological values thereof, with special reference to surface and ground water quality and supply.  </td>
							<td> 2012 </td>
							<td> NJ Highlands Water Protection and Planning Council (NJHWPPC) </td>
							<td className="has-link"> <a href="https://highlands-data-njhighlands.opendata.arcgis.com/datasets/net-water-availability-by-subwatershed " rel="noopener noreferrer" target="_blank">https://highlands-data-njhighlands.opendata.arcgis.com/datasets/net-water-availability-by-subwatershed </a></td>
						</tr>

						<tr>
							<td> Riparian Corridor Integrity Score </td>
							<td> Riparian area integrity is be defined by an area’s ability to provide water protection and ecological function. These functions include nutrient and sediment filtration, stream bank stabilization, wildlife migration corridors and habitat, storm water and flood water storage, and stream water quality protection. The NJ Highlands Council's characterization of riparian area integrity involves the assessment of existing land use and land cover condition. Using the riparian area map as the base map, land use within riparian areas was analyzed through assessment of land use and road intensity information. </td>
							<td> 2012 </td>
							<td> NJ Highlands Water Protection and Planning Council (NJHWPPC) </td>
							<td className="has-link"> <a href="https://highlands-data-njhighlands.opendata.arcgis.com/datasets/riparian-corridor-integrity-score " rel="noopener noreferrer" target="_blank">https://highlands-data-njhighlands.opendata.arcgis.com/datasets/riparian-corridor-integrity-score </a></td>
						</tr>

						<tr>
							<td> Forest Integrity Score </td>
							<td> The Highlands Council assessed forest cover integrity in the Highlands Region at the watershed level because forests are important for the protection of water quality and quantity. </td>
							<td> 2012 </td>
							<td> NJ Highlands Water Protection and Planning Council (NJHWPPC) </td>
							<td className="has-link"> <a href="https://highlands-data-njhighlands.opendata.arcgis.com/datasets/forest-integrity-score " rel="noopener noreferrer" target="_blank">https://highlands-data-njhighlands.opendata.arcgis.com/datasets/forest-integrity-score </a></td>
						</tr>

						<tr>
							<td> Watershed Values </td>
							<td> A watershed describes an area of land that drains downslope to the lowest point. Water moves through a network of drainage pathways, both underground and on the surface, and these pathways converge into streams and rivers, which become progressively larger (i.e., higher order) as the water moves downstream and the size of the contributing drainage area increases. </td>
							<td> 2012 </td>
							<td> NJ Highlands Water Protection and Planning Council (NJHWPPC) </td>
							<td className="has-link"> <a href="https://highlands-data-njhighlands.opendata.arcgis.com/datasets/watershed-values " rel="noopener noreferrer" target="_blank"> https://highlands-data-njhighlands.opendata.arcgis.com/datasets/watershed-values</a></td>
						</tr>
					</tbody>
				</table>
			</div>


	</div>
	</CalcitePanel> );
}


export default DataSourcesPage;