import React
,
{ 
	//useEffect,
useState } 
from "react";
import { connect } from "react-redux";

import {

	CalciteButton,
	CalcitePanel,
	//CalciteRadioGroup,
	//CalciteRadioGroupItem
	CalcitePickList,
	CalcitePickListGroup,
	CalcitePickListItem,

} from "@esri/calcite-components-react";


import attributePdf from './documents/Attribute Based data for Highlands and Pinelands Regions.pdf';
import clusterPdf from './documents/Cluster Based data for Highlands and Pinelands Regions.pdf';
import distancePdf from './documents/Distance Based data for Highlands and Pinelands Regions.pdf';
import priorityPdf from './documents/Priority based data for Highlands and Pinelands Regions.pdf';



/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPVariables = ({geographicArea, analysisClass, variableList, SetVariableList, RemoveServicePanel}) => {
	const InstructionalText = `Select between two (2) to five (5) variables. Disabled variables may not have their Image Service ready yet.`;

	const [pickListVars, SetPickListVars] = useState(variableList);


	const UpdateSelectedVariableList = (pickListEvent) => { 
		console.log('[UpdateSelectedVariableList]', pickListVars, pickListEvent.detail);
		const myUpdatedPickList = pickListVars.map( (varInfo, idx) => {
			if( varInfo.isGroup ) {
				return {
					...varInfo,
					variables: varInfo.variables.map( (inGroupVar, idx2) => {
						return {
							...inGroupVar,
							selected: (	
								pickListEvent.detail.has(inGroupVar.key)
								&& (
									!inGroupVar.region ||(inGroupVar.region && inGroupVar.region === geographicArea)
								)
							)
						}
					}),
				}
			}
			return {
				...varInfo,
				selected: (	
					pickListEvent.detail.has(varInfo.key)
					&& (
						!varInfo.region ||(varInfo.region && varInfo.region === geographicArea)
					)
				)
			}
		});
		
		//console.log('Updating list : ', myUpdatedPickList, pickListVars);
		SetPickListVars( myUpdatedPickList );

	}
	
	const ConfirmSelection = () => {
		const test = pickListVars.flatMap( (item) => {
			if(item.isGroup) {
				return item.variables;
			}

			return item;
		})
		console.log('Confirming selection', test);
		SetVariableList(test.filter( (varItem) => {
			return varItem.selected;
		}));
	}

	const CreatePickListItem = (varInfo, key='') => {
		return <CalcitePickListItem 
			key={key}
			disabled={
				(pickSize > 4 && !varInfo.selected)
				|| varInfo.rasterId === null
				? true : undefined
			}
			label={varInfo.label} value={varInfo.key} 
		/>
	}
	const CreatePickListItems = (listOfVariableItems) => {

		return listOfVariableItems.map( (varInfo, key) => {
			if( varInfo.isGroup ) {
				return <CalcitePickListGroup key={key} groupTitle={varInfo.title}>
					{CreatePickListItems(varInfo.variables)}
				</CalcitePickListGroup>
			}

			if( !varInfo.region ||(varInfo.region && varInfo.region === geographicArea) ) {
				return CreatePickListItem(varInfo, key);
			}
			return null;
		});
	}

	const GetLinkToPdf = () => {
		switch(analysisClass) {
			case 'priority-based':
				return  <>
					<h4>Here is a document with the descriptions for each variable in this class: </h4>
					<div>
						<CalciteButton target="_blank" href={priorityPdf} width="full" color="neutral" 
						iconStart="link" iconEnd="file-pdf">Priority Based Variable Descriptions (.pdf)</CalciteButton>
					</div>
				</>
			case 'distance-based':
				return  <>
					<h4>Here is a document with the descriptions for each variable in this class: </h4>
					<div>
						<CalciteButton target="_blank" href={distancePdf} width="full" color="neutral" 
						iconStart="link" iconEnd="file-pdf">Distance Based Variable Descriptions (.pdf)</CalciteButton>
					</div>
				</>
			case 'attribute-based':
				return  <>
					<h4>Here is a document with the descriptions for each variable in this class: </h4>
					<div>
						<CalciteButton target="_blank" href={attributePdf} width="full" color="neutral" 
						iconStart="link" iconEnd="file-pdf">Attribute Based Variable Descriptions (.pdf)</CalciteButton>
					</div>
				</>
			case 'cluster-based':
				return  <>
					<h4>Here is a document with the descriptions for each variable in this class: </h4>
					<div>
						<CalciteButton target="_blank" href={clusterPdf} width="full" color="neutral" 
						iconStart="link" iconEnd="file-pdf">Cluster Based Variable Descriptions (.pdf)</CalciteButton>
					</div>
				</>
			default:
				//<CalciteButton target="_blank" href={tutorialPdf} width="full" color="neutral" iconStart="link" iconEnd="file-pdf">Download AHP Tool Tutorial (.docx)</CalciteButton>
				break;
		}
		
		return <>
		<h4><em>Unknown Analysis Class</em></h4>
		</>;
	}

	const pickSize = pickListVars ? pickListVars.reduce(
		(previousValue, currentValue) => {

			if( currentValue.isGroup) {
				return previousValue+ currentValue.variables.reduce( 
					(subPreviousValue, subCurrentValue) => {
						
						if( subCurrentValue.selected ) {
							return subPreviousValue+1;
						}
						return subPreviousValue;
					}, 0
				);
			}


			if( currentValue.selected ) {
				return previousValue+1;
			}
			return previousValue;
		},
		0 
	) : 0;

	return (<>
		
			<CalcitePanel
				key={'select-variables'}
				heading={'Select Variables'}
				summary={InstructionalText}
				onCalcitePanelBackClick={RemoveServicePanel}
			>
				<div className="content-area">
					
					<div style={{borderBottom:'1px dotted black', marginBottom: '.5rem', paddingBottom: '.5rem'}}>
						{GetLinkToPdf()}
					</div>
					<div style={{marginTop:'.5em',marginBottom:'1em'}}>
						<CalciteButton width="full" disabled={pickSize<2 || undefined} onClick={ConfirmSelection}>Confirm Variables</CalciteButton>
					</div>
					<CalcitePickList 
						multiple
						onCalciteListChange={UpdateSelectedVariableList}
					>
						{CreatePickListItems(pickListVars)}
					</CalcitePickList>
					<div style={{marginTop:'1em',marginBottom:'1em'}}>
						<CalciteButton width="full" disabled={pickSize<2 || undefined} onClick={ConfirmSelection}>Confirm Variables</CalciteButton>
					</div>
				</div>
			</CalcitePanel>

	</>);
};



const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPVariables);


/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/