/**
 * This file will contain all the functions that will be used to parse the url using esri's urlUtils
 */
import axios from "axios";
import {Buffer} from 'buffer';
import * as urlUtils from "@arcgis/core/core/urlUtils";


 
const apiUrls = {
	saveMap: `${process.env.REACT_APP_API_URL}/share/store/`,
	loadMap: `${process.env.REACT_APP_API_URL}/share/`
};

export const GetUrlParamsFromKey = async (shareMapKey) => {

	const myMapOptions = await axios.get(
		`${apiUrls.loadMap}/${shareMapKey}`
	);
		//console.log('My map options:', myMapOptions)
	if(myMapOptions.data.code !== 'OK') {

		return null;
	}

	//console.log(myMapOptions.data.payload.shareMapJson);

	const myJsonObj = await JSON.parse(myMapOptions.data.payload.shareMapJson);

	//console.log(myJsonObj);
	//return 'asdf';
	return myJsonObj;
}

export const GetCurrentUrlOptions = async (shareMapKey) => {
	const urlObj = {
		origin: `${window.origin}/`,
		params: null,
		isMinimal: false
	};

	if( shareMapKey ) {
		const myRemoteOpts = GetUrlParamsFromKey(shareMapKey);
		if( myRemoteOpts ) {
			//console.log('REMOTE OPTIONS', myRemoteOpts);

			return myRemoteOpts;
		}
		console.log('Failed share map key')
	}
	//let {shareMapKey} = useParams();

	const myCurrentParameters = urlUtils.urlToObject( window.location.href );
	//console.log('Current params from urlToObject', myCurrentParameters);

	//checking for options
	if( myCurrentParameters.query && myCurrentParameters.query.options ) {
		urlObj.params = JSON.parse( myCurrentParameters.query.options );
	}

	//Checking for minimal
	if( myCurrentParameters.query && myCurrentParameters.query.minimal !== undefined ) {
		//console.log('[urlobj] Is minimal should now be true');
		urlObj.isMinimal = true;
	}

	

	return urlObj;
}


export const GenerateUrlOptions = (arcgisMap, isMinimal = false) => {
	const urlObj = {
		origin: `${window.origin}/`,
		params:{
			center: null,
			zoom: null,
			basemap: null,
			layers: []
		},
		isMinimal
	};
	if( !arcgisMap.ready ) { 
		return urlObj;
	}

	urlObj.params.basemap = arcgisMap.map.basemap ? 
		(
			//try to get the real ID first
			arcgisMap.map.basemap.id && !/\d/.test(arcgisMap.map.basemap.id) ? arcgisMap.map.basemap.id :  _getBasemapIdByTitle(arcgisMap.map.basemap.title) 
		): 'gray'
	;

	urlObj.params.center = {
		longitude: arcgisMap.center.longitude,
		latitude: arcgisMap.center.latitude,
	};
	urlObj.params.zoom = arcgisMap.zoom;


	//console.log('Custom layer layers', arcgisMap.map.layers);

	urlObj.params.layers = arcgisMap.map.layers.map( (layer) => {
		//console.log('[UrlOptionsLogic] GenerateUrlOptions ' , layer);
		//if this is an imported layer
		if( layer.ora && layer.ora.custom ) {
			//console.log('Custom layer at: ', layer, layer.sublayers, layer.layerId,layer.ora.layerId);


			if( layer.sublayers ) {
				
				return {
					layerId: Buffer.from(layer.ora.layerId).toString('base64') || null ,
					trimLayerId: layer.ora.layerId.replace(`${layer.url}/`,''),
					title: layer.title,
					sublayers: layer.sublayers || null ,
					opacity: layer.opacity,
					visible: layer.visible,
					custom: layer.ora ? layer.ora.custom : null,
					type: layer.type

				};
			}else if( layer.portalItem ) {
				
				return {
					layerId: Buffer.from(layer.ora.layerId).toString('base64') || null ,
					trimLayerId: layer.ora.layerId.replace(`${layer.url}/`,''),
					sublayers: layer.sublayers || null ,
					opacity: layer.opacity,
					visible: layer.visible,
					custom: layer.ora ? layer.ora.custom : null,
					type: layer.type,
					isPortal: true,

				};
			}else if( layer.ora && layer.ora.rasterRenderer ) {
				//console.log('UrlOptionsLogic', layer, layer.ora);

				//let myRasterRenderer = null;
				

				return {
					layerId: layer.ora.layerId,
					trimLayerId: layer.ora.layerId.replace(`${layer.url}/`,''),

					opacity: layer.opacity,
					visible: layer.visible,
					custom: layer.ora ? layer.ora.custom : null,
					ahpServiceUrl: layer.url,
					ahpTitle: layer.title,
					rasterRenderer: Buffer.from(layer.ora.rasterRenderer).toString('base64'),
					type: layer.type,
				};
			}else{
				return {
					layerId: layer.ora ? Buffer.from(layer.ora.layerId).toString('base64') : null ,
					opacity: layer.opacity,
					visible: layer.visible,
					custom: layer.ora ? layer.ora.custom : null,
					type: layer.type
				};
			}
		}


		return {
			layerId: layer.ora ? layer.ora.layerId : '' ,
			opacity: layer.opacity,
			visible: layer.visible,
			custom: layer.ora ? layer.ora.custom : null
		};
	})

	return urlObj;
}

export const CreateUrlFromUrlOptions = (urlOptions, forceMinimal=false) => {
	return `${urlOptions.origin}${process.env.REACT_APP_HOMEPAGE_FOLDER.substring(1)}?options=${JSON.stringify(urlOptions.params)}${urlOptions.isMinimal || forceMinimal ? '&minimal' : ''}`;
}

//Fall back incase basemap.id id null.
const _getBasemapIdByTitle = (basemapTitle) => {
	switch(basemapTitle) {
		case 'Imagery':
			return 'satellite';
		case 'Imagery Hybrid':
			return 'hybrid';
		case 'Streets':
			return 'streets';
		case 'Topographic':
			return 'topo-vector';
		case 'World Navigation Map':
		case 'Navigation':
			return 'streets-navigation-vector';
		case 'Streets (Night)':
			return 'streets-night-vector';
		case 'Terrain with Labels':
			return 'terrain';
		case 'Light Gray Canvas':
			return 'gray';
		case 'Dark Gray Canvas':
			return 'dark-gray';
		case 'Oceans':
			return 'oceans';
		case 'National Geographic Style Map':
			return 'national-geographic';
		case 'OpenStreetMap':
			return 'osm';
		default:
			return 'topo-vector';
	}
}




export const SaveMapOptions = (shareMapTitle, shareMapJson ) => {
	const form = new FormData();
	form.append('shareMapTitle', shareMapTitle);
	form.append('shareMapJson', JSON.stringify(shareMapJson));

	return axios.post(
		apiUrls.saveMap,
		form, {
			headers: {
			  // 'application/json' is the modern content-type for JSON, but some
			  // older servers may use 'text/json'.
			  // See: http://bit.ly/text-json
			  'content-type': 'application/x-www-form-urlencoded'
			}
		  }
	);
}