//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const thisId = 'aa-pl-tba';
export const plTreeBasalArea = {
	id: thisId,
	title:"Tree Basal Area",
	type:'group',
	description:`Tree basal area represents data on tree presence and total basal area across all species.`,
	items: [
		{
			layerKey: 'pineTree-basal',
			server: 'terrene',
			title: 'Total basal area. Cumulative across all tree species',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Tree_Basal_Area',
			layerId: 0,
			parentDiv: 'pineTree',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineTree-treed',
			server: 'terrene',
			title: 'Treed area. Binary map of tree presence.',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Tree_Basal_Area',
			layerId: 1,
			parentDiv: 'pineTree',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
		return {
			layerId: `aa-pl-hydro-${floodmapperObj.layerKey}`,
			type: 'layer',
			item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
				, sublayers: [{id:floodmapperObj.layerId}]
				, title: floodmapperObj.title
			}),
			meta:null}
	})
}