import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const boundCounties = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/0"
	, title: 'Counties'
	, outFields: ['*']
	, popupEnabled: true
});
boundCounties.when(() => {
	//Create a template of literally all the fields
	boundCounties.popupTemplate = boundCounties.createPopupTemplate();
});

const boundMunis = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/1"
	, title: 'Muncipalities'
	, outFields: ['*']
	, popupEnabled: true
});
boundMunis.when(() => {
	//Create a template of literally all the fields
	boundMunis.popupTemplate = boundMunis.createPopupTemplate();
});

const boundParcels = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/2"
	, title: 'Property Parcels'
	, outFields: ['*']
	, popupEnabled: true
});
boundParcels.when(() => {
	//Create a template of literally all the fields
	boundParcels.popupTemplate = boundParcels.createPopupTemplate();
});

const boundBoundaries = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/3"
	, title: 'Pinelands Boundary'
	, outFields: ['*']
	, popupEnabled: true
});
boundBoundaries.when(() => {
	//Create a template of literally all the fields
	boundBoundaries.popupTemplate = boundBoundaries.createPopupTemplate();
});

const boundMgmtArea = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/4"
	, title: 'Pinelands Management Areas'
	, outFields: ['*']
	, popupEnabled: true
});
boundMgmtArea.when(() => {
	//Create a template of literally all the fields
	boundMgmtArea.popupTemplate = boundMgmtArea.createPopupTemplate();
});

/*
const boundMgmtType = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/5"
	, title: 'Open Space Management Type'
	, outFields: ['*']
	, popupEnabled: true
});
boundMgmtType.when(() => {
	//Create a template of literally all the fields
	boundMgmtArea.popupTemplate = boundMgmtArea.createPopupTemplate();
});

const boundOpenSpace = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/6"
	, title: 'Open Space Use Label'
	, outFields: ['*']
	, popupEnabled: true
});
boundOpenSpace.when(() => {
	//Create a template of literally all the fields
	boundOpenSpace.popupTemplate = boundOpenSpace.createPopupTemplate();
});
*/
const boundSustain = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Pinelands_Boundary/MapServer/6"
	, title: 'Sustainable Jersery Certified Municipalities'
	, outFields: ['*']
	, popupEnabled: true
});
boundSustain.when(() => {
	//Create a template of literally all the fields
	boundSustain.popupTemplate = boundSustain.createPopupTemplate();
});

export const boundaryPinelands = {
	id: 'bl-pl-pbl',
	title:"Pinelands Boundary Layers",
	type:'group',
	
	items: [
		{layerId:'bl-pl-pbl-county', type: 'layer', item: boundCounties, meta:null},
		{layerId:'bl-pl-pbl-mun', type: 'layer', item: boundMunis, meta:null},
		{layerId:'bl-pl-pbl-parcel', type: 'layer', item: boundParcels, meta:null},
		{layerId:'bl-pl-pbl-bound', type: 'layer', item: boundBoundaries, meta:null},
		
		{layerId:'bl-pl-pbl-mgmt-area', type: 'layer', item: boundMgmtArea, meta:null},
		//{layerId:'bl-hl-hbl-mgmt-type', type: 'layer', item: boundMgmtType, meta:null},
		//{layerId:'bl-hl-hbl-os', type: 'layer', item: boundOpenSpace, meta:null},
		{layerId:'bl-pl-pbl-sustain', type: 'layer', item: boundSustain, meta:null},
	]
}

