//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

import {projectedLossPestsEastGroup, projectedLossEmeraldAshBorerGroup, projectedLossRateSpeciesGroup} from './hazardsGroupCompilation';

const compTbaLoss = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Composite_hazard/MapServer/"
	, sublayers: [{id:0}]
	, title: 'TBA Loss'
});
const compRisk = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Composite_hazard/MapServer/"
	, sublayers: [{id:1}]
	, title: 'Risk'
});
const compPctTbaLoss = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Composite_hazard/MapServer/"
	, sublayers: [{id:2}]
	, title: 'PCT TBA Loss'
});




/*
forestBiomass.when(() => {
	//Create a template of literally all the fields
	forestBiomass.popupTemplate = forestBiomass.createPopupTemplate();
});
*/

const thisId = 'aa-hl-hazard';
export const hlHazard = {
	id: thisId,
	title:"Hazard",
	type:'group',
	description:`Hazard tab identifies potential impacts of pests and pathogens to forest ecosystems for the 2013 -2027 timeframe. This data can be useful to inform assessments of natural ecosystems, forest pest prevention and risk prone areas.`,
	items: [
		projectedLossPestsEastGroup,
		projectedLossEmeraldAshBorerGroup, 
		projectedLossRateSpeciesGroup,
		{layerId:`${thisId}-fc-cs-down-dead`, type: 'layer', item: compTbaLoss, meta:null},
		{layerId:`${thisId}-fc-cs-below-ground`, type: 'layer', item: compRisk, meta:null},
		{layerId:`${thisId}-fc-cs-above-ground`, type: 'layer', item: compPctTbaLoss, meta:null},
		
	]
}