import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

export const plEnvironHabitats = {id: `aa-pl-env-hab`, title:'Environmental Habitat', type:'group', 
items:[{
	layerKey: 'pineEnvHab-threatened',
	server: 'terrene',
	title: 'Threatened and endangered species habitat',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Environmental_Habitat',
	layerId: 0,
	parentDiv: 'pineEnvHab',
	defExp: '1=1',
	isFeatureLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}].map( (floodmapperObj) => {
	const thisLayer = new FeatureLayer({
		url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/${floodmapperObj.layerId}`
		, title: floodmapperObj.title
		, outFields: ['*']
		, popupEnabled: true
	});

	thisLayer.when(() => {
		//Create a template of literally all the fields
		thisLayer.popupTemplate = thisLayer.createPopupTemplate();
	});

	return {
		layerId: `aa-pl-env-hab-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: thisLayer,
		meta:null}
}),
description: `The map helps increase predictability for local planners, environmental commissions, and developers and helps facilitate local land use decisions to balance development and habitat protection.`
}
