import React, {useState, useEffect, 
	//useRef
	
} from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { loadMap } from '../../Flux/thunks';

import { CalciteAction, CalciteActionBar, CalciteActionGroup, 
	//CalciteBlock, CalciteBlockSection, 
	//CalciteModal,
	CalciteShellPanel, CalcitePanel, 
	CalciteShell,
	CalciteShellCenterRow,
	//CalciteButton, 
} from "@esri/calcite-components-react";

import Map from './Components/Map';
import BasemapGalleryPanel from './Panels/Core/BasemapGalleryPanel';
import LegendPanel from './Panels/Core/LegendPanel';
import AlertList from './Panels/Core/AlertList';

import MapLayersPanel from './Panels/Core/MapLayersPanel';
import ServerBrowserPanel from './Panels/Core/ServerBrowserPanel';
import SaveSharePrintPanel from './Panels/Core/SaveSharePrintPanel';
import FindLocationPanel from './Panels/Core/FindLocationPanel';

import SefeaLayersPanel from './Panels/Custom/SefeaLayersPanel';
//import AHPTool from './Panels/Custom/AHPTool';
import AHPPanel from './Panels/Custom/AHPTool/AHPPanel';

//import StockLayerControlPanel from './Panels/Custom/StockLayerControlPanel';
import LayerControlPanel from './Panels/Core/LayerControlPanel';
import DebugPanel from './Panels/Core/DebugPanel';
//import GraphingPanel from './Panels/Custom/GraphingPanel';
//import GraphCenterPanel from './Panels/Custom/GraphingPanel/GraphCenterPanel';
import FloatingCalciteTip from '../../Common/FloatingCalciteTip';
import AHPModal from './Panels/Custom/AHPTool/AHPModal';
import ScenarioModal from './Panels/Custom/ScenarioModal';
import SefeaIntroModal from './Panels/Custom/SefeaIntroModal';


const MapPage = ({ isMapLoading, isLayerLoading, isRasterLayerLoading, arcgisMap, getUrlOptions, startLoadingMap }) => {
	
	//In the tutorial, I didn't need to add the function into use effect
  	let { shareMapKey } = useParams();
	const [isAhpToolActive, setAhpToolActive] = useState(false);
	const [scenarioContentId, setScenarioContentId] = useState('');
	const [primaryToggle, SetPrimaryToggle] = useState(
		(getUrlOptions && getUrlOptions.isMinimal) ? false : true
	);
	const [auxToggle, SetAuxToggle] = useState(true);

	const [showPrimaryContent, SetShowPrimaryContent] = useState(
		(getUrlOptions && getUrlOptions.isMinimal ? 'legend' : null)
	);
	const [showAuxContent, SetShowAuxContent] = useState(null);


	const TogglePrimaryPanel = ( primaryContent ) => {
		
		//primary content already open
		if( showPrimaryContent === primaryContent ) {
			SetPrimaryToggle(true);
			SetShowPrimaryContent(null);
			return;
		}

		SetPrimaryToggle(false);
		SetShowPrimaryContent(primaryContent);
		return;
	}
	
	const ToggleAuxPanel = ( auxContent ) => {
		
		//primary content already open
		if( showAuxContent === auxContent ) {
			SetAuxToggle(true);
			SetShowAuxContent(null);
			return;
		}

		SetAuxToggle(false);
		SetShowAuxContent(auxContent);
		return;
	}

	//console.log('Is Minimal? ', getUrlOptions.isMinimal, ((getUrlOptions ? getUrlOptions.isMinimal : false ) || showPrimaryContent === 'legend'));

	useEffect( () => {
		startLoadingMap(shareMapKey);
	}, [startLoadingMap, shareMapKey]);

//CalciteActions can have loading="true" to show a loader.

	return ( 
		<CalciteShell>
			<CalciteShellPanel slot="primary-panel" position="start" widthScale="m" detached="true" detachedHeightScale="l" collapsed={primaryToggle} >
				<CalciteActionBar hidden={getUrlOptions ? getUrlOptions.isMinimal : null} slot="action-bar" expanded={true}>
					{ process.env.NODE_ENV === 'development' ?
						<CalciteActionGroup>
							<CalciteAction active={showPrimaryContent==='debug' || null} text="DEBUG PANEL" icon="debug" onclick={(_e) => { TogglePrimaryPanel('debug'); }}></CalciteAction>
						</CalciteActionGroup>
					: null}
					{/*
					onclick={(_e) => { 
							console.log('AHP Modal? ', isAhpToolActive);
							//TogglePrimaryPanel('ahp-tool'); 
							setAhpToolActive(true);
						}}
						
					*/}
					<CalciteActionGroup>
						<CalciteAction active={showPrimaryContent==='assessment-area' || null} text="Assessment Area" icon="layers" onclick={(_e) => { TogglePrimaryPanel('assessment-area'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='scenario-assessment' || null} text="Scenario Assessment of LU Change Potential" icon="collection" onclick={(_e) => { TogglePrimaryPanel('scenario-assessment'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='watershed-eco-health' || null} text="Watershed Ecological Health" icon="beaker" onclick={(_e) => { TogglePrimaryPanel('watershed-eco-health'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='boundary-layers' || null} text="Boundary Layers" icon="layer" onclick={(_e) => { TogglePrimaryPanel('boundary-layers'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='ahp-tool' || null} text="AHP Tool" icon="data-raster" onclick={(_e) => { TogglePrimaryPanel('ahp-tool'); }}></CalciteAction>
					</CalciteActionGroup>
					<CalciteActionGroup>
						{/*
						<CalciteAction active={showPrimaryContent==='map-layers' || null} text="Map Layers" icon="layers" onclick={(_e) => { TogglePrimaryPanel('map-layers'); }}></CalciteAction>
						*/}
						<CalciteAction active={showPrimaryContent==='server-browser' || null} text="Import Data" icon="data-magnifying-glass" onclick={(_e) => { TogglePrimaryPanel('server-browser'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='basemaps' || null} text="Basemap" icon="basemap" onclick={(_e) => { TogglePrimaryPanel('basemaps'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='find-location' || null} text="Find Location" icon="search" onclick={(_e) => { TogglePrimaryPanel('find-location'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='save' || null} text="Save / Share / Print" icon="save" onclick={(_e) => { TogglePrimaryPanel('save'); }}></CalciteAction>
						<CalciteAction active={showPrimaryContent==='legend' || null} text="Legend" icon="legend" onclick={(_e) => { TogglePrimaryPanel('legend'); }}></CalciteAction>
					</CalciteActionGroup>
				</CalciteActionBar>
				<CalcitePanel  
					
					dismissible={false}
					open={showPrimaryContent === 'debug'}
					style={{marginTop: '0', display:( showPrimaryContent === 'debug' ? 'flex' : 'none' )}}
					heading="DEBUG"
					summary="Random assortment of stuff.">
						<DebugPanel arcgisMap={arcgisMap} />
				</CalcitePanel>
				
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'assessment-area'}
					style={{marginTop: '0', display:( showPrimaryContent === 'assessment-area' ? 'flex' : 'none' )}}
					heading="Assessment Area"
					summary="Assessment area provides data on forest assessment areas including habitat, economic infrastructure, forest, hazards, hydrology/water, land use/land cover and treed/basal area. This tab is helpful to determine baseline forest, environmental and economical attributes.">
					<SefeaLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} useLayerSet={'assessment-area'} />
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'scenario-assessment'}
					style={{marginTop: '0', display:( showPrimaryContent === 'scenario-assessment' ? 'flex' : 'none' )}}
					heading="Scenario Assessment of LU Change Potential"
					>
					<SefeaLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} useLayerSet={'scenario-assessment'} OpenScenarioModal={(scenarioContentId) => {
						console.log('[OpenScenarioModal] I guess??????',scenarioContentId )
						setScenarioContentId(scenarioContentId);
					}} />
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'watershed-eco-health'}
					style={{marginTop: '0', display:( showPrimaryContent === 'watershed-eco-health' ? 'flex' : 'none' )}}
					heading="Watershed Ecological Health"
					summary="Watershed ecological health tool provides data on watershed health indicators.">
					<SefeaLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} useLayerSet={'watershed-eco-health'} />
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'boundary-layers'}
					style={{marginTop: '0', display:( showPrimaryContent === 'boundary-layers' ? 'flex' : 'none' )}}
					heading="Boundary Layers"
					summary="Provides data by administrative boundaries.">
					<SefeaLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} useLayerSet={'boundary-layers'} />
				</CalcitePanel>

				<CalcitePanel  
					open={showPrimaryContent === 'server-browser'}
					style={{marginTop: '0', display:( showPrimaryContent === 'server-browser' ? 'flex' : 'none' )}}
					heading="Import Data"
					summary="Browse layers online">
					
					<ServerBrowserPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} />
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'ahp-tool'}
					style={{marginTop: '0', display:( showPrimaryContent === 'ahp-tool' ? 'flex' : 'none' )}}
					heading="AHP Tool"
					summary="Analytical Hierarchy Process (AHP) with pairwise comparison.">
					<AHPPanel OpenModalTool={() => {
						setAhpToolActive(true);
					}} isMapLoading={isMapLoading} arcgisMap={arcgisMap} />
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'map-layers'}
					style={{marginTop: '0', display:( showPrimaryContent === 'map-layers' ? 'flex' : 'none' )}}
					heading="Map Layers"
					summary="Add layers to map here.">
					<MapLayersPanel isMapLoading={isMapLoading} arcgisMap={arcgisMap} />
				</CalcitePanel>

				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'basemaps'}
					style={{marginTop: '0', display:( showPrimaryContent === 'basemaps' ? 'flex' : 'none' )}}
					heading="Basemaps"
					summary="Select a basemap">
					<BasemapGalleryPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading}/>
					
				</CalcitePanel>
				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'find-location'}
					style={{marginTop: '0', display:( showPrimaryContent === 'find-location' ? 'flex' : 'none' )}}
					heading="Find Location"
					summary="Find a point on the map.">
					<FindLocationPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>


				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'save'}
					style={{marginTop: '0', display:( showPrimaryContent === 'save' ? 'flex' : 'none' )}}
					heading="Save / Share / Print"
					summary="Save, share, or print your map."
					loading={isMapLoading}
					>
					<SaveSharePrintPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>

				<CalcitePanel  
					dismissible={false}
					open={showPrimaryContent === 'legend'}
					style={{marginTop: '0', display:( showPrimaryContent === 'legend' ? 'flex' : 'none' )}}
					heading="Legend"
					summary="The Legend for the map.">
					<LegendPanel arcgisMap={arcgisMap} isMapLoading={isMapLoading} />
				</CalcitePanel>
			</CalciteShellPanel>
			<CalciteShellPanel 
				slot="contextual-panel" 
				position="end" 	
				widthScale="m" detached="true" detachedHeightScale="l" collapsed={auxToggle}
			>
				<CalciteActionBar slot="action-bar" position="end" theme="dark"  class="calcite-theme-dark"
				 hidden={getUrlOptions ? getUrlOptions.isMinimal : null}
				>
					<CalciteAction text="Layer Control" icon="map-contents" onclick={(_e) => { ToggleAuxPanel('layer-control'); }}></CalciteAction>
				</CalciteActionBar>
				<CalcitePanel 
					dismissible={false}
					loading={isMapLoading}
					open={showAuxContent === 'layer-control'}
					style={{marginTop: '0', display:( showAuxContent === 'layer-control' ? 'flex' : 'none' )}}
					heading="Layer Control"
					summary="Change your layer options here">
					<LayerControlPanel arcgisMap={arcgisMap} />
				</CalcitePanel>
			</CalciteShellPanel>

			<CalciteShellCenterRow slot="center-row">
				<Map  arcgisMap={arcgisMap} loading={isMapLoading || isLayerLoading} rasterLoading={isRasterLayerLoading} />
			</CalciteShellCenterRow>

			<AlertList />
			<FloatingCalciteTip />

			<AHPModal active={isAhpToolActive} CloseModal={() => {
				setAhpToolActive(false);
			} }/>

<ScenarioModal scenario={scenarioContentId} CloseModal={() => {
				setScenarioContentId('');
			}} />

			
			<SefeaIntroModal />

			

		</CalciteShell>

	
	
	);
}
/*

			<CalciteModal aria-labelledby="modal-title" active>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
</CalciteModal>
*/
//<Map view={view} webmap={webmap} />


//what state items should this component have access to
const mapStateToProps = (state) => ({
    //todos: state.todos,
	arcgisMap: state.arcgisMap,
    isMapLoading: state.isMapLoading,
    isLayerLoading: state.isLayerLoading,
    isRasterLayerLoading:  state.isRasterLayerLoading.length > 0,
    getUrlOptions: state.getUrlOptions
    //alertList: state.alertList
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	startLoadingMap: (shareMapKey) => {dispatch(loadMap(shareMapKey))},
	//onAlertClose: (key) => {dispatch(closeAlert(key))}
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
