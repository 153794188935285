//See `src\Pages\Map\Layers\ShellfishLayerExample\allLayersJson.js` for an example
//"id", "layerId", and "key" should be unique across all items.
//Typical structure for Layer.layerId will typically be `{parent group id}-{layerId}`
//Groups under other groups will typically have their ids be `{parent group id}-{current group id}
//Group can be considered the folder.
/*
layerCompilation: Array<Group|Layer>

Group: {
	id:string, //Unique group id
	title:string,
	type:'group',
	items:Array<Group|Layer>,
	ignoreInMapLayersPanel: bool //Optional, specifically hides in mapLayersPanel
},

Layer: {
	layerId: string,
	type: 'layer',
	item: Object<subtype of esri-layers-layer>, // {@arcgis/core/layers/} * or https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html
	containsSublayers: Object<Sublayers>|null,
	meta: Object<Meta>|null
}

Sublayers: {
	main: {
		key: string, 
		optionTitle: string
	},
	useSublayer: string, //Current layer option key (typically {this.main.key})
	sublayerItems: Array<Sublayer>
}
Sublayer:{
	key: string,
	optionTitle: string,
	layerId: string,
	type: 'layer',
	item: Object<subtype of esri-layers-layer>, // https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html
	hideInMenu: true,
	meta: Object<Meta>|null
}

Meta: {
	layerTitle: string, 
	layerDescription: string, 
	mapService: string, 
	authoritiveSource: string
}
*/


import { assessmentArea, scenarioAssessmentLuChange, boundaryLayers, wsLayers } from "./sefea";

//Must export as layerCompilation;
export const layerCompilation = [
	assessmentArea
	,scenarioAssessmentLuChange
	,boundaryLayers 
	,wsLayers

	//Do not change the ID of this group
	//This is used by import data / server browser panel for custom layers
	,{
		id:'custom',
		title:"Your Imported Data Layers",
		type:'group',
		items:[]
	}
];



