import React, { 
	//useRef, 
	useEffect, useState,
	//useState 
} from "react";
import { connect } from "react-redux";

import { CalciteBlock, CalciteButton, CalciteInput
	//, CalciteNotice 
} from "@esri/calcite-components-react";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import {GenerateUrlOptions, CreateUrlFromUrlOptions} from '../../../../../Logic/UrlOptionsLogic';

const TemporaryMapBlock = ({arcgisMap}) => {
	const [temporaryUrl, SetTemporaryUrl] = useState('');

	useEffect(() => {
		if( arcgisMap.map  ) {
			
			
			watchUtils.whenFalse(arcgisMap, "updating", () => {
				const myUrlOpts = GenerateUrlOptions(arcgisMap);
				SetTemporaryUrl(CreateUrlFromUrlOptions(myUrlOpts));
			});
			watchUtils.whenTrue(arcgisMap, "stationary", () => {
				//console.log('Map is now stationary.');
				const myUrlOpts = GenerateUrlOptions(arcgisMap);
				SetTemporaryUrl(CreateUrlFromUrlOptions(myUrlOpts));
			});
			/*
			watchUtils.watch(arcgisMap.map.basemap, "id", () => {
				
				//console.log('Map is now stationary.'); 
				const myUrlOpts = GenerateUrlOptions(arcgisMap);
				SetTemporaryUrl(CreateUrlFromUrlOptions(myUrlOpts));
			});*/
		}
	}, [arcgisMap, SetTemporaryUrl]);


	return (
		<CalciteBlock 
		heading="Temporary Map"
		summary="Get a temporary URL to quickly share."
		collapsible={true}
		>
			{/*
			<CalciteNotice scale="s" color="yellow" active={true} icon="exclamation-mark-triangle-f">
				<div slot="title">{"Imported Data Layers"}</div>
				<div slot="message">{'Layer reloading or saving from the `Import Data` Module is not implemented yet.'}</div>
			</CalciteNotice>
			*/}
			URL:
			<CalciteInput readOnly={true} type="textarea" label="Temporary URL:" value={temporaryUrl}></CalciteInput>
			<p><em>Use this link if you’re not ready to make your options a permanent link.</em></p>
			<CalciteButton href={temporaryUrl} target={'_blank'} scale="m" color="blue" iconStart="link" onMouseOver={(_e)=>{
				//Juuuuust in case 
				const myUrlOpts = GenerateUrlOptions(arcgisMap);
				console.log({myUrlOpts, temporaryUrl});
				SetTemporaryUrl(CreateUrlFromUrlOptions(myUrlOpts));
			}}>Try This Link</CalciteButton>&nbsp;
			<CalciteButton href={`${temporaryUrl}&minimal`} target={'_blank'} scale="m" color="neutral" iconStart="content-minimal"
				disabled={null}
			>Try Minimal View</CalciteButton>

		</CalciteBlock>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryMapBlock);
