import React
,
{ 
	//useRef, 
	useEffect,
	useState 
} 
from "react";
import { connect } from "react-redux";


//import LayerList from "@arcgis/core/widgets/LayerList";
//import { layerCompilation } from "./Layers/allLayersJson";
/*
import { CalciteFlow, CalcitePanel, 
	CalciteButton,
	CalciteAction,
	CalcitePickList,
	CalcitePickListGroup,
	CalcitePickListItem,
	//CalcitePopoverManager,
	//CalcitePopover
} from "@esri/calcite-components-react";
import {loadLayer, removeLayer} from '../../../../Flux/thunks';
import { updateTip } from "../../../../Flux/actions";
*/
import {

//CalciteNotice,
CalciteFlow, 
CalciteButton,

} from "@esri/calcite-components-react";

import {
	//AHPVariablesByRegion,
	AHPVariablesByAnalysisClass

} from './logic';

import AHPIntro from "./AHPIntro";
import AHPAnalysisClass from './AHPAnalysisClass';
import AHPVariables from "./AHPVariables";
import AHPMatrix from "./AHPMatrix";
import AHPCalculator from "./AHPCalculator";


/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPTool = ({CloseModal}) => {

	//We need
	const [geographicArea, SetGeographicArea] = useState(''); //region
	const [analysisClass, SetAnalysisClass] = useState(''); //analysis Class
	const [selectedVariableList, SetSelectedVariableList] = useState('');
	const [ratingsList, SetRatingsList] = useState([]);
	
	//const [useRasterWeights, SetRasterWeights] = useState(null);


	const [panels, UpdatePanels] = useState([]);
	

	const RemoveServicePanel = (event) => {
		console.log('Removing Panel: ', event);

		console.log(panels);
		//event.stopPropagation();
		//UpdatePanels( panels.slice(0,-1) );
	}

	
	const SetVariableList = (myVariableList) => {
		console.log('Setting variable list... opening matrix.', myVariableList);
		console.log('My Current panels???????', panels);
		SetSelectedVariableList(myVariableList);

		UpdatePanels([
			<AHPMatrix selectedVariableList={selectedVariableList} RemoveServicePanel={(e) => {
				RemoveServicePanel(e)
			}} />,
			...panels
		]);
	};

	
	useEffect( () => {
		console.log('Panels changed...', panels);
	}, [panels] );

	useEffect( () => {
		console.log('geographicArea changed...', geographicArea);
	}, [geographicArea] );
	useEffect( () => {
		console.log('analysisClass changed...', analysisClass, AHPVariablesByAnalysisClass[analysisClass]);
	}, [analysisClass] );
	
/*
	const NextStep = () => {
		UpdatePanels(
			[...panels,
				<AHPVariables variableList={AHPVariablesByRegion[geographicArea]} />
			]
		);
	}
*/
/*
	useEffect( () => {
		console.log('Geographic Area updated: ', geographicArea);

		if( geographicArea !== null ) {
			UpdatePanels(
				[...panels,
					<AHPVariables variableList={AHPVariablesByRegion[geographicArea]} />
				]
			);
		}
	}, [panels, geographicArea] );
	*/

	const ResetTool = () => {
		SetGeographicArea('');
		SetAnalysisClass('');
		SetSelectedVariableList('');
		SetRatingsList([]);
	}

	return (<>
		<CalciteFlow>
			<AHPIntro UpdateGeographicArea={(myGeographicArea) =>{ 
				console.log('AHP Updating: ', myGeographicArea);
				SetGeographicArea(myGeographicArea);
			}} />
			{
				geographicArea? 
				<AHPAnalysisClass geographicArea={geographicArea} 
					SetAnalysisClass={(myVariableList) =>{ 
						console.log('AHP Updating: ', myVariableList);
						SetAnalysisClass(myVariableList);
					}}
					
					RemoveServicePanel={(e) => {
						//RemoveServicePanel(e)
						console.log(e);
						e.stopPropagation();
						SetGeographicArea('');
						SetAnalysisClass('');
					}}
				/>
				: null
			}
			{
				analysisClass ? 
				<AHPVariables geographicArea={geographicArea} analysisClass={analysisClass} variableList={
					//needs to be updated
					//AHPVariablesByRegion[geographicArea] ? 
					//AHPVariablesByRegion[geographicArea].variables 
					AHPVariablesByAnalysisClass[analysisClass] ?
					AHPVariablesByAnalysisClass[analysisClass].variables
					: null
				} 
					SetVariableList={(myVariableList) =>{ 
						console.log('AHP Updating: ', myVariableList);
						SetVariableList(myVariableList);
					}}
					
					RemoveServicePanel={(e) => {
						//RemoveServicePanel(e)
						console.log(e);
						e.stopPropagation();
						SetAnalysisClass('');
					}}
				/>
				: null
			}
			{
				selectedVariableList.length > 0 
				? <AHPMatrix selectedVariableList={selectedVariableList} 
					SetRatingsList={ (myRatingsList) => {
						SetRatingsList(myRatingsList);
					}}
					RemoveServicePanel={(e) => {
						//RemoveServicePanel(e)
						console.log(e);
						e.stopPropagation();
						SetVariableList([]);
					}}
				/>
				: null
			}
			{
				ratingsList.length > 0 
				? <AHPCalculator ratingsList={ratingsList} 

					geographicArea={geographicArea} analysisClass={analysisClass} 
					CloseModal={CloseModal}
					RemoveServicePanel={(e) => {
						//RemoveServicePanel(e)
						console.log(e);
						e.stopPropagation();
						SetRatingsList([]);
					}}
				/>
				: null
			}
			
		</CalciteFlow>


		<CalciteButton
			width={"full"}
			onClick={(_e) => { 
				ResetTool();
			}}
			iconStart="reset"
			color="neutral"
		>
			Restart Tool
		</CalciteButton>

		
		
	</>);
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPTool);

/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/