import { hlConnectingHabitats } from "./connectingHabitats";
import { hlEconomicInfra } from "./economicInfrastructure";
import { hlForest } from "./forest";
import { hlHazard } from "./hazards";
import { hlHydrologyWater } from "./hydrologyWater";
import { hlLulc } from "./landUseLandCover";
import { hlTreeBasalArea } from "./treedBasalArea";
import { hlPlanning } from "./planning";
const assessAreaHighlands = [
	hlConnectingHabitats
	, hlEconomicInfra
	, hlForest
	, hlHazard
	, hlHydrologyWater
	, hlLulc 
	, hlTreeBasalArea
	, hlPlanning
];
export default assessAreaHighlands;