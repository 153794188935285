import React, { useRef, 
	useEffect,
	//useState 
} from "react";
import { connect } from "react-redux";

import Print from "@arcgis/core/widgets/Print";
import { CalciteBlock } from "@esri/calcite-components-react";


const PrintMapBlock = ({isMapLoading, arcgisMap}) => {

	const printContainer = useRef(null);

	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (printContainer.current && !printContainer.current.children.length && arcgisMap.map) {
			arcgisMap.when( () => {
				new Print({
					view: arcgisMap,
					printServiceUrl: `${process.env.REACT_APP_PRINT_SERVICE_URL || "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"}`,
					container: printContainer.current,
					allowedLayouts: [
					  "letter-ansi-a-landscape",
					  "letter-ansi-a-portrait",
					  "tabloid-ansi-b-landscape",
					  "tabloid-ansi-b-portrait",
					  // "a3-landscape",
					  // "a3-portrait",
					  // "a4-landscape",
					  // "a4-portrait",
					],
				  }
				  );
			});

		}
	}, [arcgisMap]);


	return (
		<CalciteBlock
		heading="Print Your Map"
		summary="Print your map into a format of your choice. See Notes and Limitations below."
		collapsible={true}
		
		>	
		<p style={{margin:0,padding:0,marginBottom:'1rem'}}><strong>Notes / Limitations on Print:</strong></p>
		<ul>
			<li>Some basemaps may cause your print to fail.</li>
			<li>Please do not add a space to the title.</li>
			<li>If you are trying to print out a raster, the raster may not appear on the map. You should save the map above and store the link somewhere until a fix is made to allow Imagery Layers to be able to print.</li>
		</ul>
			<div ref={printContainer}></div>
		</CalciteBlock>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintMapBlock);
