import React
,
{ 
	useState
} 
from "react";
import { connect } from "react-redux";

import {
	CalciteIcon,
	CalcitePanel,
	CalciteButton,
	CalciteRadioGroup,
	CalciteRadioGroupItem

} from "@esri/calcite-components-react";

const AHP_TD_LABEL_CLASS = {
	fontWeight: 'bold',
	textAlign: 'center'
};


/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPMatrix = ({selectedVariableList, SetRatingsList, RemoveServicePanel}) => {

 /*
	console.log(
		'[AHP Intro] Current Region: ', 
		selectedRegion
	);
	*/
	const [matrixCombos, SetMatrixCombo] = useState(selectedVariableList
		.map( ( varInfo, currentIndex ) => {
			const myCombos = [];
			for( let i=currentIndex+1; i<selectedVariableList.length;i++) {
				myCombos.push({
					firstItem: varInfo,
					secondItem: selectedVariableList[i],
					value: null
				});
			}
			return myCombos;
		}).flat()
		/*
		.reduce(
			(previousValue, currentValue, currentIndex, arrayList) => {

				for( let i=currentIndex+1; i<arrayList.length;i++) {
					previousValue.concat({
						firstItem: currentValue,
						secondItem: arrayList[i]
					})
				}
				return previousValue;
			},
			[] 
		)
		*/
	);

	const ConfirmSelection = () => {
		/*
		SetVariableList(pickListVars.filter( (varItem) => {
			return varItem.selected;
		}));
		*/
		SetRatingsList(matrixCombos);
	}

	const UpdateKeyValues = (key1, key2, setValueTo) => {
		//console.log('[AHPMatrix - UpdateKeyValues]', key1, key2, setValueTo);
		const newMatrixCombo = matrixCombos
		.map( ( varInfo ) => {

			if( varInfo.firstItem.key === key1 && varInfo.secondItem.key === key2 ) {
				varInfo.value = setValueTo;
			}

			return varInfo;
		});


		SetMatrixCombo(newMatrixCombo);
	};

	//console.log('Matrix val? ', matrixCombos);

	const allRated = matrixCombos ? matrixCombos.reduce(
		(previousValue, currentValue) => {
			if( currentValue.value !== null ) {
				return previousValue+1;
			}
			return previousValue;
		},
		0 
	) === matrixCombos.length : false;

	if(!matrixCombos ||matrixCombos.length === 0 ) {
		return null;
	}
	return (<>
		
		<CalcitePanel
				key={'select-matrix'}
				heading={'Matrix'}
				summary={'Select ratings...'}
				onCalcitePanelBackClick={RemoveServicePanel}
			>
				<div className="content-area">
					<table className="table" style={{marginTop:'1rem'}}>
						<thead>
							<tr>
								<th className="ahp-tool-th th-33"></th>
								<th className="ahp-tool-th label"><span>Strongly Prefer</span></th>
								<th className="ahp-tool-th label"><span>Prefer</span></th>
								<th className="ahp-tool-th label"><span>Equal Preference</span></th>
								<th className="ahp-tool-th label"><span>Prefer</span></th>
								<th className="ahp-tool-th label"><span>Strongly Prefer</span></th>
								<th className="ahp-tool-th th-33"></th>
							</tr>
						</thead>
						<tbody>
						{
							matrixCombos.map( (varItem) => { 
								return <tr>
									<td style={AHP_TD_LABEL_CLASS}>{varItem.firstItem.label}</td>
									
									<td colSpan={5}>					
										<CalciteRadioGroup scale="s" width="full" onCalciteRadioGroupChange={ (e) => {
											const key1 = varItem.firstItem.key;
											const key2 = varItem.secondItem.key;
											//console.log(e);
											UpdateKeyValues(key1, key2, e.detail);
										}}>
											<CalciteRadioGroupItem value="6"> <CalciteIcon icon="chevrons-left"></CalciteIcon></CalciteRadioGroupItem>
											<CalciteRadioGroupItem value="3"> <CalciteIcon icon="chevron-left"></CalciteIcon></CalciteRadioGroupItem>
											<CalciteRadioGroupItem value="1"> <CalciteIcon icon="circle"></CalciteIcon></CalciteRadioGroupItem>
											<CalciteRadioGroupItem value="-3"> <CalciteIcon icon="chevron-right"></CalciteIcon></CalciteRadioGroupItem>
											<CalciteRadioGroupItem value="-6"> <CalciteIcon icon="chevrons-right"></CalciteIcon></CalciteRadioGroupItem>
										</CalciteRadioGroup>
									</td>
									<td style={AHP_TD_LABEL_CLASS}>{varItem.secondItem.label}</td>
								</tr>
							})
						}
						</tbody>
					</table>
					
					<div style={{marginTop:'1em',marginBottom:'1em'}}>
						<CalciteButton width="full" disabled={!allRated || undefined} onClick={ConfirmSelection}>Confirm Ratings</CalciteButton>
					</div>
				</div>
			</CalcitePanel>

	</>);
};


/* 

<div>					
<CalciteRadioGroup scale="s" width="full">
		<CalciteRadioGroupItem value="1"> <CalciteIcon icon="chevrons-left"></CalciteIcon></CalciteRadioGroupItem>
		<CalciteRadioGroupItem value="2"> <CalciteIcon icon="chevron-left"></CalciteIcon></CalciteRadioGroupItem>
		<CalciteRadioGroupItem value="3"> <CalciteIcon icon="circle"></CalciteIcon></CalciteRadioGroupItem>
		<CalciteRadioGroupItem value="4"> <CalciteIcon icon="chevron-right"></CalciteIcon></CalciteRadioGroupItem>
		<CalciteRadioGroupItem value="5"> <CalciteIcon icon="chevrons-right"></CalciteIcon></CalciteRadioGroupItem>
	</CalciteRadioGroup>
</div>
					<div>
						<CalciteSlider ticks={1} max={5} min={1} />

					</div>



					<ul>{
						matrixCombos.map( (varItem) => { 
							return <li>{varItem.firstItem.label} vs {varItem.secondItem.label}</li>
						})
					}
					</ul>
*/

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPMatrix);


/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/