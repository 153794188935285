import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const wsAquatic = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Integrity_layers/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Aquatic integrity score (%)'
	, outFields: ['*']
	, popupEnabled: true
});
/*
wsAquatic.when(() => {
	//Create a template of literally all the fields
	wsAquatic.popupTemplate = wsAquatic.createPopupTemplate();
});*/


const wsLandscape = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Integrity_layers/MapServer/"
	, sublayers: [{id:1}]
	, title: 'Landscape integrity score (%)'
	, outFields: ['*']
	, popupEnabled: true
});
/*
wsLandscape.when(() => {
	//Create a template of literally all the fields
	wsLandscape.popupTemplate = wsLandscape.createPopupTemplate();
});*/

const wsEcological = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Integrity_layers/MapServer/"
	, sublayers: [{id:2}]
	, title: 'Ecological integrity score (%)'
	, outFields: ['*']
	, popupEnabled: true
});
/*
wsEcological.when(() => {
	//Create a template of literally all the fields
	wsEcological.popupTemplate = wsEcological.createPopupTemplate();
});*/


const wsDrainage = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Integrity_layers/MapServer/"
	, sublayers: [{id:3}]
	, title: 'Wetland drainage integrity score (%)'
	, outFields: ['*']
	, popupEnabled: true
});
/*
wsDrainage.when(() => {
	//Create a template of literally all the fields
	wsDrainage.popupTemplate = wsDrainage.createPopupTemplate();
});
*/
const wsIntegrityScore = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Integrity_layers/MapServer/"
	, sublayers: [{id:4}]
	, title: 'Wetland integrity score (%)'
	, outFields: ['*']
	, popupEnabled: true
});
/*
wsIntegrityScore.when(() => {
	//Create a template of literally all the fields
	wsIntegrityScore.popupTemplate = wsIntegrityScore.createPopupTemplate();
});
*/

const wsSurfaceWater = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/0"
	, title: 'Surface Water Quality Standards'
	, outFields: ['*']
	, popupEnabled: true
});
wsSurfaceWater.when(() => {
	//Create a template of literally all the fields
	wsSurfaceWater.popupTemplate = wsSurfaceWater.createPopupTemplate();
});

const wsGenAquaLifeTurb = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/1"
	, title: 'General Aquatic Life Use Assessment Turbidity'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaLifeTurb.when(() => {
	//Create a template of literally all the fields
	wsGenAquaLifeTurb.popupTemplate = wsGenAquaLifeTurb.createPopupTemplate();
});

const wsGenAquaTss = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/2"
	, title: 'General Aquatic Life Use Assessment TSS'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaTss.when(() => {
	//Create a template of literally all the fields
	wsGenAquaTss.popupTemplate = wsGenAquaTss.createPopupTemplate();
});

const wsGenAquaTp = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/3"
	, title: 'General Aquatic Life Use Assessment TP'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaTp.when(() => {
	//Create a template of literally all the fields
	wsGenAquaTp.popupTemplate = wsGenAquaTp.createPopupTemplate();
});
const wsGenAquaPH = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/4"
	, title: 'General Aquatic Life Use Assessment pH'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaPH.when(() => {
	//Create a template of literally all the fields
	wsGenAquaPH.popupTemplate = wsGenAquaPH.createPopupTemplate();
});
const wsGenAquaDO = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/5"
	, title: 'General Aquatic Life Use Assessment DO'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaDO.when(() => {
	//Create a template of literally all the fields
	wsGenAquaDO.popupTemplate = wsGenAquaDO.createPopupTemplate();
});

const wsGenAquaBio = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/6"
	, title: 'General Aquatic Life Use Assessment Biological'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaBio.when(() => {
	//Create a template of literally all the fields
	wsGenAquaBio.popupTemplate = wsGenAquaBio.createPopupTemplate();
});

const wsGenAquaLife = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/7"
	, title: 'General Aquatic Life Use Assessment Aquatic Life'
	, outFields: ['*']
	, popupEnabled: true
});
wsGenAquaLife.when(() => {
	//Create a template of literally all the fields
	wsGenAquaLife.popupTemplate = wsGenAquaLife.createPopupTemplate();
});


const wsWMA = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/8"
	, title: 'Watershed management areas'
	, outFields: ['*']
	, popupEnabled: true
});
wsWMA.when(() => {
	//Create a template of literally all the fields
	wsWMA.popupTemplate = wsWMA.createPopupTemplate();
});


const wsHuc14 = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Watershed_Ecological_Health/MapServer/9"
	, title: 'Watershed HUC14'
	, outFields: ['*']
	, popupEnabled: true
});
wsHuc14.when(() => {
	//Create a template of literally all the fields
	wsHuc14.popupTemplate = wsHuc14.createPopupTemplate();
});


export const wsPinelandEcoHealth = {
	id: 'ws-pl-eco',
	title:"Pinelands Region",
	type:'group',
	
	items: [
		{layerId:'ws-pl-eco-aquatic-score', type: 'layer', item: wsAquatic, meta:null},
		{layerId:'ws-pl-eco-landscape-score', type: 'layer', item: wsLandscape, meta:null},
		{layerId:'ws-pl-eco-ecological-score', type: 'layer', item: wsEcological, meta:null},
		{layerId:'ws-pl-eco-drainage-score', type: 'layer', item: wsDrainage, meta:null},
		{layerId:'ws-pl-eco-integrity-score', type: 'layer', item: wsIntegrityScore, meta:null},
		{layerId:'ws-pl-eco-surface-water', type: 'layer', item: wsSurfaceWater, meta:null},
		
		{layerId:'ws-pl-eco-gen-aqua-turbidity', type: 'layer', item: wsGenAquaLifeTurb, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-tss', type: 'layer', item: wsGenAquaTss, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-tp', type: 'layer', item: wsGenAquaTp, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-ph', type: 'layer', item: wsGenAquaPH, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-do', type: 'layer', item: wsGenAquaDO, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-bio', type: 'layer', item: wsGenAquaBio, meta:null},
		{layerId:'ws-pl-eco-gen-aqua-life', type: 'layer', item: wsGenAquaLife, meta:null},
		{layerId:'ws-pl-eco-wma', type: 'layer', item: wsWMA, meta:null},
		{layerId:'ws-pl-eco-huc14', type: 'layer', item: wsHuc14, meta:null},

	]
}

