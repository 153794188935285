import RasterFunction from "@arcgis/core/layers/support/RasterFunction";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
/**
 * 
 * @param {string} baseRasterServiceURL DO NOT INCLUDE LAST SLASH PLEASE FOR NOW
 * @param {array} rasterArray an array of raster object items with format {url:string, format:string}. 
 * Include the base raster as it's own raster element as the following: {url: "$$", format: null, multiplier: number}
 * @param {string} rasterArray[].url the url for an image server, See extra information 1 below for an example
 * 	we can expand this later to be able to have it be sent images from non-imageservers, but for now, specifically want imageserver.
 * Please do not add the last slash for now.
 * @param {string} rasterArray[].format the format of the image, 
 * @param {number} rasterArray[].multiplier what to multiply this raster by.
 * 
 * @returns {Promise} promise to return a new RasterFunction
 * @description 
 * See also [GenerateRasterExampleFunction] below for an example how to use function.
 * Extra information 1: 
 * We will grab the bbox from the [baseRasterServiceURL] parameter, so please just send 
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage`
 * 
 * Full example of image url that will be generated/implied generated . bbox is the really important variable here
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage?
 * bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342
 * &bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`
 * 
 * https://developers.arcgis.com/documentation/common-data-types/raster-function-objects.htm
 * 
 */
export const  GenerateRaster_triplet = async (
	baseRasterServiceURL = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer`, 
	rasterArray=[]) => {
	
	return fetch( `${baseRasterServiceURL}/queryBoundary?outSR=&f=pjson`)
	.then( (response) => {
		return response.json();
	})
	.then ( (respJson) => {
		console.log('Json Response: ', respJson);
		const bbox = `${respJson.shape.xmin},${respJson.shape.ymin},${respJson.shape.xmax},${respJson.shape.ymax}`;
		const bboxSR = `${respJson.shape.spatialReference.wkid}`;

		const inputNames = []; //arbiturary names for the function to work.
		const rasters = []; //objects of URL and format
		const expressionArray = []; //joined by addition

		//using foreach instead of map for simplicity's sake
		//&bboxSR=${bboxSR}
		rasterArray.forEach( (rasterItem,idx) => {
			const rasterName = `raster${idx}`;
			if(rasterItem.url==="$$" ) {
				rasters.push("$$");
			}else{
				rasters.push({
					
					url: `${rasterItem.url}?bbox=${bbox}&bboxSR=${bboxSR}&f=image&format=tiff`,
					format: rasterItem.format
				});
			}
			inputNames.push(rasterName);
			expressionArray.push( `(${rasterName} * ${rasterItem.multiplier})` )
		});	



		return new RasterFunction({
			functionName: "RasterCalculator",
			functionArguments: {
				"InputNames": inputNames,
				"expression": expressionArray.join('+'),
				"rasters" :rasters
			}
		});


	}) 
	.catch( (failedResponse) => {
		return failedResponse;
	})


}

export const  GenerateRaster = async (
	baseRasterServiceURL = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Highlands_AHP/agri_priority/ImageServer`, 
	rasterArray=[]) => {
	

		return new Promise((resolve, reject) => {

			resolve( new RasterFunction({
				functionName: "RasterCalculator",
				functionArguments: {
					"InputNames": [
						"agri_priority",
						"Confirmed_Vernal_Pools_300m_Buffer",
						//"dist_train_UPD"
					],
					//"expression": "(dist_high*.096)+(dist_roads*.251)+(dist_train_UPD*.653)",
					"expression": "(agri_priority*1)+(Confirmed_Vernal_Pools_300m_Buffer*1)",
					"rasters" :['$$','$2'],
					
				},
				outputPixelType: "unknown"
			}) );

		})
		/*
	return fetch( `${baseRasterServiceURL}/queryBoundary?outSR=&f=pjson`)
	.then( (response) => {
		return response.json();
	})
	.then ( (respJson) => {
		console.log('Json Response: ', respJson);
		const bbox = `${respJson.shape.xmin},${respJson.shape.ymin},${respJson.shape.xmax},${respJson.shape.ymax}`;
		const bboxSR = `${respJson.shape.spatialReference.wkid}`;

		const inputNames = []; //arbiturary names for the function to work.
		const rasters = []; //objects of URL and format
		const expressionArray = []; //joined by addition

		//using foreach instead of map for simplicity's sake
		//&bboxSR=${bboxSR}
		rasterArray.forEach( (rasterItem,idx) => {
			const rasterName = `raster${idx}`;
			if(rasterItem.url==="$$" ) {
				rasters.push("$$");
			}else{
				rasters.push({
					
					url: `${rasterItem.url}?bbox=${bbox}&bboxSR=${bboxSR}&f=image&format=tiff`,
					format: rasterItem.format
				});
			}
			inputNames.push(rasterName);
			expressionArray.push( `(${rasterName} * ${rasterItem.multiplier})` )
		});	



		return new RasterFunction({
			functionName: "RasterCalculator",
			functionArguments: {
				"InputNames": inputNames,
				"expression": expressionArray.join('+'),
				"rasters" :rasters
			}
		});


	}) 
	.catch( (failedResponse) => {
		return failedResponse;
	})
	*/

}


export const GenerateRasterExampleFunction = async () => {
	//These specific multipliers are based on the AVP power point where it says "[vis] * (0.653) + [elev] * (0.251) + [asp] * (0.096)]"
	const baseRasterServiceURL = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer`;
	const rasterArray = [
		//road "asp" - called directly from the service, so we don't need to pass the url. I guess in theroy we could, but let's not.
		{url: '$$', format: null, multiplier: .096},
		//train: "vis"
		{url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage', format: 'tiff', multiplier: .653},
		//high density areas: "elev"
		{url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_high/ImageServer/exportImage', format: 'tiff', multiplier: .251}
	];

	//Max value should be 42.65
	//Min value should be 1

	//Generate raster returns a promise of a rendering rule. You can apply a coloring ramp to it if you want and send that as the rendering rule instead.
	GenerateRaster(baseRasterServiceURL,rasterArray)
	.then( (myRenderingRule ) => {

		//Since RasterFunction items can be chained, here's where you can take [myRenderingRule] and use it in another raster function
		//Example below. 

/*		const colorMapper = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
				"ColorrampName": "Blue Bright",
				"Raster": myRenderingRule
			},
			variableName: "Raster"
		});
*/
		const FINAL_RASTER = new ImageryLayer({
			url: baseRasterServiceURL,
			// apply the most recent raster function to the chain
			renderingRule:myRenderingRule //colorMapper  
			//if we wanted to do more with the returned raster item pass colorMapper, but something seems to be wrong
				//as it changes the HIGH to some 32 bit unsigned int, so I'm sure a little more research will be needed.
			//mosaicRule: mosaicRule
			, pixelType: "u8"
		});
	
		console.log('This is the layer to add onto the map: ', FINAL_RASTER);
	})
	.catch( (_failure) => {
		console.error(_failure);
	});

}