
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";






export const ifp = 
{
	id:'sa-hl-ifp',
	title:"Infrastructure Focused Priority",
	showScenario: 'generalInfraPriority',
	type:'group',
	items:[
		{ id:'sa-hl-ifp',title:"Equal priority of each infrastructure focused factors", type:'group', 
		showScenario:'highlandsInfraEqual',
		items:
			[
			{layerId:'sa-hl-ifp-layer-1', type: 'layer', item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Infrastructure_Focused_Priority/MapServer/`
				, sublayers: [{id:1}]
				, title: 'Equal priority of each infrastructure focused factors'}), meta:null},
		] },
		{ id:'sa-hl-ifp',title:"Infrastructure Focused Priority", type:'group',
		showScenario:'highlandsInfraInfraPrio', items:
			[
			{layerId:'sa-hl-ifp-layer-2', type: 'layer', item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Infrastructure_Focused_Priority/MapServer/`
				, sublayers: [{id:2}]
				, title: 'Infrastructure Focused Priority'}), meta:null},
		] },
		{ id:'sa-hl-ifp',title:"Medium Agricultural Priority", type:'group',
		showScenario:'highlandsInfraMedium', items:
			[
			{layerId:'sa-hl-ifp-layer-3', type: 'layer', item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Infrastructure_Focused_Priority/MapServer/`
				, sublayers: [{id:3}]
				, title: 'Medium Agricultural Priority'}), meta:null},
		] },
		{ id:'sa-hl-ifp',title:"High Agricultural Priority", 
		showScenario:'highlandsInfraHigh',type:'group', items:
			[
			{layerId:'sa-hl-ifp-layer-4', type: 'layer', item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Infrastructure_Focused_Priority/MapServer/`
				, sublayers: [{id:4}]
				, title: 'High Agricultural Priority'}), meta:null},
		] },
		{ id:'sa-hl-ifp',title:"Residential Density Priority", type:'group', 
		showScenario:'highlandsInfraResi',items:
			[
			{layerId:'sa-hl-ifp-layer-5', type: 'layer', item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Infrastructure_Focused_Priority/MapServer/`
				, sublayers: [{id:5}]
				, title: 'Residential Density Priority'}), meta:null},
		] },
		{
			id: 'sa-hl-alcp-df',
			type: 'group-set',
			//type: 'group',
			title: 'Driving Factors',
			items: [
				{
					layerKey: 'saluc-ipf-hdra',
					server: 'terrene',
					title: 'Distance to High Density Residential Areas',
					folder: 'SAFEA_Highlands',
					mapServer: 'Infrastructure_Focused_Priority',
					layerId: 8,
					parentDiv: 'ifpDf',
					defExp: '1=1',
					isRasterLayer: true,
					hasFeatureServer: false,
					detailsText: ''
				}, {
					layerKey: 'saluc-ipf-mr',
					server: 'terrene',
					title: 'Distance to Major Roads',
					folder: 'SAFEA_Highlands',
					mapServer: 'Infrastructure_Focused_Priority',
					layerId: 9,
					parentDiv: 'ifpDf',
					defExp: '1=1',
					isRasterLayer: true,
					hasFeatureServer: false,
					detailsText: ''
				}, {
					layerKey: 'saluc-ipf-ts',
					server: 'terrene',
					title: 'Distance to Train Stations',
					folder: 'SAFEA_Highlands',
					mapServer: 'Infrastructure_Focused_Priority',
					layerId: 10,
					parentDiv: 'ifpDf',
					defExp: '1=1',
					isRasterLayer: true,
					hasFeatureServer: false,
					detailsText: ''
				}, {
					layerKey: 'saluc-ipf-lulc15',
					server: 'terrene',
					title: 'Land Use / Land Cover 2015',
					folder: 'SAFEA_Highlands',
					mapServer: 'Infrastructure_Focused_Priority',
					layerId: 11,
					parentDiv: 'ifpDf',
					defExp: '1=1',
					isRasterLayer: true,
					hasFeatureServer: false,
					detailsText: ''
				}, {
					layerKey: 'saluc-ipf-sewer',
					server: 'terrene',
					title: 'Sewer Service Areas',
					folder: 'SAFEA_Highlands',
					mapServer: 'Infrastructure_Focused_Priority',
					layerId: 12,
					parentDiv: 'ifpDf',
					defExp: '1=1',
					isRasterLayer: true,
					hasFeatureServer: false,
					detailsText: ''
				}
			].map( (floodmapperObj) => {
				return {
					layerId: `sa-hl-ipf-df-${floodmapperObj.layerKey}`,
					type: 'layer',
					item: new MapImageLayer({
						url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
						, sublayers: [{id:floodmapperObj.layerId}]
						, title: floodmapperObj.title
					}),
					meta:null}
			})
		}

		
	],
	//description: `Infrastructure focused priority has several infrastructure focused sub-scenarios`
};