import MapImageLayer from "@arcgis/core/layers/MapImageLayer";


const impervDesc = [{
									layerKey: 'lulc-hl-imp16',
									layerItem: null,
									server: 'terrene',
									title: 'Impervious Description 2016',
									folder: 'SAFEA_LULC_Highlands',
									mapServer: 'Imperviousness_description',
									layerId: 3,
									opacityBar: null,
									parentDiv: 'lulchild',
									defExp: '1=1',
									isRasterLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'lulc-hl-imp11',
									layerItem: null,
									server: 'terrene',
									title: 'Impervious Description 2011',
									folder: 'SAFEA_LULC_Highlands',
									mapServer: 'Imperviousness_description',
									layerId: 2,
									opacityBar: null,
									parentDiv: 'lulchild',
									defExp: '1=1',
									isRasterLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'lulc-hl-imp06',
									layerItem: null,
									server: 'terrene',
									title: 'Impervious Description 2006',
									folder: 'SAFEA_LULC_Highlands',
									mapServer: 'Imperviousness_description',
									layerId: 1,
									opacityBar: null,
									parentDiv: 'lulchild',
									defExp: '1=1',
									isRasterLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}, {
									layerKey: 'lulc-hl-imp01',
									layerItem: null,
									server: 'terrene',
									title: 'Impervious Description 2001',
									folder: 'SAFEA_LULC_Highlands',
									mapServer: 'Imperviousness_description',
									layerId: 0,
									opacityBar: null,
									parentDiv: 'lulchild',
									defExp: '1=1',
									isRasterLayer: true,
									hasFeatureServer: false,
									detailsText: ''
								}];

const nlcd =[
	{
		layerKey: 'lulc-hl-nlcd-lcci',
		layerItem: null,
		server: 'terrene',
		title: 'NLCD Land Cover Change Index',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 0,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc16',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2016',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 7,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc13',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2013',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 6,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc11',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2011',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 5,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc08',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2008',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 4,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc06',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2006',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 3,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc04',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2004',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 2,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-nlcd-lulc01',
		layerItem: null,
		server: 'terrene',
		title: 'Land use/land cover 2001 ',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'National_Land_Cover_Database_Highlands',
		layerId: 1,
		opacityBar: null,
		parentDiv: 'lulchlnlcd',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}

];
const urbImp =[
	{
		layerKey: 'lulc-hl-urban-imp16',
		layerItem: null,
		server: 'terrene',
		title: 'Urban Imperviousness 2016',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'Urban_Imperviousness',
		layerId: 0,
		opacityBar: null,
		parentDiv: 'lulchlui',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-urban-imp11',
		layerItem: null,
		server: 'terrene',
		title: 'Urban Imperviousness 2011',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'Urban_Imperviousness',
		layerId: 1,
		opacityBar: null,
		parentDiv: 'lulchlui',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-urban-imp06',
		layerItem: null,
		server: 'terrene',
		title: 'Urban Imperviousness 2006',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'Urban_Imperviousness',
		layerId: 2,
		opacityBar: null,
		parentDiv: 'lulchlui',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'lulc-hl-urban-imp01',
		layerItem: null,
		server: 'terrene',
		title: 'Urban Imperviousness 2001',
		folder: 'SAFEA_LULC_Highlands',
		mapServer: 'Urban_Imperviousness',
		layerId: 3,
		opacityBar: null,
		parentDiv: 'lulchlui',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}
];



			

							

											

export const lulcImpDesc = {id: `aa-hl-lulc-id`, title:'Highlands Imperviousness Description', type:'group', items:[]}
export const lulcNlcd = {id: `aa-hl-lulc-nlcd`, title:'Highlands National Land Cover Database', type:'group', items:[]}
export const lulcUrbanImp = {id: `aa-hl-lulc-ui`, title:'Highlands Urban Imperviousness', type:'group', items:[]}

lulcImpDesc.items = impervDesc.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-lulc-id-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});


lulcNlcd.items = nlcd.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-lulc-nlcd-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});


lulcUrbanImp.items = urbImp.map( (floodmapperObj) => {
	return {
		layerId: `aa-hl-lulc-ui-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});
