
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const medLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/`
	, sublayers: [{id:3}]
	, title: 'Medium Agricultural Focused Priority'
});
export const medium = 
{
	id:'sa-pl-medium',
	title:"Medium Agricultural Focused Priority",
	type:'group',
	showScenario:'pinelandsMedium',
	items:[
	
	{layerId:'sa-pl-medium-main', type: 'layer', item: medLayer, meta:null},
	
	{
		id: 'sa-pl-medium-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [ {
			layerKey: 'pineMed-lulc',
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 8,
			parentDiv: 'pineMedDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineMed-train',
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 9,
			parentDiv: 'pineMedDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineMed-roads',
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 10,
			parentDiv: 'pineMedDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineMed-res',
			server: 'terrene',
			title: 'Distance to Med Density Residential Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 11,
			parentDiv: 'pineMedDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineMed-sewer',
			server: 'terrene',
			title: 'Sewer Service Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 12,
			parentDiv: 'pineMedDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-pl-medium-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};