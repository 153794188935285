import React from 'react';
import ReactDOM from 'react-dom';
import { applyPolyfills, defineCustomElements } from '@esri/calcite-components/dist/loader';
import './index.css';


//import { persistStore } from 'redux-persist';
//import { PersistGate } from  'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { configureStore } from './store.js';


import CalciteApp from './CalciteApp';
import reportWebVitals from './reportWebVitals';



const store = configureStore();
//const persistor = persistStore(store);

// Apply polyfills and then define the custom elements
// polyfills are not needed if you don't support IE11 or Edge
applyPolyfills().then(() => {
  defineCustomElements(window);
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <CalciteApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
