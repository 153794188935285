import React, { useRef, 
	useEffect,
	//useState 
} from "react";
import { connect } from "react-redux";
import Search from "@arcgis/core/widgets/Search";
import { CalcitePanel } from "@esri/calcite-components-react";
//import Extent from "@arcgis/core/geometry/Extent";
//import Locator from "@arcgis/core/tasks/Locator";



const FindLocationPanel = ({isMapLoading, arcgisMap}) => {

	const findLocation = useRef(null);

	useEffect(() => {
		/*
		const NJExtentish = new Extent({
				
			xmax: -8210729.690342123,
			xmin: -8413746.437467542,
			ymax: 5076908.477570052,
			ymin: 4698392.313501879,
			spatialReference: {
			  wkid: 102100
			}
		});
		
		const searchExtent = {
			geometry: NJExtentish
		}
		*/
		
		//console.log(arcgisMap, isMapLoading);
	  	if (findLocation.current && !findLocation.current.children.length && arcgisMap.map) {
			arcgisMap.when( () => {
				new Search({
					view: arcgisMap,
					container: findLocation.current,
					/*
					locationEnabled: false,
					includeDefaultSources: false,
					*/
					/*
					sources: [{
						name: "NJ Locator",
						placeholder: "Enter a location...",
						countryCode: "US",
						locator: new Locator({
							url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
						}),
						filter: searchExtent,
					}]
					*/

				});
			});

		}
	}, [arcgisMap]);


	return (
		<CalcitePanel  loading={isMapLoading}>
		<div className="content-area" style={{minHeight:'50vh'}} >
			<div ref={findLocation}></div>
		</div>		
		</CalcitePanel>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(FindLocationPanel);
