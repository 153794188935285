import React, { useRef, 
	useEffect,
	useState 
} from "react";
import { connect } from "react-redux";

import { CalciteBlock, CalciteButton, CalciteInput
	//, CalciteNotice 
} from "@esri/calcite-components-react";
import {SaveMapOptions, GenerateUrlOptions} from '../../../../../Logic/UrlOptionsLogic';

const SaveMapBlock = ({isMapLoading, arcgisMap}) => {

	const [shareMapTitle, SetShareMapTitle] = useState('No Title');
	const [shareMapLink, SetShareMapLink] = useState('');
	const tempBlockContainer = useRef(null);



	const UpdateShareMapTitle = (event) => {
		//console.log('Updating portal url.', event);
		event.preventDefault();
		//event.stopPropagation();
		let useMapTitle = ( event.type && event.type === 'drop' ) ? event.dataTransfer.getData('text') : event.target.value;
		useMapTitle = useMapTitle.trim();
		//SetDisableSearchButton(!useUrl.length);
		SetShareMapTitle(useMapTitle);
	};
	const SaveMapToDb = () => {
		/*
		if( shareMapTitle.trim() === '' ) {
			return false;
		}
		*/
		const myJsonObj = GenerateUrlOptions(arcgisMap);
		SaveMapOptions(shareMapTitle || 'No Title', myJsonObj)
		.then( (response) => {
			console.log('Map saved', response);
			SetShareMapLink( `${myJsonObj.origin}${process.env.REACT_APP_HOMEPAGE_FOLDER.substring(1)}map/${response.data.payload.key}/`)
		})
		.catch( (error) => {
			alert('There was a problem saving this map.')
		});
	}

	useEffect(() => {
		//console.log(arcgisMap, isMapLoading);
	  	if (tempBlockContainer.current && arcgisMap.map) {

		}
	}, [arcgisMap]);
//TODO: icons arne't loading when using key

	return (
		<CalciteBlock
		heading="Save Your Map"
		summary="Get a pretty, permanent URL to share."
		collapsible={true}
		>
			
			{/*
			<CalciteNotice scale="s" color="yellow" active={true} icon="exclamation-mark-triangle-f">
				<div slot="title">{"Imported Data Layers"}</div>
				<div slot="message">{'Layer reloading or saving from the `Import Data` Module is not implemented yet.'}</div>
			</CalciteNotice>
			*/}
			Give this map a title:
			<CalciteInput  type="text" label="Title:"
						onKeyUp={(e) => { UpdateShareMapTitle(e) }}
						onChange={(e) => { UpdateShareMapTitle(e) }}
						onDrop={(e) => { UpdateShareMapTitle(e) }}
						value={shareMapTitle}
			 ></CalciteInput>
			<CalciteButton width="full" color="blue" iconStart="save" 
			onClick={(e)=> {
				SaveMapToDb();
			}}
			disabled={
				/*shareMapTitle.trim() === '' || */
				null} >Save Link Permanently</CalciteButton>
			<hr />
			<CalciteInput readOnly={true} type="text" label="Saved URL:"
						value={shareMapLink} ></CalciteInput>
			<CalciteButton 
				href={shareMapLink}
				target="_blank"
				width="full" color="blue" iconStart="link"
						disabled={shareMapLink.trim() === '' || null}
						>View Saved Link</CalciteButton>
		</CalciteBlock>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveMapBlock);
