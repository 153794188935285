//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";


const forestBiomass = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/forest_biomass/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Forest Biomass'
});

const forestGroup = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/forest_group/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Forest Group'
});

const forestType = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Forest_Type/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Forest Type'
});
const forestCanopy = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Forest__canopy/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Forest Canopy'
});

const forestUnderStory = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:0}]
	, title: 'Forest Understory Carbon'
});


const forestTotalCarbon = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:1}]
	, title: 'Total Forest Carbon'
});


const forestSoilOrgCar = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:2}]
	, title: 'Soil Organic Carbon'
});


const forestStandDead = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:3}]
	, title: 'Standing Dead Carbon'
});


const forestCsLitter = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:4}]
	, title: 'Forest Litter Carbon'
});

const forestCsDownDead = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:5}]
	, title: 'Forest Down Dead Carbon'
});
const forestCsBelowGround = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:6}]
	, title: 'Belowground Forest Carbon'
});
const forestCsAboveGround = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_forest_carbon/Forest_carbon_stocks/MapServer/"
	, sublayers: [{id:7}]
	, title: 'Carbon Aboveground'
});

/*
forestBiomass.when(() => {
	//Create a template of literally all the fields
	forestBiomass.popupTemplate = forestBiomass.createPopupTemplate();
});
*/

const thisId = 'aa-hl-forest';
export const hlForest = {
	id: thisId,
	title:"Forest",
	type:'group',
	description:`Forest assessment data include biomass, forest species, forest cover, forest types, and forest carbon stocks. The data can be helpful in visualization and assessing the existing forest resources.`,
	items: [
		{layerId:`${thisId}-biomass`, type: 'layer', item: forestBiomass, meta:null},
		{layerId:`${thisId}-group`, type: 'layer', item: forestGroup, meta:null},
		{layerId:`${thisId}-type`, type: 'layer', item: forestType, meta:null},
		{layerId:`${thisId}-canopy`, type: 'layer', item: forestCanopy, meta:null},
		{layerId:`${thisId}-fc-understory`, type: 'layer', item: forestUnderStory, meta:null},
		{layerId:`${thisId}-fc-total-carbon`, type: 'layer', item: forestTotalCarbon, meta:null},
		{layerId:`${thisId}-fc-soil-org-car`, type: 'layer', item: forestSoilOrgCar, meta:null},
		{layerId:`${thisId}-fc-stand-dead`, type: 'layer', item: forestStandDead, meta:null},
		{layerId:`${thisId}-fc-cs-litter`, type: 'layer', item: forestCsLitter, meta:null},
		{layerId:`${thisId}-fc-cs-down-dead`, type: 'layer', item: forestCsDownDead, meta:null},
		{layerId:`${thisId}-fc-cs-below-ground`, type: 'layer', item: forestCsBelowGround, meta:null},
		{layerId:`${thisId}-fc-cs-above-ground`, type: 'layer', item: forestCsAboveGround, meta:null},
		
	]
}
