//import logo from './logo.svg';
import '@esri/calcite-components/dist/calcite/calcite.css';
import './CalciteApp.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";




import GenericHeader from './Common/GenericHeader';
import GenericFooter from './Common/GenericFooter';
import MapPage from './Pages/Map/MapPage';
//import TestPage from './Pages/Test/TestPage';
import {  CalciteShell } from "@esri/calcite-components-react";
import DataSourcesPage from './Pages/DataSources';
import PresentationPage from './Pages/Presentation';

const basename = {};
//Change <base href="/"/> in index.html if necessary...
if( process.env.REACT_APP_HOMEPAGE_FOLDER ) {
  basename.basename = process.env.REACT_APP_HOMEPAGE_FOLDER;
}else{
  basename.basename='/';
}
//<BrowserRouter basename={'/rssbp-database/'}>
const CalciteApp = () =>{
  return (
      <CalciteShell>
      <GenericHeader />
        <BrowserRouter {...basename}>
          <Routes>
            <Route path="/" element={<MapPage />} />
            <Route path="map/:shareMapKey" element={<MapPage />} />
            <Route path="data-sources" element={<DataSourcesPage />} />
            <Route path="presentation" element={<PresentationPage />} />
          </Routes>
          <GenericFooter />
        </BrowserRouter>
		  </CalciteShell>
  );
}

export default CalciteApp;
