import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import {loadLayer } from '../../../../../Flux/thunks';
import { updateTip } from "../../../../../Flux/actions";
import { 
	//CalciteNotice, 
	CalciteButton, CalcitePickList, CalcitePickListItem, CalciteAction } from "@esri/calcite-components-react";
import {LAYER_ID_HEADER} from './logic';
import tutorialPdf from './documents/MCDA_AHP_tutorial.pdf';



const AHPPanel = ({availableAHPLayers, onUpdateTip, loadLayerOnMap, OpenModalTool, isMapLoading, arcgisMap}) => {


	const [ahpLayers, SetAhpLayers] = useState([]);

	useEffect( () => {
		const findGroup = availableAHPLayers.find( (layerInfo) => {
			return layerInfo.id === 'custom';
		});
		if(findGroup && findGroup.items) {
			SetAhpLayers( findGroup.items.filter( (individualLayer) => {
				return individualLayer.layerId && individualLayer.layerId.startsWith(LAYER_ID_HEADER);
			}) );
		}
	}, [availableAHPLayers, SetAhpLayers]);

	
	const AddLayerToMap = (layerItem, _event) => {
		//console.log('[MapLayersFlowPanel] DUMB: Adding layer to map.', layerItem);
		loadLayerOnMap(layerItem);
		//loadLayerOnMap
	};
	const CreateElementFromItem = (selectionItem, key) => {
		const appendHelper = {};
		if( !selectionItem.meta ) {
			appendHelper.disabled=true;
		}
		return (
			<CalcitePickListItem key={key} 
			heading={selectionItem.item.title} 
			description={selectionItem.item.title} 
			title={selectionItem.item.title} 

			value={selectionItem.item} 
			onCalciteListItemChange={ (_e) => { 
				if( _e.detail && _e.detail.selected) {
					//SearchServer(_e.detail.value);
					//AddLayerToMap()
				}
			}}
			>{
				selectionItem.downloadLink 
				? 
				<CalciteAction 
				slot="actions-end"
				icon="download" 
				title="Download"
				onClick={ (_e) => { 
					const myItems = selectionItem.item.exportImageServiceParameters.toJSON();
					console.log('Downloading Data...', selectionItem, selectionItem.downloadLink, myItems ); 
					console.log('Has custom?.', selectionItem.item.custom ); 
					myItems.format = 'tiff';
					myItems.f = 'image';
					myItems.noData = '-1,0';
					
					if( selectionItem.item.ora && selectionItem.item.ora.export ) {
						myItems.renderingRule= selectionItem.item.ora.export;
					}


					const myUrlParams=Object.keys(myItems).map( (myItemKey) => {
						console.log(myItems[myItemKey]);
						if(typeof myItems[myItemKey]  === 'object') {
							return `${myItemKey}=${encodeURIComponent(JSON.stringify(myItems[myItemKey]))}`;
						}else if( Array.isArray(myItems[myItemKey])) {
							return `${myItemKey}=${encodeURIComponent(myItems[myItemKey].join(','))}`;
						}
						return `${myItemKey}=${myItems[myItemKey]}`;
					});

					myUrlParams.push(`size=${encodeURIComponent('4096,4096')}`);
					myUrlParams.push(`bbox=${selectionItem.item.fullExtent.xmin},${selectionItem.item.fullExtent.ymin},${selectionItem.item.fullExtent.xmax},${selectionItem.item.fullExtent.ymax}`);
					myUrlParams.push(`bboxSR=${selectionItem.item.fullExtent.spatialReference.wkid}`);
					console.log('Link: ', `${selectionItem.downloadLink}/exportImage?${myUrlParams.join('&')}`);
					window.open(
						`${selectionItem.downloadLink}/exportImage?${myUrlParams.join('&')}`
					);

				}}></CalciteAction>
				: null

			}
			<CalciteAction 
				slot="actions-end"
				icon="information" 
				title="Metadata"
				{...appendHelper}
				onClick={ (_e) => { console.log('Activating Metadata...', _e); 
					if(selectionItem.meta ) {
						const {layerTitle, layerDescription, mapService, authoritiveSource} = selectionItem.meta;
						onUpdateTip(layerTitle, layerDescription, mapService, authoritiveSource);
					}
				}}></CalciteAction>
			<CalciteAction slot="actions-end" icon={
				selectionItem.type === 'tiles' ? 'tile-layer' : 'add-layer'
			} onClick={ (_e) => { 
				//SearchServer(layers.url); 
				AddLayerToMap(selectionItem.item);
			}}></CalciteAction></CalcitePickListItem>
		);
	}
	const OutputLayers = () => {
		if(ahpLayers.length < 1) {
			return(<>
				<h4>No layers have been generated yet.</h4>
				<p><em>Use the AHP Tool above to start generating layers.</em></p>
			</>)
		}

		return <CalcitePickList>
		{ahpLayers
		.map( (selectionItem, key) => {
			return CreateElementFromItem(selectionItem, key);
		})}
		</CalcitePickList>
	};

	//console.log('AHP Panel', availableAHPLayers);
	/* 
	
				<CalciteNotice active={true} color="red" icon="exclamation-mark-triangle-f" style={{marginBottom:'1em'}}>
					<div slot="title">AHP</div>
					<div slot="message">This feature is still under development in the new format and some data items may not be available.</div>
				</CalciteNotice>
	*/

	return (<>
			<div className="content-area">
				<div style={{borderBottom:'1px dotted black', marginBottom: '.5rem', paddingBottom: '.5rem'}}>
					<p><em>If it is your first time using the tool, you may want to consider downloading the tutorial document below and follow along while you use the tool. </em></p>
					<CalciteButton target="_blank" href={tutorialPdf} width="full" color="neutral" iconStart="link" iconEnd="file-pdf">Download AHP Tool Tutorial (.pdf)</CalciteButton>
				</div>
				<div>
					<CalciteButton width="full" onClick={OpenModalTool}>Open AHP Tool</CalciteButton>
				</div>
				<hr />
				<div>
					<h3>Generated layers</h3>
					<div>
						{OutputLayers()}
					</div>
				</div>
			</div>
		</>);

}


//what state items should this component have access to
const mapStateToProps = (state) => ({
    availableAHPLayers: state.availableLayers
	/*.find( (layerInfo) => {
		return layerInfo.id === 'custom';
	}).items.filter( (individualLayer) => {
		return individualLayer.layerId && individualLayer.layerId.startsWith(LAYER_ID_HEADER);
	}),
	*/
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onUpdateTip: (layerTitle, layerDescription, mapService, authoritiveSource) => {dispatch(updateTip(layerTitle, layerDescription, mapService, authoritiveSource))},
	loadLayerOnMap: (layer) => {dispatch(loadLayer(layer))},
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPPanel);