//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
export const projectedLossRatePestsEastGroup = {id: `aa-pl-hazard-pl-pe`, title:'Projected Loss Rate: Pests East', type:'group', items:[]}
export const projectedLossRateSpeciesGroup = {id: `aa-pl-hazard-pl-s`, title:'Projected Loss Rate: Species', type:'group', items:[]}

projectedLossRateSpeciesGroup.items = [ {
	layerKey: 'projLossSpecies-pitch',
	server: 'terrene',
	title: 'Pitch Pine',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Project_loss_rate_species',
	layerId: 0,
	parentDiv: 'projLossSpecies',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossSpecies-southern',
	server: 'terrene',
	title: 'Southern Pines All spp.',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Project_loss_rate_species',
	layerId: 1,
	parentDiv: 'projLossSpecies',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossSpecies-sirex',
	server: 'terrene',
	title: 'Sirex Pines spp.',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Project_loss_rate_species',
	layerId: 2,
	parentDiv: 'projLossSpecies',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
// ,{layerKey: 'projLossSpecies-beech', server: 'terrene', title:'American Beech', folder: 'SEFEA_Pinelands', mapServer:'Project_loss_rate_species', layerId: 3, parentDiv:'projLossSpecies', defExp: '1=1', isRasterLayer: true, hasFeatureServer: false, detailsText: ''}
, {
	layerKey: 'projLossSpecies-whiteOak',
	server: 'terrene',
	title: 'White Oak spp.',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Project_loss_rate_species',
	layerId: 4,
	parentDiv: 'projLossSpecies',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossSpecies-redOak',
	server: 'terrene',
	title: 'Red Oak spp.',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Project_loss_rate_species',
	layerId: 5,
	parentDiv: 'projLossSpecies',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}].map( (floodmapperObj) => {
	return {
		layerId: `aa-pl-hazard-pl-pe-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});

projectedLossRatePestsEastGroup.items = [{
	layerKey: 'projLossPest-sirexHost',
	server: 'terrene',
	title: 'Sirex woodwasp % host basal area (BA) loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 0,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-sirexTotal',
	server: 'terrene',
	title: 'Sirex woodwasp % total BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 1,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-sirexArea',
	server: 'terrene',
	title: 'Sirex woodwasp area of BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 2,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-pineHost',
	server: 'terrene',
	title: 'Southern pine beetle % host BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 3,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-pineTotal',
	server: 'terrene',
	title: 'Southern pine beetle % total BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 4,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-pineArea',
	server: 'terrene',
	title: 'Southern pine beetle area of BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 5,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
// ,{layerKey: 'projLossPest-beechHost', server: 'terrene', title:'Beech bark disease % host BA loss', folder: 'SEFEA_Pinelands', mapServer:'Projected_Loss_Pest_East', layerId: 6, parentDiv:'projLossPest', defExp: '1=1', isRasterLayer: true, hasFeatureServer: false, detailsText: ''}
// ,{layerKey: 'projLossPest-beechArea', server: 'terrene', title:'Beech bark disease area of BA loss', folder: 'SEFEA_Pinelands', mapServer:'Projected_Loss_Pest_East', layerId: 7, parentDiv:'projLossPest', defExp: '1=1', isRasterLayer: true, hasFeatureServer: false, detailsText: ''}
, {
	layerKey: 'projLossPest-oakHost',
	server: 'terrene',
	title: 'Oak decline % host BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 8,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-oakTotal',
	server: 'terrene',
	title: 'Oak decline % total BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 9,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}, {
	layerKey: 'projLossPest-oakArea',
	server: 'terrene',
	title: 'Oak decline area of BA loss',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Projected_Loss_Pest_East',
	layerId: 10,
	parentDiv: 'projLossPest',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}].map( (floodmapperObj) => {
	return {
		layerId: `aa-pl-hazard-pl-pe-${floodmapperObj.layerKey}`,
		type: 'layer',
		item: new MapImageLayer({
			url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
			, sublayers: [{id:floodmapperObj.layerId}]
			, title: floodmapperObj.title
		}),
		meta:null}
});
