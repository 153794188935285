
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const agriPrioLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA_Highlands/Agricultural_Priority/MapServer/`
	, sublayers: [{id:0}]
	, title: 'Agricultural Priority Areas Restricted'
})

export const agriPrio = 
{
	id:'sa-hl-agri-prio',
	title:"Agricultural Priority Areas Restricted",
	type:'group',
	showScenario:'highlandsAgriPriority',
	items:[
	
	{layerId:'sa-hl-agri-prio-main', type: 'layer', item: agriPrioLayer, meta:null},
	
	{
		id: 'sa-hl-agri-prio-cl',
		type: 'group-set',
		//type: 'group',
		showScenario:'highlandsAgriPriority',
		title: 'Classified Layers',
		items: [{
			layerKey: 'saluc-ap-apa',
			layerItem: null,
			server: 'terrene',
			title: 'Agricultural Priority Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Agricultural_Priority',
			layerId: 1,
			opacityBar: null,
			parentDiv: 'apDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-ap-lulc15',
			layerItem: null,
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SAFEA_Highlands',
			mapServer: 'Agricultural_Priority',
			layerId: 2,
			opacityBar: null,
			parentDiv: 'apDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-ap-mr',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SAFEA_Highlands',
			mapServer: 'Agricultural_Priority',
			layerId: 3,
			opacityBar: null,
			parentDiv: 'apDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-ap-ts',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SAFEA_Highlands',
			mapServer: 'Agricultural_Priority',
			layerId: 4,
			opacityBar: null,
			parentDiv: 'apDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'saluc-ap-hdra',
			layerItem: null,
			server: 'terrene',
			title: 'Distance to High Density Residential Areas',
			folder: 'SAFEA_Highlands',
			mapServer: 'Agricultural_Priority',
			layerId: 5,
			opacityBar: null,
			parentDiv: 'apDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}].map( (floodmapperObj) => {
			return {
				layerId: `sa-hl-agri-prio-cl-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};