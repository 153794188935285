import { CalcitePanel, CalciteFab } from "@esri/calcite-components-react";
import React, { useRef, 
	useEffect 
} from "react";

import './Map.css';

const Map = ({arcgisMap, loading, rasterLoading}) => {

	const mapDiv = useRef(null);
	useEffect(() => {
	  	if (mapDiv.current) {
			arcgisMap.container = mapDiv.current;
		}
	}, [arcgisMap]);
	
	return (<CalcitePanel><div className="mapDiv" ref={mapDiv}></div>
	<div style={{position:'absolute',top:'1rem',right:'1rem'}}>
	{
		rasterLoading ? <CalciteFab 
		color="blue" icon="raster-analysis" label="Your Raster Data is Loading..." 
		loading={true}
    	textEnabled={true} 
		text="Your Raster Data is Loading"></CalciteFab>
		: null }
	</div></CalcitePanel>);
}

export default Map;