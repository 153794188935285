
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

const transLayer = new MapImageLayer({
	url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/`
	, sublayers: [{id:5}]
	, title: 'Infrastructure Focused Priority (Scenario 2)'
});
export const trans = 
{
	id:'sa-pl-trans',
	title:"Infrastructure Focused Priority (Scenario 2)",
	type:'group',
	showScenario:'pinelandsTrans',
	items:[
	
	{layerId:'sa-pl-trans-main', type: 'layer', item: transLayer, meta:null},
	
	{
		id: 'sa-pl-trans-df',
		type: 'group-set',
		//type: 'group',
		title: 'Driving Factors',
		items: [{
			layerKey: 'pineTrans-lulc',
			server: 'terrene',
			title: 'Land Use / Land Cover 2015',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 8,
			parentDiv: 'pineTransDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineTrans-train',
			server: 'terrene',
			title: 'Distance to Train Stations',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 9,
			parentDiv: 'pineTransDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineTrans-roads',
			server: 'terrene',
			title: 'Distance to Major Roads',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 10,
			parentDiv: 'pineTransDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineTrans-res',
			server: 'terrene',
			title: 'Distance to Trans Density Residential Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 11,
			parentDiv: 'pineTransDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}, {
			layerKey: 'pineTrans-sewer',
			server: 'terrene',
			title: 'Sewer Service Areas',
			folder: 'SEFEA_Pinelands',
			mapServer: 'Weighted_Overlay',
			layerId: 12,
			parentDiv: 'pineTransDf',
			defExp: '1=1',
			isRasterLayer: true,
			hasFeatureServer: false,
			detailsText: ''
		}
].map( (floodmapperObj) => {
			return {
				layerId: `sa-pl-trans-df-${floodmapperObj.layerKey}`,
				type: 'layer',
				item: new MapImageLayer({
					url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
					, sublayers: [{id:floodmapperObj.layerId}]
					, title: floodmapperObj.title
				}),
				meta:null}
		})
	}
	
] 
};