//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

import {projectedLossRateSpeciesGroup, projectedLossRatePestsEastGroup} from './hazardsGroupCompilation';

const compTbaLoss = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Composite_Hazard/MapServer/"
	, sublayers: [{id:2}]
	, title: 'TBA Loss'
});
const compRisk = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Composite_Hazard/MapServer/"
	, sublayers: [{id:1}]
	, title: 'Risk'
});
const compPctTbaLoss = new MapImageLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Composite_Hazard/MapServer/"
	, sublayers: [{id:0}]
	, title: 'PCT TBA Loss'
});




/*
forestBiomass.when(() => {
	//Create a template of literally all the fields
	forestBiomass.popupTemplate = forestBiomass.createPopupTemplate();
});
*/

const thisId = 'aa-pl-hazard';
export const plHazard = {
	id: thisId,
	title:"Hazard",
	type:'group',
	description:`Hazard tab identifies potential impacts of pests and pathogens to forest ecosystems for the 2013 -2027 timeframe. This data can be useful to inform assessments of natural ecosystems, forest pest prevention and risk prone areas.`,
	items: [
		projectedLossRateSpeciesGroup,
		projectedLossRatePestsEastGroup,
		{layerId:`${thisId}-tba-loss`, type: 'layer', item: compTbaLoss, meta:null},
		{layerId:`${thisId}-risk`, type: 'layer', item: compRisk, meta:null},
		{layerId:`${thisId}-pct-tba-loss`, type: 'layer', item: compPctTbaLoss, meta:null},
		
	]
}