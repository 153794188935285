import React from "react";
import { connect } from "react-redux";

import { CalciteButton, 
	//CalciteNotice 
} from "@esri/calcite-components-react";
import Portal from "@arcgis/core/portal/Portal";
//import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
//import esriId from "@arcgis/core/identity/IdentityManager";

const ArcGISOnlinePortalLoggedOutPanel = ( ) => {
	
	const SignInToArcGISOnline = () => {

		var portal = new Portal({
			
			//url: "https://rutgers.maps.arcgis.com/",
			//portalHostname: "https://rutgers.maps.arcgis.com/",
			//customBaseUrl: "https://rutgers.maps.arcgis.com/",
			//restUrl: "https://rutgers.maps.arcgis.com/sharing/rest",
			canSignInIDP: true,
			canSignInArcGIS: true,
			
			portalMode: "multitenant",
			//urlKey: "rutgers",
			
			
		}); 
		portal.authMode = "immediate";//"auto"; 
		portal.load()
		;
	}
	
	return (
		<>
		<div className="content-area">

			You are not signed into an ArcGIS Online account. Please sign in here:
			
			<CalciteButton width="full"
				iconEnd="sign-in"
					onClick={ (_e) => { console.log('Connect to your portal'); SignInToArcGISOnline(); }}
				>Sign into ArcGIS Online </CalciteButton>
			
		</div>		
		</>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(ArcGISOnlinePortalLoggedOutPanel);
