import { createStore, combineReducers 
	, applyMiddleware //for redux thunk
	} from 'redux';
	//import { todos, isLoading } from './todos/reducers';
	import { 
		isMapLoading, isLayerLoading, 
		isRasterLayerLoading,
		getUrlOptions,
		arcgisMap, alertList, layerList, availableLayers, graphingOptions, tipItem

		, isCustomServiceLoading
		, customServerBrowser
		, serverBrowserCache
		, serverBrowser
	} from './Flux/reducers';
	
	//next three for persisting
	import { persistReducer } from 'redux-persist';
	import storage from 'redux-persist/lib/storage';
	import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
	
	//redux thunk specifically
	import thunk from 'redux-thunk';
	import { composeWithDevTools } from 'redux-devtools-extension';
	
	const reducers = {
		isMapLoading
		, isLayerLoading
		, isRasterLayerLoading
		, getUrlOptions
		, arcgisMap
		, alertList
		, layerList
		, availableLayers
		, graphingOptions
		, tipItem
		
		, isCustomServiceLoading
		, customServerBrowser
		, serverBrowserCache
		, serverBrowser
	};
	const persistConfig = {
		key: 'calcite-map-redux',
		storage,
		stateReconciler: autoMergeLevel2
	};
	const rootReducer = combineReducers(reducers);
	
	//persisting
	
	const persistedReducer = persistReducer( persistConfig, rootReducer);
	
	
	export const configureStore = () => {
		return createStore(persistedReducer,
			composeWithDevTools(
				applyMiddleware(thunk)
			)
			//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //Not necessary when using redux thunk
		);
	}