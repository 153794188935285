//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";


const hydroFMLayer = [{
	layerKey: 'hydro-streams',
	layerItem: null,
	server: 'terrene',
	title: 'Streams',
	folder: 'SAFEA',
	mapServer: 'Hydrology',
	layerId: 0,
	opacityBar: null,
	parentDiv: 'hydrologyMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
//,{layerKey: 'hydro-highlands', layerItem: null,  server: 'terrene', title:'Highlands', folder: 'SAFEA', mapServer:'Water_Bodies_Highlands', layerId: 0, opacityBar:null, parentDiv:'hydrologyMenu', defExp: '1=1', isRasterLayer: true, hasFeatureServer: false, detailsText: ''}
, {
	layerKey: 'hydro-dwb',
	layerItem: null,
	server: 'terrene',
	title: 'Detailed Water Bodies',
	folder: 'SAFEA',
	mapServer: 'Water_Bodies_Highlands',
	layerId: 1,
	opacityBar: null,
	parentDiv: 'hydrologyMenu',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}];

const thisId = 'aa-hl-hydro';
export const hlHydrologyWater = {
	id: thisId,
	title:"Hydrology / Water",
	type:'group',
	description:`Hydrology/water tab include data on streams and detailed waterbodies. This data set is intended to serve as a resource for analysis rather than regulatory delineations.`,
	items: hydroFMLayer.map( (floodmapperObj) => {
		return {
			layerId: `aa-hl-hydro-${floodmapperObj.layerKey}`,
			type: 'layer',
			item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
				, sublayers: [{id:floodmapperObj.layerId}]
				, title: floodmapperObj.title
			}),
			meta:null}
	})
}