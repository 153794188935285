import React, { useEffect } from "react";
import { connect } from 'react-redux';

import { loadMapInProgress, loadMapFailure, 
	//loadMapSuccess 
	createAlert
} from '../../../../Flux/actions';

import {loadLayer} from '../../../../Flux/thunks';

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { CalciteButton } from "@esri/calcite-components-react";

//import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
//import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import RasterData from "@arcgis/core/rest/support/RasterData";
//import MosaicRule from "@arcgis/core/layers/support/MosaicRule";
import RasterFunction from "@arcgis/core/layers/support/RasterFunction";

import Portal from "@arcgis/core/portal/Portal";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
//https://github.com/Esrijsapi-resources/blob/master/esm-samples/jsapi-create-react-app/src/App.js

import { 
	//GenerateRaster, 
	GenerateRaster_triplet } from "../../../../Logic/RasterLogic";

//import RasterStretchRenderer from "@arcgis/core/renderers/RasterStretchRenderer";
//TEST ITEM

const DebugPanel = ({isMapLoading, isLayerLoading, onInduceMapInProgress, onInduceMapFailure, onAddLayerClick, onCreateAlert, arcgisMap}) => {

	const LogoutTest = () => {
		esriId.destroyCredentials();
		console.log('Boom!');
	}


	const Mosaic_Calculator = async () => {

		const addRaster = new RasterFunction({
			functionName: "RasterCalculator",
			functionArguments: {     
			  "InputNames": [
				"raster0",
				"raster1"
			  ],
			  "Expression": "(raster0 + raster1)",
			  "Rasters": [
				"$1",
				"$2"
			  ]
			},
			outputPixelType: "u8"
		});

		const colorFunction = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
			 colormap:[
				[0, 2, 114, 24],
				[2, 66, 154, 26],
				[4, 142, 194, 29],
				[6, 209, 234, 28],
				[8, 249, 232, 30],
				[10, 254, 184, 29],
				[12, 255, 131, 17],
				[14, 254, 60, 25]

			 ],
			  Raster: addRaster // chaining multiple rasterfunctions
			},
			outputPixelType:'u8'
		});


		const FINAL_RASTER = new ImageryLayer({
			url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Test_AHP_Rasters/AHP_Test_Mosaic/ImageServer/',
			// apply the most recent raster function to the chain
			renderingRule:colorFunction
			//renderingRule: colorMapper
			//mosaicRule: mosaicRule
			//, pixelType: 'u4'
			, noData: 0
			,pixelType: 'u8'
			//mosaicRule: mosaicRule
		});
	
		console.log('FINAL RASTER?????', FINAL_RASTER);

		onAddLayerClick(FINAL_RASTER);
		onCreateAlert('blue','Raster generated',`(ALL_TOGETHER_NOW) Should appear on map soon.`);

	}





	const ALL_TOGETHER_NOW = async () => {
		//These specific multipliers are based on the AVP power point where it says "[vis] * (0.653) + [elev] * (0.251) + [asp] * (0.096)]"
		const baseRasterServiceURL = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Highlands_AHP/agri_priority/ImageServer`;
		const rasterArray = [
			// "agri_prio"
			{url: '$$', format: null, multiplier: 1},
			//vernal pools
			{url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Highlands_AHP/Confirmed_Vernal_Pools_300m_Buffer/ImageServer/exportImage', format: 'tiff', multiplier: 1}
		];
		/*
		const baseRasterServiceURL = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/SEFEA_AHP/ImageServer`;
		const rasterArray = [
			//road "asp"
			{url: '$$', format: null, multiplier: .096},
			//train "vis"
			{url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage', format: 'tiff', multiplier: .653},
			//high density areas "elev"
			{url: 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_high/ImageServer/exportImage', format: 'tiff', multiplier: .251}
		];
		*/
		//Max value should be 42.65
		//Min value should be 1

		//Generate raster returns a promise of a rendering rule. You can apply a coloring ramp to it if you want and send that as the rendering rule instead.
		GenerateRaster_triplet(baseRasterServiceURL,rasterArray)
		.then( (myRenderingRule ) => {
			console.log('My Rendering Rule: ', myRenderingRule);
			
			
			/*
			const colorMapper = new RasterFunction({
				functionName: "Colormap",
				functionArguments: {
					//"ColorrampName": "Partial Spectrum",
					
    "colorramp": {
		"type": "multipart",
		"colorRamps": [
		  {
			  start:0,
			  stop: 3,
			"type": "algorithmic",
			"fromColor": [
			  255,
			  0,
			  0,
			  255
			],
			"toColor": [
			  0,
			  255,
			  0,
			  255
			],
			"algorithm": "esriHSVAlgorithm"
		  },
		  {
			start:3,
			stop: 60,
			"type": "algorithmic",
			"fromColor": [
			  155,
			  34,
			  78,
			  255
			],
			"toColor": [
			  255,
			  0,
			  0,
			  255
			],
			"algorithm": "esriCIELabAlgorithm"
		  }
		]
	  },
					"Raster": myRenderingRule
				},
				outputPixelType:"u4",
				variableName: "Raster"
			});


			*/
			
			const FINAL_RASTER = new ImageryLayer({
				url: baseRasterServiceURL,
				// apply the most recent raster function to the chain
				renderingRule:myRenderingRule 
				//renderingRule: colorMapper
				//mosaicRule: mosaicRule
				, pixelType: 'u4'
				, noData: 255
				//mosaicRule: mosaicRule
			});
		
			console.log('FINAL RASTER?????', FINAL_RASTER);

			onAddLayerClick(FINAL_RASTER);
			onCreateAlert('blue','Raster generated',`(ALL_TOGETHER_NOW) Should appear on map soon.`);
		})
		.catch( (failure) => {
			
			onCreateAlert('red','Raster Generation Failed.',`The raster generation failed because it failed. I'm sure there's a reason but I didn't output it yet.`);
		});

	}
					/*
    "Colormap": [
		[1,255,0,0],
		[2,0,255,0],
		[3,125,25,255],
	  ],
	  */

	const SHEER_RASTER_CALCULATOR = () => {

		const USE_SERVICE = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer`;

		const DIST_TRAIN_MULTIPLIER = 0.653;
		const DIST_TRAIN = {
			url: `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage?
			bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342
			&bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`,
			format: 'tiff'
		};	
		
		//Distance to high density areas
		const DIST_ELEV_MULTIPLIER = 0.251;
		const DIST_ELEV = {
			url: `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_high/ImageServer/exportImage?
			bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342
			&bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`,
			format: 'tiff'
		};	
		
		//ddistance to raods
		const DIST_ROAD_MULTIPLIER = 0.096;
		const DIST_ROADS ="$$";


		const expArr = [
			`(vis*${DIST_TRAIN_MULTIPLIER})`,
			`(elev*${DIST_ELEV_MULTIPLIER})`,
			`(asp*${DIST_ROAD_MULTIPLIER})`
		];


		const RASTER_CALC = new RasterFunction({
			functionName: "RasterCalculator",
			functionArguments: {
				"InputNames": [
					"vis", "elev", "asp",
				],
				"expression": expArr.join('+'),
			  	"rasters" : [
					DIST_TRAIN,
					DIST_ELEV,
					DIST_ROADS
				]
			}
		});

/*
		const colorMapper = new RasterFunction({
			functionName: "Colormap",
			functionArguments: {
				"Colormap": 'random', //'Partial Spectrum 1 Diverging',
				"raster": RASTER_CALC
			}
		});
		*/
		const FINAL_RASTER = new ImageryLayer({
			url: USE_SERVICE,
			// apply the most recent raster function to the chain
			renderingRule:RASTER_CALC, // colorMapper,
			//mosaicRule: mosaicRule
		});
	
		onAddLayerClick(FINAL_RASTER);



	}




	const MULTIPLY_TWO_RASTER = ( raster1, raster2) => {
		return new RasterFunction({
			functionName: "Arithmetic",
			functionArguments: {
				raster: raster1,
				raster2: raster2,
				operation: 3 //3
				////int 1=esriRasterPlus, 2=esriRasterMinus, 3=esriRasterMultiply, 4=esriRasterDivide, 5=esriRasterPower, 6=esriRasterMode
			}
		  });
	}
	
	const ADD_TWO_RASTER = ( raster1, raster2) => {
		return new RasterFunction({
			functionName: "Arithmetic",
			functionArguments: {
				raster: raster1,
				raster2: raster2,
				operation: 1
			}
		  });
	}


const TEST_TWO_RASTER = () => {
	const USE_SERVICE = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer`;


	const DIST_TRAIN = MULTIPLY_TWO_RASTER({
		url: `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage?bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342&bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`,
		format: 'tiff'
	}, .653 );	
	
	//Distance to high density areas
	const DIST_ELEV = MULTIPLY_TWO_RASTER({
		url: `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_high/ImageServer/exportImage?bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342&bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`,
		format: 'tiff'
	},0.251 );	
	
	//ddistance to raods
	const DIST_ROADS = MULTIPLY_TWO_RASTER( 
		"$$",
		.096
	);	

	//const ALL_ADDED = ADD_TWO_RASTER( DIST_ROADS, DIST_ELEV);


	
	const ALL_ADDED = ADD_TWO_RASTER( 
		DIST_ROADS,
		ADD_TWO_RASTER(DIST_TRAIN, DIST_ELEV)
	);





	
/*
	const remapper = new RasterFunction({
		functionName: "Remap",
		functionArguments: {
			"InputRanges": [
				0, 1,
				1, 2,
				2, 3,
				3, 4,
				4, 5,
				5, 6,
				6, 7,
				7, 8,
				8, 9,
				9, 4294967295
			  ],
			"OutputValues": [
				1,2,3,4,5,6,7,8,9,10
			],
		  "raster" : ALL_ADDED
		}
	});
	const colorMapper = new RasterFunction({
		functionName: "Colormap",
		functionArguments: {
			//"Colormap": 'random', //'Partial Spectrum 1 Diverging',
			"ColorMap": 'Black And White',
			"raster": ALL_ADDED
			/*
		  	"raster" : ADD_TWO_RASTER("$$", {
				  url: `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer/exportImage?bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342&bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`,
				  format: 'tiff'
			})
			* /
			
		},
		"variableName":"Raster"
	});
	*/
	
	const FINAL_RASTER = new ImageryLayer({
		url: USE_SERVICE,
		// apply the most recent raster function to the chain
		renderingRule: ALL_ADDED,
		//mosaicRule: mosaicRule
	});

	onAddLayerClick(FINAL_RASTER);
} 





const ORADEVRASTER = () => {

	const layer = new ImageryLayer({
		url: "https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer",
		// apply the most recent raster function to the chain
		//renderingRule: colorRF,
		//mosaicRule: mosaicRule
	  });
	  
	  onAddLayerClick(layer);

	  
	  
	  
const plus1 = new RasterFunction({
	functionName: "Arithmetic",
	functionArguments: {
		raster: "$$",
		raster2: {
			

		},
		operation: 1
	}
  });
  const colorMapper = new RasterFunction({
	functionName: "Colormap",
	functionArguments: {
		"Colormap": [
		[3,255,0,0],
		[6,0,255,0],
		[12,125,25,255],
	  ],
	  "raster" : plus1
	}
  })


  const layer2 = new ImageryLayer({
	url: "https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer",
	// apply the most recent raster function to the chain
	renderingRule: colorMapper,
	//mosaicRule: mosaicRule
  });
	  onAddLayerClick(layer2);
/*
	  let layer2 = new ImageryLayer({
		// URL to the imagery service
		url: "https://landsat2.arcgis.com/arcgis/rest/services/Landsat8_Views/ImageServer"
	  });
	  onAddLayerClick(layer2);
	  */

}
	const TestPortal = () => {

		
		let info = new OAuthInfo({
			appId:'ZJ2e5nW7N1MtYvJC',
			//portalUrl: 'https://rutgers.maps.arcgis.com',
			popup: true,
		  });
		
		  esriId.registerOAuthInfos([info]);

		  //https://developers.arcgis.com/documentation/mapping-apis-and-services/security/arcgis-identity/serverless-web-apps/#authorization-endpoints
		var portal = new Portal({
			
			//url: "https://rutgers.maps.arcgis.com/",
			//portalHostname: "https://rutgers.maps.arcgis.com/",
			//customBaseUrl: "https://rutgers.maps.arcgis.com/",
			//restUrl: "https://rutgers.maps.arcgis.com/sharing/rest",
			canSignInIDP: true,
			canSignInArcGIS: true,
			
			portalMode: "multitenant",
			//urlKey: "rutgers",
			
			
		}); 
		portal.authMode = "immediate";//"auto"; 
		portal.load()
		.then((response) => {
			
			console.log('Wow, got some kind of resposne: ', response);

			response.user.fetchGroups()
			.then((groups) => {
				groups.forEach((group) => {
				console.log(group.title, group.id);
				  group.fetchMembers().then((members) => {
					console.log("member", members);
				  });
				});
			 })
			.catch((groups) => {
				console.log('Bad groups ', groups);
			})


			
		})
		.catch((response) => {
			
			console.log('Bad response, but still a response', response);
		});
		
	}
	


	const GetCenterAndZoom = () => {
		if( arcgisMap ){
			onCreateAlert('blue','Center and Zoom', `Center ([Lng/Lat]): [${arcgisMap.center.longitude},${arcgisMap.center.latitude}], Zoom: ${arcgisMap.zoom}`)
		}
		return;
	}

	const AddLayerToMap = () => {
		const feature = new FeatureLayer({
			portalItem: {
				id: "4918cb21cebf4fc7984d78c8497acc1f"
			}
		});
		//arcgisMap.map.add(feature);
		onAddLayerClick(feature);
		return;
	}
	const CreateRandomAlert = () => {
		const randomTitle = `Title: ${(Math.random() + 1).toString(36).substring(7)}`;

		onCreateAlert('red', randomTitle, 'message');
		return;
	}
/*
{
	layerKey: 'pineHab-train',
	server: 'terrene',
	title: 'Distance to Train Stations',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Weighted_Overlay',
	layerId: 9,
	parentDiv: 'pineHabDf',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
{
	layerKey: 'pineHab-res',
	server: 'terrene',
	title: 'Distance to High Density Residential Areas',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Weighted_Overlay',
	layerId: 11,
	parentDiv: 'pineHabDf',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
{
	layerKey: 'pineHab-roads',
	server: 'terrene',
	title: 'Distance to Major Roads',
	folder: 'SEFEA_Pinelands',
	mapServer: 'Weighted_Overlay',
	layerId: 10,
	parentDiv: 'pineHabDf',
	defExp: '1=1',
	isRasterLayer: true,
	hasFeatureServer: false,
	detailsText: ''
}
*/
	
	const RasterFunctionTest = () => {

		const pineHabTrain = new RasterData({
			url: 'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/9'
		});
		const pineHabRes = new RasterData({
			url: 'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/11'
		});
		const pineHabRoads = new RasterData({
			url: 'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/10'
		});

		console.log({
			pineHabTrain,
			pineHabRes,
			pineHabRoads,
		});

		var colorRF = new RasterFunction({
			"rasterFunction": "RasterCalculator",
			"rasterFunctionArguments": {
			  "InputNames": [  //array of strings for arbitrary raster names
				"raster1_Name",
				"raster2_Name",
				"raster3_Name"
			  ],
			  "Expression": "raster1_Name + raster2_Name - raster3_Name",  //string, expression to calculate output raster from input rasters
			  //"ExtentType": <ExtentType>,  //int, optional, default 0; 0=esriExtentFirstOf, 1=esriExtentIntersectionOf, 2=esriExtentUnionOf, 3=esriExtentLastOf 
			  //"CellsizeType": <CellsizeType>,  //int, optional, default 0; 0=esriCellsizeFirstOf, 1=esriCellsizeMinOf, 2=esriCellsizeMaxOf, 3=esriCellsizeMeanOf, 4=esriCellsizeLastOf
			  "Rasters": [  //array of rasters
				pineHabTrain,
				pineHabRes,
				pineHabRoads,
			  ],
			  //"MatchVariable": true,  //new at 10.9, boolean, optional, default is true
			  //"UnionDimension": <true|false>  //new at 10.9, boolean, optional, default is true
			},
			//"outputPixelType": "<outputPixelType>",  //optional
			//"variableName": "Raster"
		  });

		const test = new ImageryLayer({
			url:'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/10/',
			renderingRule: colorRF
		});
		onAddLayerClick(test);

/*{
  "rasterFunction": "RasterCalculator",
  "rasterFunctionArguments": {
    "InputNames": [  //array of strings for arbitrary raster names
      "raster1_Name",
      "raster2_Name",
      "raster3_Name”,
      …
    ],
    "Expression": "raster1_Name + raster2_Name - raster3_Name”,  //string, expression to calculate output raster from input rasters
    "ExtentType": <ExtentType>,  //int, optional, default 0; 0=esriExtentFirstOf, 1=esriExtentIntersectionOf, 2=esriExtentUnionOf, 3=esriExtentLastOf 
    "CellsizeType": <CellsizeType>,  //int, optional, default 0; 0=esriCellsizeFirstOf, 1=esriCellsizeMinOf, 2=esriCellsizeMaxOf, 3=esriCellsizeMeanOf, 4=esriCellsizeLastOf
    "Rasters": [  //array of rasters
      <raster1>,
      <raster2>,
      <raster3>,
      …
    ]
    "MatchVariable": <true|false>,  //new at 10.9, boolean, optional, default is true
    "UnionDimension": <true|false>  //new at 10.9, boolean, optional, default is true
  },
  "outputPixelType": "<outputPixelType>",  //optional
  "variableName": "Raster"
}
*/









		/*
		const test = new ImageryLayer({
			
		})
		//onAddLayerClick(rasterData);

		const testMapImage = new MapImageLayer({
			url:'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/',
			sublayers:[
				{
					title: "Slope",
					source: {
					  type: "data-layer",
					  dataSource: {
						type: "raster",
						dataSourceName: "slope"
					  }
					}
				  }
			]
		})
		console.log(testRasterData);
		console.log(test);
*/
		/*
		const pineHabTrain = new ImageryLayer({
			url: 'https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_Pinelands/Weighted_Overlay/MapServer/9'
		});



		onAddLayerClick(pineHabTrain);
		*/

		return;
	}

	useEffect(() => {
		
	}, []);

/*

					async function ahpTool (A_weight = 0.653, B_weight = 0.251, C_weight = 0.096) {
						// get access to rasters
						let rasters = []
						for await (let raster of ["pineHab-train", "pineHab-res", "pineHab-roads"]){
							rasters.push(sefeaHelper.layerControl.findLayerByKey(raster))
						}
						console.log(rasters)
						let ahpCalc = new RasterFunction({
							rasterFunction: "AHPCalc",
							functionArguments: {
							InputNames: [
								"A", "B", "C"
							],
							Expression: `A * ${A_weight} + B * ${B_weight} + C * ${C_weight}`,
							Raster: rasters
						}
						});

						const layer = new ImageryLayer({
  							renderingRule: ahpCalc,
  							// mosaicRule: mosaicRule
						});
						console.log(layer)
						// sefeaHelper.map.add(layer)

					}
					// once testing is done, a button can be added to the selection module that says something like "apply selection" and it will look like this:
					// <button type="button" class="btn btn-sm btn-danger" onclick="sefeaHelper.layerControl.ahpTool(A_weight, B_weight, C_weight);">Apply Selection <span style="vertical-align: middle;" aria-hidden="true" class="esri-icon-non-visible"></span></button>
					ahpTool();
*/	
  
	//<button type="button" onClick={ (_e) => { console.log('Test add layer'); AddLayerToMap(); }}>ADD PORTAL FEATURE LAYER</button>
	return (
		<div>
			<h4>DEBUG</h4>
			<p>{isMapLoading ? 'Map is loading...' : 'Map ready.'}</p>
			<p>{isLayerLoading ? 'Layer is loading...' : 'Layer ready.'}</p>
			<div style={{marginTop:'2em'}}>

			<CalciteButton width="full"
		
		color="blue"
			onClick={ (_e) => { Mosaic_Calculator(); }}
		> [Func() Mosaic_Calculator]</CalciteButton>


			<CalciteButton width="full"
		
		color="blue"
			onClick={ (_e) => { ALL_TOGETHER_NOW(); }}
		> [Func() ALL_TOGETHER_NOW]</CalciteButton>



	<CalciteButton width="full"
		
		color="blue"
			onClick={ (_e) => { SHEER_RASTER_CALCULATOR(); }}
		> [Func() SHEER_RASTER_CALCULATOR]</CalciteButton>


				<CalciteButton width="full"
					disabled={true}
					color="primary"
						onClick={ (_e) => {  TEST_TWO_RASTER(); }}
					> [Func() TEST_TWO_RASTER]</CalciteButton>


			<CalciteButton width="full"
				
				color="inverse"
					onClick={ (_e) => { console.log('Connect to your portal'); ORADEVRASTER(); }}
				> Raster From ORADEV</CalciteButton>


				
			<CalciteButton width="full"
				
				color="inverse"
					onClick={ (_e) => { console.log('Connect to your portal'); TestPortal(); }}
				>CONNECT TO OAUTH TO SIGN IN </CalciteButton>
				<CalciteButton width="full"
					color="red"
						onClick={ (_e) => { console.log('Connect to your portal'); LogoutTest(); }}
					>LOGOUT </CalciteButton>
					
				<CalciteButton width="full"
				 	onClick={ (_e) => { 
						 AddLayerToMap(); 
					}}
				>Add Layer To Map With Loader</CalciteButton>

				<CalciteButton width="full"
				 	onClick={ (_e) => { console.log('Induce Map Loader'); GetCenterAndZoom(); }}
				>Output Center and Zoom Level</CalciteButton>
				<CalciteButton width="full"
				 	onClick={ (_e) => { console.log('Induce Map Loader'); onInduceMapInProgress(); }}
				>Show Map Loader</CalciteButton>
				<CalciteButton width="full"
					color="red"
				 	onClick={ (_e) => { console.log('Induce Map Failure'); onInduceMapFailure(); }}
				>Stop Map Loader (Via Fail)</CalciteButton>
				<CalciteButton width="full"
					color="red"
				 	onClick={ (_e) => { console.log('Create Random Alert'); CreateRandomAlert(); }}
				>RANDOM ALERT!!!</CalciteButton>
				
				<CalciteButton width="full"
					color="red"
				 	onClick={ (_e) => { console.log('BROKEN Raster Function Test'); RasterFunctionTest(); }}
				>BROKEN Raster Function Test</CalciteButton>
			</div>
		</div>
	);
}


//what state items should this component have access to
const mapStateToProps = (state) => ({
    //todos: state.todos,
    isMapLoading: state.isMapLoading,
    isLayerLoading: state.isLayerLoading
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	onInduceMapInProgress: () => {dispatch(loadMapInProgress())},
	onInduceMapFailure: () => {dispatch(loadMapFailure())},

	
	onAddLayerClick: (layer) => {dispatch(loadLayer(layer))},
	onCreateAlert: (color, title, message) => {dispatch(createAlert(color, title, message))},
	
	//import { loadMapInProgress, loadMapFailure, loadMapSuccess } from "./actions";
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugPanel);