import React
,
{ 
	//useRef, 
	//useEffect,
	//useState 
} 
from "react";
import { connect } from "react-redux";


//import AHPIntro from "./AHPIntro";
//import AHPVariables from "./AHPVariables";
//import AHPMatrix from "./AHPMatrix";
import { CalciteModal, CalciteButton } from "@esri/calcite-components-react";
import HighlandsAlcp from './ScenarioModalContent/HighlandsAlcp';
import HighlandsAgriPriority from './ScenarioModalContent/HighlandsAgriPriority';
import HighlandsAmenity from './ScenarioModalContent/HighlandsAmenity';
import HighlandsConservation from './ScenarioModalContent/HighlandsConservation';
import HighlandsEqualPrio from "./ScenarioModalContent/HighlandsEqualPrio";
import GeneralInfraPriority from "./ScenarioModalContent/GeneralInfraPriority";

import HighlandsInfraEqual from "./ScenarioModalContent/HighlandsInfraEqual";
import HighlandsInfraInfraPrio from "./ScenarioModalContent/HighlandsInfraInfraPrio";
import HighlandsInfraMedium from "./ScenarioModalContent/HighlandsInfraMedium";
import HighlandsInfraHigh from "./ScenarioModalContent/HighlandsInfraHigh";
import HighlandsInfraResi from "./ScenarioModalContent/HighlandsInfraResi";

import PinelandsEqualPrio from "./ScenarioModalContent/PinelandsEqualPrio";
import PinelandsInfra from "./ScenarioModalContent/PinelandsInfra";
import PinelandsMedium from "./ScenarioModalContent/PinelandsMedium";
import PinelandsHigh from "./ScenarioModalContent/PinelandsHigh";
import PinelandsTrans from "./ScenarioModalContent/PinelandsTrans";
import PinelandsHabitat from "./ScenarioModalContent/PinelandsHabitat";

/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const ScenarioModal = ({scenario, CloseModal}) => {
	if( !scenario || scenario==='' ) {

		return null;
	}


	const MainContent = () => {
		return <>
			<div slot="header" id="modal-title">Scenario Assessment of LU Change Potential</div>
			<div slot="content">
				<p>This tool demonstrates how geospatially combining driving factors of land use can be used to preliminarily identify opportunities for forest and ecological assessment, specifically in Highlands and Pinelands regions of New Jersey.</p>
				<p>ArcGIS and publicly available data, a raster weighted overlay suitability modeling approach was used to combine several biophysical and socioeconomic factors to identify potential spots for land use conversion. This approach provides a starting point for stakeholders, forest and land managers involvement regarding municipality, county or watershed specific conditions and forest management goals. Each overlay layer was valued by the attributes within and based on a common scale (0-low suitability to 9-high suitability). Each driving factor is given an influence percentage with relative importance according to the literature review (available on data sources tab). Classification on suitability is based upon a natural breaks statistical classification (High, Moderate, Low).</p>		
			</div>
		</>;
	}

	const RetrieveContent = () => {
		switch(scenario) {
			case 'main':
				return MainContent();
			case 'highlandsAlcp':
				return <HighlandsAlcp />;
			case 'highlandsAgriPriority':
				return <HighlandsAgriPriority />;
			case 'highlandsAmenity':
				return <HighlandsAmenity />;
			case 'highlandsConservation':
				return <HighlandsConservation />;
			case 'highlandsEqualPrio':
				return <HighlandsEqualPrio />;
			case 'generalInfraPriority':
				return <GeneralInfraPriority />;

			case 'highlandsInfraEqual':
				return <HighlandsInfraEqual />;
			case 'highlandsInfraInfraPrio':
				return <HighlandsInfraInfraPrio />;
			case 'highlandsInfraMedium':
				return <HighlandsInfraMedium />;
			case 'highlandsInfraHigh':
				return <HighlandsInfraHigh />;
			case 'highlandsInfraResi':
				return <HighlandsInfraResi />;
				
			case 'pinelandsEqualPrio':
				return <PinelandsEqualPrio />;
			case 'pinelandsInfra':
				return <PinelandsInfra />;
			case 'pinelandsMedium':
				return <PinelandsMedium />;
			case 'pinelandsHigh':
				return <PinelandsHigh />;
			case 'pinelandsTrans':
				return <PinelandsTrans />;
			case 'pinelandsHabitat':
				return <PinelandsHabitat />;
					
			default: 
				return null;
		}
	}


	return (
		<CalciteModal active={(scenario && scenario !== '') || undefined} onCalciteModalClose={CloseModal}>
			{
				RetrieveContent()
			}
			<CalciteButton slot="primary" color="neutral" icon="chevron-left" width="full" onClick={CloseModal}>Close Window</CalciteButton>
		</CalciteModal>
	)
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioModal);

/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/