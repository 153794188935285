import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const ecoRailroadLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/0"
	, title: 'Railroad Stations'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoPriSecRoadsLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/1"
	, title: 'Primary and Secondary Roads'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoRoadwayLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/2"
	, title: 'Roadway Network'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoPowerlinesLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/3"
	, title: 'Electric Power Transmission Lines'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoHouseHotSpot2010Layer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/4"
	, title: 'Housing Hot Spot 2010'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoHouse2010Layer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/5"
	, title: 'Housing 2010'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoPopHotSpot2010Layer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/6"
	, title: 'Population Hot Spot 2010'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoPop2010Layer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/7"
	, title: 'Population 2010'
	, outFields: ['*']
	, popupEnabled: true
});
const ecoSewerLayerLayer = new FeatureLayer({
	url: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/SAFEA/Economic_Infrastructure/MapServer/8"
	, title: 'Sewer Service Area'
	, outFields: ['*']
	, popupEnabled: true
});

ecoRailroadLayer.when(() => {
	//Create a template of literally all the fields
	ecoRailroadLayer.popupTemplate = ecoRailroadLayer.createPopupTemplate();
});
ecoPriSecRoadsLayer.when(() => {
	//Create a template of literally all the fields
	ecoPriSecRoadsLayer.popupTemplate = ecoPriSecRoadsLayer.createPopupTemplate();
});
ecoRoadwayLayer.when(() => {
	//Create a template of literally all the fields
	ecoRoadwayLayer.popupTemplate = ecoRoadwayLayer.createPopupTemplate();
});
ecoPowerlinesLayer.when(() => {
	//Create a template of literally all the fields
	ecoPowerlinesLayer.popupTemplate = ecoPowerlinesLayer.createPopupTemplate();
});
ecoHouseHotSpot2010Layer.when(() => {
	//Create a template of literally all the fields
	ecoHouseHotSpot2010Layer.popupTemplate = ecoHouseHotSpot2010Layer.createPopupTemplate();
});
ecoHouse2010Layer.when(() => {
	//Create a template of literally all the fields
	ecoHouse2010Layer.popupTemplate = ecoHouse2010Layer.createPopupTemplate();
});
ecoPopHotSpot2010Layer.when(() => {
	//Create a template of literally all the fields
	ecoPopHotSpot2010Layer.popupTemplate = ecoPopHotSpot2010Layer.createPopupTemplate();
});
ecoPop2010Layer.when(() => {
	//Create a template of literally all the fields
	ecoPop2010Layer.popupTemplate = ecoPop2010Layer.createPopupTemplate();
});

ecoSewerLayerLayer.when(() => {
	//Create a template of literally all the fields
	ecoSewerLayerLayer.popupTemplate = ecoSewerLayerLayer.createPopupTemplate();
});

export const hlEconomicInfra = {
	id: 'aa-hl-eco',
	title:"Economic Infrastructure",
	type:'group',
	description:`Economic infrastructure GIS data can be used to support decisions, to pinpoint hot spots to allocate resources, and to advance other projects.`,
	items: [
		{layerId:'aa-hl-eco-railroad', type: 'layer', item: ecoRailroadLayer, meta:null},
		{layerId:'aa-hl-eco-power-lines', type: 'layer', item: ecoPowerlinesLayer, meta:null},
		{layerId:'aa-hl-eco-roadway', type: 'layer', item: ecoRoadwayLayer, meta:null},
		{layerId:'aa-hl-eco-pri-sec-roads', type: 'layer', item: ecoPriSecRoadsLayer, meta:null},
		{layerId:'aa-hl-eco-house-hot-spot-2010', type: 'layer', item: ecoHouseHotSpot2010Layer, meta:null},
		{layerId:'aa-hl-eco-house-2010', type: 'layer', item: ecoHouse2010Layer, meta:null},
		{layerId:'aa-hl-eco-pop-hot-spot-2010', type: 'layer', item: ecoPopHotSpot2010Layer, meta:null},
		{layerId:'aa-hl-eco-pop-2010', type: 'layer', item: ecoPop2010Layer, meta:null},
		{layerId:'aa-hl-eco-sewer', type: 'layer', item: ecoSewerLayerLayer, meta:null},
	]
}