import React
,
{ 
	//useRef, 
	//useEffect,
	//useState 
} 
from "react";
import { connect } from "react-redux";


//import AHPIntro from "./AHPIntro";
//import AHPVariables from "./AHPVariables";
//import AHPMatrix from "./AHPMatrix";
import { CalciteModal, CalciteButton } from "@esri/calcite-components-react";

import AHPTool from ".";

/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const AHPModal = ({active, CloseModal}) => {
	
	return (
		<CalciteModal active={active || undefined} onCalciteModalClose={CloseModal}>

			<div slot="header" id="modal-title">Analytical Hierarchy Process (AHP)</div>
    		<div slot="content">
				<AHPTool CloseModal={CloseModal} />
			</div>
			<CalciteButton slot="back" color="neutral" icon="chevron-left" width="full"onClick={CloseModal}>Cancel</CalciteButton>
		</CalciteModal>
	)
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(AHPModal);

/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/