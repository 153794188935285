//import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
/*
import { lulcImpDesc, lulcNlcd, lulcUrbanImp } from "./lulcGroupCompilation";
*/
const thisId = 'aa-hl-lulc';
export const plLulc = {
	id: thisId,
	title:"Land Use / Land Cover ",
	type:'group',
	description:`The National Land Cover Database (NLCD) data provides data on land cover and land cover change at a 30m resolution with a land use classification based on a modified Anderson Level II classification system.`,
	items: [{
		layerKey: 'pineLULC-2001',
		server: 'terrene',
		title: 'Land use/land cover 2001',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 0,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2004',
		server: 'terrene',
		title: 'Land use/land cover 2004',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 1,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2006',
		server: 'terrene',
		title: 'Land use/land cover 2006',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 2,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2008',
		server: 'terrene',
		title: 'Land use/land cover 2008',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 3,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2011',
		server: 'terrene',
		title: 'Land use/land cover 2011',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 4,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2013',
		server: 'terrene',
		title: 'Land use/land cover 2013',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 5,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}, {
		layerKey: 'pineLULC-2016',
		server: 'terrene',
		title: 'Land use/land cover 2016',
		folder: 'SEFEA_Pinelands',
		mapServer: 'Pinelands_National_Land_Cover_Database',
		layerId: 6,
		parentDiv: 'pineLULC',
		defExp: '1=1',
		isRasterLayer: true,
		hasFeatureServer: false,
		detailsText: ''
	}
].map( (floodmapperObj) => {
		return {
			layerId: `aa-pl-lulc-id-${floodmapperObj.layerKey}`,
			type: 'layer',
			item: new MapImageLayer({
				url: `https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/${floodmapperObj.folder}/${floodmapperObj.mapServer}/MapServer/`
				, sublayers: [{id:floodmapperObj.layerId}]
				, title: floodmapperObj.title
			}),
			meta:null}
	})
}