import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Portal from "@arcgis/core/portal/Portal";
//import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
import { CalciteButton, CalciteNotice, CalciteAvatar, CalcitePanel, CalcitePickList, 
	//CalcitePickListGroup, 
	CalcitePickListItem, CalciteAction, CalciteCombobox, CalciteComboboxItem } from "@esri/calcite-components-react";

import {layerListAddCustomLayer} from '../../../../../../Flux/actions';
import {loadLayer} from '../../../../../../Flux/thunks';
import Layer from "@arcgis/core/layers/Layer";

const initialSettings = {
	num: 10,
	start: 1,
	total: 0,
	nextStart: -1,
	folder: null,
};
const ArcGISOnlinePortalSignedInPanel = ( {addLayerToMapLayerModule, addLayerToMap} ) => {
	//user info is a [https://developers.arcgis.com/javascript/latest/api-reference/esri-portal-PortalUser.html#fetchItems] object
	const [userInfo, SetUserInfo] = useState(null);

	const [useGroup, SetUseGroup] = useState("main");


	const allowedItemsOnly = [
		'Feature Service',
		'Map Service',
		'Image Service',
		'KML',
		'KML Collection',
		'WMS',
		'WFS',
		'WMTS',
		'OGCFeatureServer',
		'Feature Collection',
		'Feature Collection Template',
		'Geodata Service',
		'Globe Service',
		'Vector Tile Service',
		'Scene Service',
		'Relational Database Connection',
		'Oriented Imagery Catalog',
		//Special items
		'Web Map'
	];

	//Items
	/*
	const [items, SetItems] = useState({
		...initialSettings,
		useFolder: null,
		currentItems: null
	});
	*/

	const [isLoading, SetIsLoading] = useState(false);
	const [cachedPortalItems, SetCachedPortalItems] = useState({});

	//Folders can't go into subfolders.
	//Also there is no limit on folders
	const [folders, SetFolders] = useState(null);

	//Tags
	//Also there is no limit on tags
	const [tags, SetTags] = useState(null);

	//Groups
	//Also there is no limit on groups
	const [groups, SetGroups] = useState(null);






	const SignOutOfArcGISPortal = () => {
		esriId.destroyCredentials();
	}
	const AddPortalItemToMap = (portalItem, _event) => {
		console.log('Adding portal item to map: ', {
			portalItem,
			portalId: portalItem.id
		});
		const usePortalId = portalItem.id;

		if( portalItem.isLayer ) {
			const portalLayer = Layer.fromPortalItem({
				portalItem:{id:usePortalId}
			});
			//Currently if we're not conscious about loading stuff, the title may not load. 
			 portalLayer.then((returnLayer) => {
				//Loads the resources referenced by this class. (https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-Layer.html#load)
				returnLayer.load().then( (newLayer)=> {
					newLayer.when(() => {
						if(newLayer.createPopupTemplate ) {
							newLayer.popupTemplate = newLayer.createPopupTemplate();
						}

						addLayerToMapLayerModule(usePortalId, newLayer);
						addLayerToMap(newLayer);
					});
				});
			});
		}else if( portalItem.type === 'Web Map' ) { 
			console.warn('This is a web map. We will try to grab all layers together into a Group Layer, but it is not guarenteed');

		}

	}
/*
	const PopulateItem = async () => {
		console.log(useGroup, userInfo, cachedPortalItems);


		if( 
			cachedPortalItems[useGroup] 
		) {
			if( !cachedPortalItems[useGroup].items  ) {
				//Nothing yet.

				await userInfo.fetchItems({
					folder: cachedPortalItems[useGroup].source,
					start: initialSettings.start,
					num: initialSettings.num
				})
				.then((items) => {
					console.log('Items found for new area: ', items);
					cachedPortalItems[useGroup] = {
						...cachedPortalItems[useGroup],
						settings: {
							...cachedPortalItems[useGroup].settings,
							nextStart: items.nextStart
						},
						items: items.items
					}
					SetCachedPortalItems(cachedPortalItems);
				})
			}else{
				//asking for more.
			}
			//SetCachedPortalItems(cachedPortalItems);
		}

	}
*/
	useEffect( () => {


		const portal = new Portal({
			canSignInIDP: true,
			canSignInArcGIS: true,

			portalMode: "multitenant",
		}); 
		portal.authMode = "immediate";//"auto"; 
		portal.load()
		.then(async (response) => {
			
			console.log('Wow, got some kind of resposne: ', response);
			const myUserInfo = response.user;
			const initialCachedPortalItems={};
			SetUserInfo(myUserInfo);
			
			//Set the initial state
			//Content Items

			await myUserInfo.fetchItems({
				folder: null,
				start: initialSettings.start,
				num: initialSettings.num
			})
			.then((items) => {
				console.log('Good items', items)
				

				initialCachedPortalItems.main = {
					isFolder:true,
					isGroup:false,
					source:null,
					settings: {
						...initialSettings,
						nextStart: items.nextStart
					},
					items: items.items
				};

			 })
			.catch((items) => {
				console.log('Bad items ', items);
				
			});

			//Folders
			//Folders cannot have subfolders.
			await myUserInfo.fetchFolders()
			.then((folders) => {
				SetFolders(folders)
				folders.forEach((folderItem) => {
					initialCachedPortalItems[folderItem.id] = {
						isFolder:true,
						isGroup:false,
						source:folderItem,
						settings: {
							num: 10,
							start: 1,
							nextStart: 1
						},
						items: null
					};
				});
			})
			.catch((folders) => {
				console.log('Could not retrieve folders for this user ', folders);
			});


			//Groups
			//There seems to be no limit on returned groups, so we won't need to continuously update it.
			await myUserInfo.fetchGroups()
			.then((groups) => {
				SetGroups(groups);
				groups.forEach((groupItem) => {
					initialCachedPortalItems[groupItem.id] = {
						isFolder:false,
						isGroup:true,
						source:groupItem,
						settings: {
							num: 10,
							start: 1,
							nextStart: -1
						},
						items: null
					};
				});
			})
			.catch((groups) => {
				console.log('Could not retrieve groups for this user ', groups);
			});	
			
			//Tags
			//There seems to be no limit on returned groups, so we won't need to continuously update it.
			await myUserInfo.fetchTags()
			.then((tags) => {
				SetTags(tags);
			})
			.catch((tags) => {
				console.log('Could not retrieve tags for this user ', tags);
			});	

			SetCachedPortalItems(initialCachedPortalItems);
			
		})
		.catch((response) => {
			
			console.log('Bad response, but still a response', response);
		});
	}, [] );
	

	//Appeasing the warning.
	console.log(tags);

	useEffect( () => {
		console.log('Use group changed: ', useGroup);
		//if "Your Portal"
		if( !isLoading ) { return; }
		
		const GetUpdatedItems = async () => {
			if( !cachedPortalItems[useGroup] ) {
				return;
			}
			if( cachedPortalItems[useGroup].isFolder) {
				if( cachedPortalItems[useGroup].settings.nextStart !== -1  ) {
					console.log('User Folder Settings: ', cachedPortalItems[useGroup].settings);
					await userInfo.fetchItems({
						folder: cachedPortalItems[useGroup].source,
						start: cachedPortalItems[useGroup].settings.nextStart,
						num: cachedPortalItems[useGroup].settings.num
					})
					.then((portalArray) => {
						//console.log('User Folder Items: ', portalArray, cachedPortalItems[useGroup].items);

						const itemList = cachedPortalItems[useGroup].items;
						cachedPortalItems[useGroup] = {
							...cachedPortalItems[useGroup],
							settings: {
								...cachedPortalItems[useGroup].settings,
								nextStart: portalArray.nextStart
							},
							items: (itemList ? cachedPortalItems[useGroup].items.concat(portalArray.items) : portalArray.items)
						}

						//console.log('Total item: ', cachedPortalItems[useGroup]);
						SetCachedPortalItems(cachedPortalItems);
						SetIsLoading(false);
					});
				}
			}else if( cachedPortalItems[useGroup].isGroup) {
				await cachedPortalItems[useGroup].source.queryItems()
				.then((items) => {
					console.log('Group Items: ', items);
					cachedPortalItems[useGroup] = {
						...cachedPortalItems[useGroup],
						items: items.results
					}
					SetCachedPortalItems(cachedPortalItems);
				});
			}

			SetIsLoading(false);
		}
		GetUpdatedItems();
	}, [isLoading, useGroup, cachedPortalItems, userInfo] );



	if(!userInfo) {
		return null;
	}

	const UpdateGroupSelection = (comboBoxEvent) => {
		console.log('Changing combo box: ', comboBoxEvent);
		//should have .detail.selectedItems[]
		if( !comboBoxEvent.detail.selectedItems 
			|| !Array.isArray(comboBoxEvent.detail.selectedItems)
			|| comboBoxEvent.detail.selectedItems.length === 0 ) {
			return;
		}
		const firstItem = comboBoxEvent.detail.selectedItems[0];
		SetUseGroup(firstItem.value);
		SetIsLoading(true);

	}
	const GetPortalSection = () => {

		if (isLoading ) {
			
			return  <CalcitePickList>
				Loading...
			</CalcitePickList>;
		}

		return  <CalcitePickList>
			{
				cachedPortalItems[useGroup] &&
				cachedPortalItems[useGroup].items
				? (
					cachedPortalItems[useGroup].items.length ? 
					cachedPortalItems[useGroup].items.map( (portalItem, key) => {
						return <CalcitePickListItem key={key}
						label={portalItem.title} 
						description={portalItem.description}
						>
							{
								allowedItemsOnly.includes(portalItem.type)
								?
								<CalciteAction slot="actions-end" icon="add-layer"
							onClick={ (_e) => { 
								//SearchServer(layers.url); 
								AddPortalItemToMap(portalItem);
							}}
							> </CalciteAction>
							: <CalciteAction disabled={true} slot="actions-end" icon="no-map"
							onClick={ (_e) => { 
								
							}}
							> </CalciteAction>

							}
							
						</CalcitePickListItem>
					}) : 'No items found...'
				)
				: 'Loading...'
			}
			{
				cachedPortalItems[useGroup] &&
				cachedPortalItems[useGroup].settings &&
				cachedPortalItems[useGroup].settings.nextStart !== -1
				?
				<CalcitePickListItem 
						label="Load More..."
						description="Loads more items from your portal/folder"
						>
							<CalciteAction slot="actions-end" icon="plus-circle"
							onClick={ (_e) => { 
								SetIsLoading(true);
							}}
							> </CalciteAction>
						</CalcitePickListItem>
				: null

			}
		</CalcitePickList>;

	}





	return (
		<>
		<div className="content-area" style={{minHeight: '50vh'}}>
			<div style={{marginBottom:'1em'}}>
				<CalciteNotice active={true} color="yellow" style={{marginBottom:'1em'}}>
					<div slot="title">In Beta</div>
					<div slot="message">The ArcGIS Online Portal reader is in beta.</div>
				</CalciteNotice>
				<CalciteButton width="full"
					color="red"
					onClick={ (_e) => { SignOutOfArcGISPortal(); }}
					iconStart="sign-out"
				>Log Out </CalciteButton>
			</div>

			<CalciteCombobox selectionMode="single" onCalciteComboboxChange={UpdateGroupSelection} value="main">
				<CalciteComboboxItem value="main" textLabel="Your Portal" selected={useGroup === 'main'}></CalciteComboboxItem>
				{
					folders ? folders.map( (folderItem, key) => {
						return <CalciteComboboxItem key={key} value={folderItem.id} textLabel={`Your Portal / ${folderItem.title}`} ></CalciteComboboxItem>
					}) : null
				}
				{
					groups ? groups.map( (groupItem, key) => {
						return <CalciteComboboxItem key={key} value={groupItem.id} textLabel={`${groupItem.title}`} ></CalciteComboboxItem>
					}) : null
				}
			</CalciteCombobox>


			<CalcitePanel heading="From Portal">
				<CalciteAvatar style={{marginTop:'.75em'}} slot="header-actions-end" 
				username={userInfo.username}
				fullName={userInfo.fullName}
				thumbnail={userInfo.getThumbnailUrl(150)}></CalciteAvatar>
				{GetPortalSection()}
			</CalcitePanel>
				
		</div>		
		</>
	);
}

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	addLayerToMapLayerModule: (layerKey, layerItem) =>{dispatch(layerListAddCustomLayer(layerKey, layerItem))},
	addLayerToMap: (layer) => {dispatch(loadLayer(layer))},
});

export default connect(mapStateToProps, mapDispatchToProps)(ArcGISOnlinePortalSignedInPanel);
