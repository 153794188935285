import {
	LOAD_MAP_IN_PROGRESS,
	LOAD_MAP_FAILURE,
	LOAD_MAP_SUCCESS,
	URL_PARAMS_SET, 
	LOAD_LAYER_IN_PROGRESS,
	LOAD_LAYER_SUCCESS,
	LOAD_LAYER_FAILURE,

	CREATE_ALERT,
	REMOVE_ALERT,
	CLEAR_ALERTS,
	CLOSE_ALERT,


	CUSTOM_LAYER_STORAGE_IS_LOADING,
	CUSTOM_LAYER_STORAGE_LOAD_SUCCESSFUL,
	CUSTOM_LAYER_STORAGE_LOAD_FAILED,
	CUSTOM_LAYER_STORAGE_RESET,
	
	CUSTOM_LAYER_STORAGE_ADD,
	CUSTOM_LAYER_STORAGE_ADD_SERVICES,
	CUSTOM_LAYER_STORAGE_ADD_FOLDERS,
	CUSTOM_LAYER_STORAGE_ADD_LAYERS,


	SERVER_BROWSER_ADD_PANEL,
	SERVER_BROWSER_REMOVE_PANEL,
	SERVER_BROWSER_CLEAR_ALL_PANELS,

	SERVER_BROWSER_IS_LOADING,
	SERVER_BROWSER_LOAD_SUCCESSFUL,
	SERVER_BROWSER_LOAD_FAILED,
	SERVER_BROWSER_ADD_CACHE,
	
	LAYER_ADDED_TO_LIST,
	LAYER_REMOVED_FROM_LIST,
	LAYER_LIST_READ,
	LAYER_LIST_ADD_CUSTOM_LAYER,
	LAYER_LIST_ADD_AHP_LAYER,

	GRAPH_IS_INITIALIZING,
	GRAPH_IS_READY,
	GRAPH_IS_NOT_READY,
	GRAPH_OPENED,
	GRAPH_CLOSED,
	GRAPH_IS_RENDERED,
	GRAPH_IS_RENDERING,
	
	GRAPH_IS_RETRIEVING_DATA,
	GRAPH_LOAD_SUCCESSFUL,
	GRAPH_LOAD_FAILED,
	UPDATE_TIP,
	CLEAR_TIP,

	//AHP Raster
	AHP_RASTER_START_LOADING,
	AHP_RASTER_DONE_LOADING,
	AHP_RASTERS_LOADING,

} from './actions';


export const graphingOptions = (state={
	isInitializing: false,
	isReady: false,
	isGraphOpen: false,
	isRendered: false,

	controlOptions: {},
	chartObject: {} //read directly by Highcharts or something
}, action) => {
	const {type
		, payload
	} = action;
	
	switch( type ) {
		case GRAPH_IS_RETRIEVING_DATA: {
			return state;
		}
		case GRAPH_LOAD_SUCCESSFUL: {
			const {chartObject} = payload;
			return {...state, isRendered:false, chartObject};
		}
		case GRAPH_LOAD_FAILED: {
			return state;
		}
		
		case GRAPH_IS_RENDERED:{
			return {...state, isRendered:true}
		}
		case GRAPH_IS_RENDERING:{
			return {...state, isRendered:false}
		}

		case GRAPH_CLOSED:{
			return {...state, isGraphOpen: false, isRendered:false}
		}
		case GRAPH_OPENED:{
			return {...state, isGraphOpen: true, isRendered:false}
		}
		case GRAPH_IS_INITIALIZING: {

			return {...state,
				isInitializing:true,
				isReady: false,
				isGraphOpen: false
			};
		}
		case GRAPH_IS_READY: {
			const {controlOptions} = payload;
			return {...state,
				isInitializing:false,
				isReady: true,
				isGraphOpen: false,
				controlOptions
			};
		}
		case GRAPH_IS_NOT_READY: {

			return {...state,
				isInitializing:false,
				isReady: false,
				isGraphOpen: false,
			};
		}
		default: {
			return state;
		}
	}
}




//initial state matches UrlOptionsLogic.js
export const getUrlOptions = (state = {
		origin: `${window.origin}/`,
		params: null,
		isMinimal: false
	}, action ) =>{
	const {type
		, payload
	} = action;

	switch( type ) {
		case URL_PARAMS_SET: {
			const {urlOptions} = payload;
			console.log('Updating url params: ', URL_PARAMS_SET);
			return state=urlOptions;
		}
		default: {
			return state;
		}
	}
}


export const isMapLoading = (state = true, action ) =>{
	const {type
		//, payload
	} = action;

	switch( type ) {
		case LOAD_MAP_IN_PROGRESS: {
			return true;
		}
		case LOAD_MAP_SUCCESS: 
		case LOAD_MAP_FAILURE: {
			return false;
		}
		default: {
			return state;
		}
	}
}

export const isLayerLoading = (state = false, action ) =>{
	const {type
		//, payload
	} = action;

	switch( type ) {
		case LOAD_LAYER_IN_PROGRESS: {
			return true;
		}
		case LOAD_LAYER_SUCCESS: 
		case LOAD_LAYER_FAILURE: {
			return false;
		}
		default: {
			return state;
		}
	}
}


export const isRasterLayerLoading = (state = [], action ) =>{
	const {
		type, payload
	} = action;

	switch( type ) {
		case AHP_RASTER_START_LOADING: {
			const {id} = payload;
			if( state.find( (findId) => { 
				return findId === id;
			}) ) {
				//Already added
				return state;
			}
			const newState = [...state, id];
			return newState;
		}
		case AHP_RASTER_DONE_LOADING: {
			const {id} = payload;
			return state.filter( (findId) => {
				//We want everything e
				return !( findId === id ) ;
			});
		}
		case AHP_RASTERS_LOADING: {
			return state;
		}
		
		
		default: {
			return state;
		}
	}
}

export const arcgisMap = (state = {}, action ) =>{
	const {type, payload} = action;

	switch( type ) {
		case LOAD_MAP_SUCCESS: {
			return payload.view;
		}
		default: {
			return state;
		}
	}
}

const _searchGroupForLayerIds = (groupItem)=>{
	//console.log(groupItem);
	groupItem.forEach( (item,idx) => {

		if(item.type === 'group' || item.type === 'group-set') {
			return _searchGroupForLayerIds(item.items);
		}
		//console.log('To [_applyLayerIdToLayer]', item);

		//if this item has sublayers
		if( item.containsSublayers && item.containsSublayers.sublayerItems) {
			item.containsSublayers.sublayerItems = item.containsSublayers.sublayerItems.map( (sublayerItem) =>{ 
	
				return {...sublayerItem,
					item: _applyLayerIdToLayer({layerId: sublayerItem.layerId}, sublayerItem.item)
				};
			});
		}


		return {...item,
			item: _applyLayerIdToLayer({layerId: item.layerId}, item.item)
		};
	})	
	return groupItem;
}
const _applyLayerIdToLayer = (ora, item)=>{
	//console.log('[_applyLayerIdToLayer]', item, ora);
	item.ora = ora;
	return item;
}

export const availableLayers = (state= [], action ) => {
	
	const {type, 
		payload
	} = action;
	switch( type ) {
		case LAYER_LIST_ADD_CUSTOM_LAYER : {
			const {layerId, layerItem} = payload;
			console.log('Action: [LAYER_LIST_ADD_CUSTOM_LAYER]', {layerId, layerItem});

			layerItem.ora = {layerId, custom: true, isPortal: (layerItem.portalItem ? true : false), noTrim: layerItem.noTrim || null};
			return state.map( (findGroup) => {
				if( findGroup.id !== 'custom' ) {
					return findGroup;
				}

				if( findGroup.items.some( (findLayer) => {
					return findLayer.ora && findLayer.ora.layerId === layerId;
				}) === false ){
					findGroup.items.push(
						{layerId, type: 'layer', item: layerItem, meta: null } 
					);
				}
				return findGroup;

			});
		}
		case LAYER_LIST_ADD_AHP_LAYER : {
			const {layerId, layerItem, rasterRenderer, maskRenderer} = payload;
			console.log('Action: [LAYER_LIST_ADD_AHP_LAYER]', {layerId, layerItem, rasterRenderer});

			layerItem.ora = {layerId, custom: true, rasterRenderer: rasterRenderer, 
				export: maskRenderer,
				noTrim: layerItem.noTrim || null,
			
				ahpServiceUrl: layerItem.url,
				title: layerItem.title || 'N/A'
			};
			return state.map( (findGroup) => {
				if( findGroup.id !== 'custom' ) {
					return findGroup;
				}

				if( findGroup.items.some( (findLayer) => {
					return findLayer.ora && findLayer.ora.layerId === layerId;
				}) === false ){
					findGroup.items.push(
						{layerId, type: 'layer', item: layerItem, meta: null, downloadLink: layerItem.url   } 
					);
				}
				return findGroup;

			});
		}
		case LAYER_LIST_READ: {
			const {layerCompilation} = payload;
			return _searchGroupForLayerIds(layerCompilation);
		}
		default: {
			return state;
		}
	}
}

export const layerList = (state = [], action ) =>{
	const {type, 
		payload
	} = action;

	switch( type ) {
		case LAYER_ADDED_TO_LIST: {
			const {layer} = payload;
			if( state.some( (findLayer) => {
				return findLayer.id === layer.id;
			}) ) {
				return state;
			}

			return state.concat(layer);
		}
		case LAYER_REMOVED_FROM_LIST: {
			const {layer} = payload;

			return state.filter( (findLayer) => {
				return findLayer.id !== layer.id;
			})
		}
		default: {
			return state;
		}
	}
}

//Set up a tip item
export const tipItem = (state = {active: false}, action ) =>{
	const {type, payload} = action;

	switch( type ) {
		case UPDATE_TIP:{
			const {layerTitle, layerDescription, mapService, authoritiveSource} = payload;
			return {
				active: true,
				layerTitle, layerDescription, mapService, authoritiveSource
			};
		}
		case CLEAR_TIP:{
			return {active: false};
		}
		default: {
			return state;
		}
	}
}
//Now we'll set up a place to push alerts

export const alertList = (state = [], action ) =>{
	const {type, payload} = action;

	switch( type ) {
		case CREATE_ALERT: {
			const {color, title, message} = payload;
			const key = state.length;

			return state.concat([{color, title, message, key, isRead: false}]);
		}
		case CLOSE_ALERT: {
			return state.map( (alertItem,i) => { 
				if( alertItem.key === payload.key ) {
					alertItem.isRead=true;
				}
				return alertItem;
			});
		}
		case CLEAR_ALERTS: {
			return [];
		}
		case REMOVE_ALERT: {
			//This will probably be removed.
			return state.filter( (elem,i) => { 
				return i !== 0;
			});
		}
		default: {
			return state;
		}
	}
}


export const isCustomServiceLoading = ( state = false, action ) => {
	const {type
		//, payload
	} = action;

	switch( type ) {
		case CUSTOM_LAYER_STORAGE_IS_LOADING: {
			return true;
		}
		case CUSTOM_LAYER_STORAGE_LOAD_SUCCESSFUL: 
		case CUSTOM_LAYER_STORAGE_LOAD_FAILED: {
			return false;
		}
		default: {
			return state;
		}
	}
}


const _recursiveServerBrowserSearch = (itemInfo, arcserviceUrl, applyUpdatedItems) => {
	
	//console.log('Testing itemInfo: ', itemInfo);

	if( itemInfo.url === arcserviceUrl ) {
		console.log('We found the parent!');
		const  {folders, services, layers} = applyUpdatedItems;
		//Apply update items to array
		itemInfo.items = [];
		
		folders.forEach( (folderItem) => {
			itemInfo.items.push({
				type: 'folder',
				url: `${arcserviceUrl}${folderItem}`,
				name: folderItem,
				items: null
			});
		});

		services.forEach( (serviceItem) => {
			itemInfo.items.push({
				type: 'service',
				url: `${arcserviceUrl}${serviceItem.name}/${serviceItem.type}`,
				name: serviceItem.name,
				serverType: serviceItem.type,
				items: null
			});
		});
		

		layers.forEach( (layerItem) => {
			itemInfo.items.push({
				type: 'layers',
				name: `${arcserviceUrl}${layerItem.name}`,
				layerId: layerItem.id,
				layer: null
			});
		});

		return itemInfo;
	}

	if( itemInfo.items && Array.isArray(itemInfo.items) ) {
		itemInfo.items = itemInfo.items.map( (thisItem) => {
			return _recursiveServerBrowserSearch( thisItem, arcserviceUrl, applyUpdatedItems);
		});
	}

	return itemInfo;
}

export const customServerBrowser = ( state = [], action ) => {
	const {type
		, payload
	} = action;

	switch( type ) {
		case CUSTOM_LAYER_STORAGE_RESET: {
			return [];
		}
		case CUSTOM_LAYER_STORAGE_ADD:{

			const  {parent, arcserviceUrl, folders, services, layers} = payload;
			//console.log('Add to the custom server browser', payload);
			const myBranch = [];
			if( parent ) {
				//Find the parent
				//console.log('Parent found', parent);
				//URLs should be unique across, so we should be able to find this.
				const newState = state.map( (item) => {
					return _recursiveServerBrowserSearch(item, parent.url, {folders, services, layers} );
				});

				//console.warn('RETURNING THE STATE FOR NOW, RETURN NEW STATE WHEN CHECKED OUT.', newState);
				return newState;
			}else{
				//start with folders first.
				folders.forEach( (folderItem) => {
					myBranch.push({
						type: 'folder',
						url: `${arcserviceUrl}${folderItem}`,
						name: folderItem,
						items: null
					});
				});

				services.forEach( (serviceItem) => {

					myBranch.push({
						type: 'service',
						url: `https://njmaps1.rad.rutgers.edu/arcgis/rest/services/${serviceItem.name}`,
						name: serviceItem.name,
						serverType: serviceItem.type,
						items: null
					});
				});
				

				layers.forEach( (layerItem) => {
					myBranch.push({
						type: 'layers',
						name: `${arcserviceUrl}${layerItem.name}`,
						layerId: layerItem.id,
						layer: null
					});
				});
				
				return myBranch;
			}
		} 

		//Might not use these?
		case CUSTOM_LAYER_STORAGE_ADD_SERVICES: 
		case CUSTOM_LAYER_STORAGE_ADD_FOLDERS: 
		case CUSTOM_LAYER_STORAGE_ADD_LAYERS: 
		default: {
			//console.log('Appeasing compiler: ', payload);
			return state;
		}
	}
}




export const isArcServerServiceLoading = ( state = false, action ) => {
	const {type
		//, payload
	} = action;

	switch( type ) {
		case SERVER_BROWSER_IS_LOADING: {
			return true;
		}
		case SERVER_BROWSER_LOAD_SUCCESSFUL: 
		case SERVER_BROWSER_LOAD_FAILED: {
			return false;
		}
		default: {
			return state;
		}
	}
}

export const serverBrowser = ( state = [], action ) => {
	const {type
		, payload
	} = action;
	switch( type ) {
		case SERVER_BROWSER_ADD_PANEL: {
			const  {cacheObj} = payload;

			return state.concat(cacheObj);
		}
		case SERVER_BROWSER_REMOVE_PANEL: {
			return state.slice(0,-1);
		}
		case SERVER_BROWSER_CLEAR_ALL_PANELS: {
			return [];
		}
		default: {
			//console.log('Appeasing compiler: ', payload);
			return state;
		}
	}
}
export const serverBrowserCache = ( state = {}, action ) => {
	const {type
		, payload
	} = action;

	switch( type ) {
		case SERVER_BROWSER_ADD_CACHE:{
			const  {arcserviceUrl, cacheObj} = payload;
			return {
				...state,
				[arcserviceUrl]: cacheObj
			}
			/*
			const  {arcserviceUrl, folders, services, layers} = payload;
			//console.log('Add to the custom server browser', payload);
			const myBranch = [];
				//start with folders first.
				folders.forEach( (folderItem) => {
					myBranch.push({
						type: 'folder',
						url: `${arcserviceUrl}${folderItem}`,
						name: folderItem,
						items: null
					});
				});

				services.forEach( (serviceItem) => {

					myBranch.push({
						type: 'service',
						url: `https://njmaps1.rad.rutgers.edu/arcgis/rest/services/${serviceItem.name}`,
						name: serviceItem.name,
						serverType: serviceItem.type,
						items: null
					});
				});
				

				layers.forEach( (layerItem) => {
					myBranch.push({
						type: 'layers',
						name: `${arcserviceUrl}${layerItem.name}`,
						layerId: layerItem.id,
						layer: null
					});
				});
				
				return {
					...state,
					[arcserviceUrl]: myBranch
				}
				*/
		} 

		//Might not use these?
		case CUSTOM_LAYER_STORAGE_ADD_SERVICES: 
		case CUSTOM_LAYER_STORAGE_ADD_FOLDERS: 
		case CUSTOM_LAYER_STORAGE_ADD_LAYERS: 
		default: {
			//console.log('Appeasing compiler: ', payload);
			return state;
		}
	}
}