import React
,{ 
	useState
} 
from "react";
import { connect } from "react-redux";
import { CalciteModal, CalciteButton, CalciteIcon } from "@esri/calcite-components-react";

import nifaLogo from '../../../../Common/images/nifa_logo.png';
import usdaLogo from '../../../../Common/images/USDA_logo.png';
import njaesLogo from '../../../../Common/images/njaes-logo.jpg';
/**
 * 
 * @param {string} useLayerSet is the group id from allLayersJson.js / availableLayers from the store. We do this so save / share / print can reload properly.
 * @returns {React.Component}
 */
const IntroModal = () => {
	const localStorageKey = 'sefea-intro-expires';
	const [showModal, setShowModal] = useState(
		(
			window.localStorage.getItem(localStorageKey)
			? (
				( Date.now() <= window.localStorage.getItem(localStorageKey) )
				? undefined : true
			)
			: true
		)
		);

	const CloseModal = () => {
		let currTime = Date.now();
		let increaseTime = (3600 * 1000); // 1 hour:  (3600 * 1000)

		window.localStorage.setItem(localStorageKey, currTime + increaseTime );
		setShowModal(false);
		return true;
	}

	console.log(window.localStorage.getItem(localStorageKey));

	return (
		<CalciteModal active={showModal || undefined} onCalciteModalClose={CloseModal}>
			<div slot="header" >
				<strong>Spatially Explicit Forest Ecological Assessment (SEFEA)</strong> <br /> Decision Support Tool to Aid in Forest Management and Land Use Planning: New Jersey as a Model
			</div>
			<div slot="content">

				<h3 className="small-margin-bottom no-margin-top">Application Help &amp; Information</h3>
				<p>The Spatially Explicit Forest Ecological Assessment (SEFEA) Decision Support Tool is designed and created to provide a user-friendly visualization tool to assist forest and land use decision making. The purpose of the SEFEA is to help forest and land management in taking ecologically enhanced approach to multipurpose forest management decisions.</p>
				<div>
					<p>You can:</p>
					<ul>
						<li>Find a specific site location on the map using the address or interactively by zooming and panning.</li>
						<li>Display various geospatial information related to that location by turning layers on or off.</li>
						<li>Click on a location and an information window will pop-up with relevant attributes pertaining to the location. </li>
						<li>Select the <CalciteIcon icon="information" scale="s" ></CalciteIcon> (Information) for more specific navigation instructions.</li>
					</ul>					
				</div>

				<h3 className="small-margin-bottom no-margin-top">Disclaimer</h3>
				<p>The data and maps in this tool are provided “as is,” without warranty to their performance, merchantable state, or fitness for any particular purpose. The entire risk associated with the results and performance of these data is assumed by the user. This tool should be used strictly as a planning reference tool and not for navigation, permitting, or other legal purposes.</p>
				<p><em>This work is/was supported by the USDA National Institute of Food and Agriculture McIntire-Stennis project accession number 17390 through the New Jersey Agricultural Experiment Station, USDA-NIFA McIntire-Stennis Forestry Research Program. &copy; 2022.</em></p>

				<div style={{textAlign:'center'}}>
					<img src={nifaLogo} style={{verticalAlign:'middle', maxWidth:'100px', marginLeft: '1rem', amrginRight: '1rem'}} alt={"NIFA Logo"} />
					<img src={usdaLogo} style={{verticalAlign:'middle', maxWidth:'100px', marginLeft: '1rem', amrginRight: '1rem'}}  alt={"USDA Logo"}/>
					<img src={njaesLogo} style={{verticalAlign:'middle', maxWidth:'160px', marginLeft: '1rem', amrginRight: '1rem'}} alt={"Rutgers NJAES Logo"} />
				</div>

			</div>
			<CalciteButton slot="primary" color="neutral" icon="chevron-left" width="full" onClick={CloseModal}>Close Window</CalciteButton>
		</CalciteModal>
	)
};

const mapStateToProps = (state) => ({
	
});
// what actions are we allowed to use
const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroModal);

/*
		<CalciteModal aria-labelledby="modal-title" active fullscreen>
    <div slot="header" id="modal-title">Modal title</div>
    <div slot="content">Modal content lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
    <CalciteButton slot="back" color="neutral" icon="chevron-left" width="full">Back
    </CalciteButton>
    <CalciteButton slot="secondary" width="full" appearance="outline">Cancel</CalciteButton>
    <CalciteButton slot="primary" width="full">Save</CalciteButton>
</CalciteModal>
*/