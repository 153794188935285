
import RasterFunction from "@arcgis/core/layers/support/RasterFunction";
//import ImageryLayer from "@arcgis/core/layers/ImageryLayer";

//const AHP_BASE_URL = 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Test_AHP_Rasters';
export const AHP_MOSAIC_URL = 'https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/Highlands_AHP/AHP_Mosaic/ImageServer';
export const AHP_MOSAIC_TEMPLATE_URL = '';



export const getAhpMosaicUrl = (region, analysisClass) => {
	let analysisClassSerivceName = null;
	let regionServiceName = null;
console.log({region, analysisClass})
	switch(region) {
		case 'highlands-region':
			regionServiceName='Highlands';
			break;
		case 'pinelands-region':
			regionServiceName='Pinelands';
			break;
		default:
			break;
	}
	
	switch(analysisClass) {
		case 'priority-based':
			analysisClassSerivceName='Priority';
			break;
		case 'distance-based':
			analysisClassSerivceName='Distance';
			break;
		case 'attribute-based':
			analysisClassSerivceName='Attribute';
			break;
		case 'cluster-based':
			analysisClassSerivceName='Cluster';
			break;
		default:
			break;
	}


	return `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA_AHP_Mosaics/AHP_${analysisClassSerivceName}_${regionServiceName}/ImageServer`
}

//Please only go one group down if necessary
export const AHPVariablesByAnalysisClass = {
	'priority-based':  {
		order: null,
		//variables: HIGHLANDS_REGION_VARIABLES
		variables: [
			//highlands
			{key: 'priority-lands-for-agricultural-preservation', region: 'highlands-region', label: 'Priority Lands for Agricultural Preservation', rasterId:1, selected: false, },
			{key: 'conservation-priority-areas', label: 'Conservation Priority Areas', region: 'highlands-region', rasterId:2, selected: false, },
			{key: 'ecosystems-to-protect', label: 'Ecosystems to Protect', region: 'highlands-region', rasterId:3, selected: false, },
			{key: 'priority-lands-for-water-quality-protection', label: 'Priority Lands for Water Quality Protection', region: 'highlands-region', rasterId:4, selected: false, },
			{key: 'community-green-space', label: 'Community Green Space', region: 'highlands-region', rasterId:5, selected: false, },
			//pinelands
			{key: 'priority-lands-for-agricultural-preservation', region: 'pinelands-region', label: 'Priority Lands for Agricultural Preservation', rasterId:1, selected: false, },
			{key: 'ecosystems-to-protect', label: 'Ecosystems to Protect', region: 'pinelands-region', rasterId:2, selected: false, },
			{key: 'priority-lands-for-water-quality-protection', label: 'Priority Lands for Water Quality Protection', region: 'pinelands-region', rasterId:3, selected: false, },
			{key: 'community-green-space', label: 'Community Green Space', region: 'pinelands-region', rasterId:4, selected: false, },

		]	
	},
	'distance-based':{
		order: null,
		//variables: HIGHLANDS_REGION_VARIABLES
		variables: [
			//{isGroup: true, variables: []},
			
			{key: 'distance-to-major-roads', label: 'Distance to Major Roads', region:'highlands-region', rasterId:1, selected: false, },
			{key: 'distance-to-high-density land use', label: 'Distance to High Density Land Use', region:'highlands-region', rasterId:2, selected: false, },
			{key: 'distance-to-rail-stations', label: 'Distance to Rail Stations', region:'highlands-region', rasterId:3, selected: false, },
			{key: 'distance-to-preserve-areas', label: 'Distance to Preserve Areas', region:'highlands-region', rasterId:4, selected: false, },
			{key: 'distance-to-parks', label: 'Distance to Parks', region:'highlands-region', rasterId:5, selected: false, },
			{key: 'distance-to-scenic-resources', label: 'Distance to Scenic Resources', region:'highlands-region', rasterId:6, selected: false, },

			{key: 'distance-to-parks', label: 'Distance to Parks', region:'pinelands-region', rasterId:1, selected: false, },
			{key: 'distance-to-major-roads', label: 'Distance to Major Roads', region:'pinelands-region', rasterId:2, selected: false, },
			{key: 'distance-to-open-spaces', label: 'Distance to Open Spaces', region:'pinelands-region', rasterId:3, selected: false, },
			{key: 'distance-to-rail-stations', label: 'Distance to Rail Stations', region:'pinelands-region', rasterId:4, selected: false, },
			{key: 'distance-to-urban-areas', label: 'Distance to Urban Areas', region:'pinelands-region', rasterId:5, selected: false, },
		]	
	},
	'attribute-based':{
		order: null,

		variables: [
			{key: 'steep-slope-protection-areas', label: 'Steep Slope Protection Areas', region:'highlands-region', rasterId:1, selected: false, },
			{key: 'confirmed-vernal-pools', label: 'Confirmed Vernal Pools', region:'highlands-region', rasterId:2, selected: false, },
			{key: 'conservation-areas', label: 'Conservation Areas', region:'highlands-region', rasterId:3, selected: false, },
			{key: 'preserved-farmland', label: 'Preserved Farmland', region:'highlands-region', rasterId:4, selected: false, },
			{key: 'watershed-condition	', label: 'Watershed Condition', region:'highlands-region', rasterId:5, selected: false, },		 
			{key: 'net-water-availability', label: 'Net Water Availability', region:'highlands-region', rasterId:6, selected: false, },		 
			{key: 'preservation-and-planning-areas', label: 'Preservation and Planning Areas', region:'highlands-region', rasterId:7, selected: false, },
			{key: 'habitat-importance', label: 'Habitat Importance for Imperiled Species', region:'highlands-region', rasterId:8, selected: false, },

			{key: 'agricultural-production-areas', label: 'Agricultural Production Areas', region:'pinelands-region', rasterId:1, selected: false, },
			{key: 'forest-areas', label: 'Forest Areas', region:'pinelands-region', rasterId:2, selected: false, },
			{key: 'pinelands-towns', label: 'Pinelands Towns', region:'pinelands-region', rasterId:3, selected: false, },
			{key: 'pinelands-villages', label: 'Pinelands Villages', region:'pinelands-region', rasterId:4, selected: false, },
			{key: 'preservation-area-districts', label: 'Preservation Area Districts', region:'pinelands-region', rasterId:5, selected: false, },
			{key: 'regional-growth-areas', label: 'Regional Growth Areas', region:'pinelands-region', rasterId:6, selected: false, },
			{key: 'special-agricultural-production-areas', label: 'Special Agricultural Production Areas', region:'pinelands-region', rasterId:7, selected: false, },
			{key: 'right-of-way-plans', label: 'Right-of-Way Plans', region:'pinelands-region', rasterId:8, selected: false, },
			{key: 'rural-development-areas', label: 'Rural Development Areas', region:'pinelands-region', rasterId:9, selected: false, },
			{key: 'habitat-importance', label: 'Habitat Importance for Imperiled Species', region:'pinelands-region', rasterId:10, selected: false, },
		]
	},
	'cluster-based':{
		order: null,
		variables: [
			{isGroup: true, title:'Housing', variables: [
				{key: 'housing-hot-spots', label: 'Housing (2010) Hot Spots', rasterId:1, selected: false, },
			]},
			{isGroup: true, title:'Population', variables: [
				{key: 'population-hot-spots', label: 'Population (2010) Hot Spots', rasterId:2, selected: false, },
			]},
		]
	}
}





export const AHPVariablesByRegion = {
	'highlands-region': {
		order: null,
		//variables: HIGHLANDS_REGION_VARIABLES
		

		variables: [
			{key: 'land-use-highlands-slope', label: 'Land Use/Highlands/Slope', rasterId:null, selected: false, },
			{key: 'agricultural-priority', label: 'Agricultural Priority', rasterId:1, selected: false, },
			{key: 'confirmed-vernal-pools', label: 'Confirmed Vernal Pools', rasterId:2, selected: false, },
			{key: 'conservation-priority-areas', label: 'Conservation Priority Areas', rasterId:4, selected: false, },
			{key: 'critical-wildlife-habitat', label: 'Critical Wildlife Habitat',  rasterId:5, selected: false, },
			{key: 'easements', label: 'Easements',  rasterId:6, selected: false, },
			{key: 'ecological', label: 'Ecological Priority Areas', rasterId:7, selected: false, },
			{key: 'conservation', label: 'Conservation',  rasterId:3, selected: false, },
			{key: 'green-space', label: 'Green Space', rasterId:9, selected: false},
			{key: 'watershed-values', label: 'Watershed Values', rasterId:12, selected: false},
			{key:'freshwater-priority', label: 'Freshwater Priority', rasterId:8, selected: false},
			{key: 'net-water-availability', label: 'Net Water Availability', rasterId:10, selected: false},
			{key: 'preservation-and-planning-areas', label: 'Preservation and Planning Areas', rasterId:11, selected: false},
			{key: 'preserved-lands', label: 'Preserved Lands', rasterId:13, selected: false}
		]


	},
	'pinelands-region':  {
		order: null,
		//variables: HIGHLANDS_REGION_VARIABLES
		variables: [
			{key: 'land-use-highlands-slope', label: 'Land Use/Highlands/Slope', rasterId:null, selected: false, },
		]	
	}
};
 
/**
 * 
 * @param {string} baseRasterServiceURL DO NOT INCLUDE LAST SLASH PLEASE FOR NOW
 * @param {array} rasterArray an array of raster object items with format {url:string, format:string}. 
 * Include the base raster as it's own raster element as the following: {url: "$$", format: null, multiplier: number}
 * @param {string} rasterArray[].url the url for an image server, See extra information 1 below for an example
 * 	we can expand this later to be able to have it be sent images from non-imageservers, but for now, specifically want imageserver.
 * Please do not add the last slash for now.
 * @param {string} rasterArray[].format the format of the image, 
 * @param {number} rasterArray[].multiplier what to multiply this raster by.
 * 
 * @returns {Promise} promise to return a new RasterFunction
 * @description 
 * See also [GenerateRasterExampleFunction] below for an example how to use function.
 * Extra information 1: 
 * We will grab the bbox from the [baseRasterServiceURL] parameter, so please just send 
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage`
 * 
 * Full example of image url that will be generated/implied generated . bbox is the really important variable here
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage?
 * bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342
 * &bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`
 * 
 * https://developers.arcgis.com/documentation/common-data-types/raster-function-objects.htm
 * 
 */
export const  GenerateMosaicRasterCalculation = (rasterArray=[]) => {
	
	const inputNames=[];
	const rasters=[];
	const expressionArray=[];
	
	rasterArray.forEach( (rasterItem,_idx) => {
		const rasterName = `raster${rasterItem.rasterId}`;

		rasters.push(`$${rasterItem.rasterId}`)

		inputNames.push(rasterName);
		expressionArray.push( `(${rasterName} * ${rasterItem.multiplier})` )
	});

	return new RasterFunction({
		functionName: "RasterCalculator",
		functionArguments: {
			"InputNames": inputNames,
			"Expression": expressionArray.join('+'),
			"Rasters" :rasters
		},
		outputPixelType:'unknown'
	});
}



export const generateMaskRule = ( rasterFunction ) => {
	return new RasterFunction({
		functionName: "Mask",
		functionArguments: {
			//NoDataValues: [0,15,20,255,25,-2147483648,2147483647, 3.40282e+38, -3.40282e-38 ],
			IncludedRanges:[0.00000000001,10],
			NoDataInterpretation: 'all',
			Raster: rasterFunction//rasterFunction // chaining multiple rasterfunctions
		},
		outputPixelType:'f32'
	})
}


/**
 * 
 * @param {string} baseRasterServiceURL DO NOT INCLUDE LAST SLASH PLEASE FOR NOW
 * @param {array} rasterArray an array of raster object items with format {url:string, format:string}. 
 * Include the base raster as it's own raster element as the following: {url: "$$", format: null, multiplier: number}
 * @param {string} rasterArray[].url the url for an image server, See extra information 1 below for an example
 * 	we can expand this later to be able to have it be sent images from non-imageservers, but for now, specifically want imageserver.
 * Please do not add the last slash for now.
 * @param {string} rasterArray[].format the format of the image, 
 * @param {number} rasterArray[].multiplier what to multiply this raster by.
 * 
 * @returns {Promise} promise to return a new RasterFunction
 * @description 
 * See also [GenerateRasterExampleFunction] below for an example how to use function.
 * Extra information 1: 
 * We will grab the bbox from the [baseRasterServiceURL] parameter, so please just send 
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage`
 * 
 * Full example of image url that will be generated/implied generated . bbox is the really important variable here
 * `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_train/ImageServer/exportImage?
 * bbox=-8372516.337106015%2C4943787.621052355%2C-8248878.572421204%2C5054521.444277342
 * &bboxSR=&size=&imageSR=&time=&pixelType=S32&noData=&noDataInterpretation=esriNoDataMatchAny&interpolation=+RSP_BilinearInterpolation&compression=&compressionQuality=&bandIds=&sliceId=&mosaicRule=&renderingRule=&adjustAspectRatio=true&lercVersion=1&compressionTolerance=&f=image&format=tiff`
 * 
 * https://developers.arcgis.com/documentation/common-data-types/raster-function-objects.htm
 * 
 */
export const  GenerateRasterCalculation = async (
	baseRasterServiceURL,// = `https://ora-devserver.njaes.rutgers.edu:6443/arcgis/rest/services/SEFEA/dist_roads/ImageServer`, 
	rasterArray=[]) => {
	
	return fetch( `${baseRasterServiceURL}/queryBoundary?outSR=&f=pjson`)
	.then( (response) => {
		return response.json();
	})
	.then ( (respJson) => {
		console.log('Json Response: ', respJson);
		//const bbox = `${respJson.shape.xmin},${respJson.shape.ymin},${respJson.shape.xmax},${respJson.shape.ymax}`;
		//const bboxSR = `${respJson.shape.spatialReference.wkid}`;
		//const size = `2048,2048`;

		const inputNames = []; //arbiturary names for the function to work.
		const rasters = []; //objects of URL and format
		const expressionArray = []; //joined by addition

		//using foreach instead of map for simplicity's sake
		//&bboxSR=${bboxSR}
		rasterArray.forEach( (rasterItem,idx) => {
			const rasterName = `raster${idx}`;
			if(rasterItem.url==="$$" ) {
				rasters.push("$$");
			}else{
				rasters.push({
					//size=${size}&bbox=${bbox}&bboxSR=${bboxSR}&interpolation=RSP_NearestNeighbor&
					url: `${rasterItem.url}`,//?f=image&format=bip`,
					format: rasterItem.format
				});
			}
			inputNames.push(rasterName);
			expressionArray.push( `(${rasterName} * ${rasterItem.multiplier})` )
		});	

		console.log('Expression array: ', expressionArray);

		return new RasterFunction({
			functionName: "RasterCalculator",
			functionArguments: {
				"InputNames": inputNames,
				"expression": expressionArray.join('+'),
				"rasters" :rasters
			},
			outputPixelType:"F32"
		});


	}) 
	.catch( (failedResponse) => {
		return failedResponse;
	})


}

export const LAYER_ID_HEADER = 'ahp-tool-results';

//Offset by 1 so CI of 1 is the index slot 1. There should never be a  zero
export const RCI_Table = [
	null,
	0,
	0,
	0.58,
	0.9,
	1.12,
	1.24,
	1.32,
	1.41,
	1.45,
	1.49,
	1.51,
	1.48,
	1.56,
	1.57,
	1.59,
];





